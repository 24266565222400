import {API_URL, AUTH_TOKEN, ENV} from '../constants/endPoints';

export default (method, request) => {

  let appHeaders = new Headers();
  appHeaders.append('Content-Type', 'application/json');
  appHeaders.append('Authorization', AUTH_TOKEN);

  let url = '';

  if ( ENV === 'dev' ) {
    console.log(method, request);
  }

  if ( method === 'searchItems' ) {
    url = `${API_URL}search/?query=${request.query}&offset=${request.offset}&locale=es`;
    //console.log(url);
    return fetch(url, {
      method: 'GET',
      headers: appHeaders,
      cache: 'default',
    })
    .then(
      (response) => Promise.all([response, response.json()]),
    )
    .catch((error) => {
      console.log(error);
    });
  }

};
