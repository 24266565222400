import React from 'react';
import {View, TouchableOpacity, Button, Text} from 'react-native';
import {styles} from './ModalMessage.styles';
import * as Colors from '../../styles/colors';
import globalStyles from '../../styles/global';

const ModalMessage = ({message, onClose, type = 'normal'}) => {
  //console.log('message =>', message);
  return (
    <View style={styles.modal}>
      <View style={styles.modalContent}>
        <View style={styles.contClose}>
          <TouchableOpacity onPress={onClose}>
            <Text style={styles.close}>&times;</Text>
          </TouchableOpacity>
        </View>
        <View style={[
          styles.contTextMessage,
          type === 'normal' ? styles.colorSuccess : styles.colorError
        ]}>
          {message
            ? (
              <Text style={[
                globalStyles.titleFont,
                styles.textMessage,
                type === 'normal' ? styles.colorSuccess : styles.colorError
              ]}>
                {message}
              </Text>
            )
            : {children}
          }
        </View>
        <View style={styles.contFirstBtn}>
          <View style={styles.contBtn}>
            <Button
              color={Colors.PURPLE}
              title="Ok"
              onPress={onClose}
            />
          </View>
        </View>
      </View>
    </View>
  )
}

export default ModalMessage;
