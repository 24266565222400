import {
  FETCHING_PROFILE_PENDING,
  FETCHING_PROFILE_SUCCESS,
  FETCHING_PROFILE_FAILURE,
} from '../constants/actionTypes.js';
import api from '../api/profile';

export const getProfile = async (data) => {
  try {
    const [response, json] = await api('getProfile', data);
    json.statusCode = response.status;
    return getDataSuccess(json);
  } catch (err) {
    console.log(err);
    return getDataFailure(err);
  }
};

export const getProfileItems = async (data) => {
  try {
    const [response, json] = await api('getProfileItems', data);
    json.statusCode = response.status;
    return getDataSuccess(json);
  } catch (err) {
    console.log(err);
    return getDataFailure(err);
  }
};

export const updateProfile = async (data) => {
  try {
    const [response, json] = await api('updateProfile', data);
    json.statusCode = response.status;
    return getDataSuccess(json);
  } catch (err) {
    console.log(err);
    return getDataFailure(err);
  }
};

export const registerUser = async (data) => {
  try {
    const [response, json] = await api('registerUser', data);
    json.statusCode = response.status;
    return getDataSuccess(json);
  } catch (err) {
    console.log(err);
    return getDataFailure(err);
  }
};

export const loginUser = async (data) => {
  try {
    const [response, json] = await api('loginUser', data);
    json.statusCode = response.status;
    return getDataSuccess(json);
  } catch (err) {
    console.log(err);
    return getDataFailure(err);
  }
};

export const uploadAvatar = (data) => {
  return api('uploadAvatar',data)
      .then(([response, json]) => {
          json.statusCode = response.status;
          return getDataSuccess(json);
      })
      .catch((err) => {
          console.log(err)
          return getDataFailure(err)
      });
};

export const saveItem = async (data) => {
  try {
    const [response, json] = await api('saveItem', data);
    json.statusCode = response.status;
    return getDataSuccess(json);
  } catch (err) {
    console.log(err);
    return getDataFailure(err);
  }
};

export const deleteItem = async (data) => {
  try {
    const [response, json] = await api('deleteItem', data);
    json.statusCode = response.status;
    return getDataSuccess(json);
  } catch (err) {
    console.log(err);
    return getDataFailure(err);
  }
};

export const updateDeviceToken = async (data) => {
  try {
    const [response, json] = await api('updateDeviceToken', data);
    json.statusCode = response.status;
    return getDataSuccess(json);
  } catch (err) {
    console.log(err);
    return getDataFailure(err);
  }
};

export const recoverPassword = async (data) => {
  try {
    const [response, json] = await api('recoverPassword', data);
    json.statusCode = response.status;
    return getDataSuccess(json);
  } catch (err) {
    console.log(err);
    return getDataFailure(err);
  }
};

export const changePassword = async (data) => {
  try {
    const [response, json] = await api('changePassword', data);
    json.statusCode = response.status;
    return getDataSuccess(json);
  } catch (err) {
    console.log(err);
    return getDataFailure(err);
  }
};

export const requestDeletion = async (data) => {
  try {
    const [response, json] = await api('requestDeletion', data);
    json.statusCode = response.status;
    return getDataSuccess(json);
  } catch (err) {
    console.log(err);
    return getDataFailure(err);
  }
};

export const setProfile = (data) => {
  return {
    type: 'SET_PROFILE',
    payload: data
  }
}

// API Requests
export const getData = () => {
  return {
    type: FETCHING_PROFILE_PENDING,
  };
};
export const getDataSuccess = (json) => {
  return {
    type: FETCHING_PROFILE_SUCCESS,
    profileData: json
  };
};
export const getDataFailure = () => {
  return {
    type: FETCHING_PROFILE_FAILURE,
  };
};