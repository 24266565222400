import {
    FETCHING_VIDEOS_PENDING,
    FETCHING_VIDEOS_SUCCESS,
    FETCHING_VIDEOS_FAILURE,
  } from '../constants/actionTypes.js';

import api from '../api/videos';

export const getVideos = (data) => {
    return api('getVideos',data)
        .then(([response, json]) => {
            json.statusCode = response.status;
            return getDataSuccess(json);
        })
        .catch((err) => {
            console.log(err)
            return getDataFailure(err)
        });
};

//API Requests
export const getData = () => {
    return {
      type: FETCHING_VIDEOS_PENDING,
    };
};
export const getDataSuccess = (json) => {
    return {
        type: FETCHING_VIDEOS_SUCCESS,
        contentData: json
    };
};
export const getDataFailure = () => {
    return {
        type: FETCHING_VIDEOS_FAILURE,
    };
}; 