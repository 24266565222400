import {
  FETCHING_PRODUCT_PENDING,
  FETCHING_PRODUCT_SUCCESS,
  FETCHING_PRODUCT_FAILURE,
} from '../constants/actionTypes.js';
import api from '../api/producto';

export const getAllProducts = async (data) => {
  try {
    const [response, json] = await api('getAllProducts', data);
    json.statusCode = response.status;
    return getDataSuccess(json);
  } catch (err) {
    console.log(err);
    return getDataFailure(err);
  }
};

export const getProducts = async (data) => {
  try {
    const [response, json] = await api('getProducts', data);
    json.statusCode = response.status;
    return getDataSuccess(json);
  } catch (err) {
    console.log(err);
    return getDataFailure(err);
  }
};

export const getProductsByCategory = async (data) => {
  try {
    const [response, json] = await api('getProductsByCategory', data);
    json.statusCode = response.status;
    return getDataSuccess(json);
  } catch (err) {
    console.log(err);
    return getDataFailure(err);
  }
};

export const getProductById = async (data) => {
  try {
    const [response, json] = await api('getProductById', data);
    json.statusCode = response.status;
    return getDataSuccess(json);
  } catch (err) {
    console.log(err);
    return getDataFailure(err);
  }
};

export const getProductsCustom = async (data) => {
  try {
    const [response, json] = await api('getProductsCustom', data);
    json.statusCode = response.status;
    return getDataSuccess(json);
  } catch (err) {
    console.log(err);
    return getDataFailure(err);
  }
};


export const setProducts = (data) => {
  return {
    type: 'SET_PRODUCT',
    payload: data
  }
}

//API Requests
export const getData = () => {
  return {
    type: FETCHING_PRODUCT_PENDING,
  };
};
export const getDataSuccess = (json) => {
  return {
    type: FETCHING_PRODUCT_SUCCESS,
    productData: json
  };
};
export const getDataFailure = () => {
  return {
    type: FETCHING_PRODUCT_FAILURE,
  };
};