import {
    FETCHING_HOME_PENDING,
    FETCHING_HOME_SUCCESS,
    FETCHING_HOME_FAILURE
  } from '../constants/actionTypes'

  const initialState = {
    homeIsFetching: false,
    homeError:false,
    homeData:{}
  }

  const home = (state = initialState, action) => {

    switch (action.type) {

      case 'SET_HOME':
        return {
          homeData: action.payload,
          homeIsFetching:false,
          homeError:false
      };


      //Inicio de casos para API
      case FETCHING_HOME_PENDING:
        return {
          homeData:{},
          homeIsFetching:true,
          homeError: false
        }
      case FETCHING_HOME_SUCCESS:
        return {
          homeData:action.data,
          homeIsFetching:false,
          homeError: false
        }
      case FETCHING_HOME_FAILURE:
        return {
            homeData:{},
            homeError: true,
            homeIsFetching : false
        }
      default:
        return state
    }
  }
  
  export default home
  
  