import * as React from 'react';
import { 
  View, 
  StyleSheet, 
  Platform, 
  Dimensions, 
  Image, 
  TouchableOpacity, 
  FlatList,
  Modal ,
  Text
} from 'react-native';
import { BASE_URL } from '../constants/endPoints';
import * as Colors from '../styles/colors';
import ImageViewer from 'react-native-image-zoom-viewer';

export class ProductImages extends React.Component {
  constructor (props) {
      super(props);
      this.state = {
        showModal: false,
        imageSelected: '',
        imgIndex: 0
      }     
      this.swiperRef = React.createRef();
  }

  componentDidMount () {
    this.setState({
      imageSelected: this.props.images.length ? this.props.images[0].image.url : ''
    });
  }

  itemImage = (item, index) => {
    return (
      <TouchableOpacity style={styles.backgroundImage}
        onPress={() => {
          this.setState({imgIndex:index})
          this.setState({imageSelected:item.image.url})
        }}>
        <Image 
          source={{ uri: BASE_URL + item.image.url }} 
          style={styles.image} 
          resizeMode="contain" 
        />
      </TouchableOpacity>
    );
  }

  render () {
    const { images } = this.props;

    return (
      <View style={styles.container}>
        <Modal visible={this.state.showModal} >
          <ImageViewer 
            index={this.state.imgIndex}
            ref={this.swiperRef}
            imageUrls={images.map(item=>{return {url: BASE_URL + item.image.url} })} 
            renderIndicator={() => null} 
            enableImageZoom={true}
            onSwipeDown={() => this.setState({showModal: false})}
            doubleClickInterval={500}
            menuContext={{cancel: 'cancel'}}
            enableDoubleClickZoom={true}
            minScale={0.5}
            maxScale={5}
          />
          <View  style={{
              position: 'absolute',
              bottom: 0,
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: 100,
            }}>
            <TouchableOpacity style={{
              backgroundColor: Colors.PURPLE,
              width: 300,
              textAlign: 'center',
              fontSize: 20,
              padding: 10,
              borderRadius: 10,
            }}
            onPress={() => {
              this.setState({
                showModal: false,
              })
            }}
            >
              <Text style={{
                color: 'white',
                fontSize: 20,
              }}>Cerrar</Text>
            </TouchableOpacity>
          </View>
        </Modal>
        <View style={{flexDirection: 'row'}}>
          <View>
            <FlatList 
              data={images}
              renderItem={({item,index}) =>  this.itemImage(item,index)}
              keyExtractor={item => String(item.id)}
              style={{height: Platform.OS === 'web' ? '200px' : 200}}
              initialNumToRender={10}
            />
          </View>
          <View style={{flex: 1}}>
            <TouchableOpacity onPress={()=>{
              this.setState({
                showModal: true,
                imageSelected: this.state.imageSelected
              })
            }}>
              <Image 
                style={[styles.imageFLuid, {marginHorizontal: 'auto'}]}
                source={{ uri: BASE_URL + this.state.imageSelected}} 
                resizeMode="contain"
              />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
      flex: 1,
      flexDirection: 'column',
      marginHorizontal: 20,
      marginVertical: 40
  },
  imageFLuid: {
    width:  '90%',
    height: Platform.OS === 'web' ? '450px' : 120,
    overflow: 'visible'
  },
  backgroundImage: {
    flexWrap: 'wrap',
    backgroundColor: '#f2f2f2',
    width: 115,
    marginBottom: 10,
    alignContent: 'center'
  },
  image: {
    width:  100,
    height: 100
  }
});
