import * as React from 'react';
import { View, Text, StyleSheet, ScrollView, Dimensions, Platform, FlatList, SafeAreaView, ActivityIndicator, TouchableOpacity } from 'react-native';

import { ProductImages } from '../components/ProductImages';
import { ProductImagesSlider } from '../components/ProductImagesSlider';
import RecommendedProductCard from '../components/products/RecommendedProductCard';
import VideoProductCard from '../components/products/VideoProductCards';
import ReviewProductCard from '../components/products/ReviewProductCard';
import ProductSpec from '../components/ProductSpec';
import Footer from '../components/Footer';

import * as Colors from "../styles/colors";
import * as Global from "../styles/global";

import SaveAndShare from "../components/saveandshare/SaveAndShare";
import * as RootNavigation from '../rootNavigation';
import { connect } from 'react-redux';

import * as actions from '../actions/producto';
import * as dactions from '../actions/distributors';

import * as fetchData from '../constants/actionTypes.js';

import { WebView as WebViewWeb } from "react-native-web-webview";
import AutoHeightWebView from 'react-native-autoheight-webview'
import AsyncStorage from '@react-native-async-storage/async-storage';

function DetalleProductoSection(props) {
  const [product, setProduct] = React.useState({
    productIsReady: false,
    item: {
      specifications: {},
      use: {}
    },
    related: [],
    videos: [],
    reviews: [],
    recommended: [],
  });
  const [profile, setProfile] = React.useState(null);
  const [locale, setLocale] = React.useState(null);

  React.useEffect(() => {
    AsyncStorage.getItem('profile').then(profile => {
      if (profile) {
        let p = JSON.parse(profile)
        setProfile(p)
      }
    })
  }, [])
  React.useEffect(() => {
    async function getProduct() {
      const route = props.route;
      setProduct(prevProduct => ({ ...prevProduct, productIsReady: false }));

      if (route.params) {
        await actions
          .getProductById(route.params.id)
          .then(res => {

            //console.log('productData',res.productData);

            if (res.type === fetchData.FETCHING_PRODUCT_SUCCESS) {
              setProduct(prevProduct => ({
                ...prevProduct, productIsReady: true,
                item: {
                  ...res.productData,
                  specifications: res.productData.body.filter(item => item.type === 'specification'),
                  use: res.productData.use && res.productData.use.items.length ? res.productData.use.items :
                    res.productData.content && res.productData.content.items.length ? res.productData.content.items :
                      res.productData.usem && res.productData.usem.items.length ? res.productData.usem.items :
                        res.productData.place && res.productData.place.items.length ? res.productData.place.items :
                          res.productData.placem && res.productData.placem.items.length ? res.productData.placem.items :
                            []
                },
                related: res.productData.related || [],
                videos: res.productData.videos || [],
                reviews: res.productData.reviews || [],
                recommended: res.productData.recommended || []
              }));
            }
          }).catch(err => {
            console.log(err);
            //return err;
          });
      } else {
        props.navigation.navigate('Home');
      }
    }

    getProduct();

    //return () => { }
  }, [props.route.params ? props.route.params.id : 0]);

  React.useEffect(() => {
    //Update Locale if possible
    AsyncStorage.getItem('locale').then(async l => {
      if (l) {
        const lj = JSON.parse(l)
        const countries = await dactions.getCountries();
        //console.log('countries', countries)
        if (countries.type === 'FETCHING_DISTRIBUTOR_SUCCESS') {
          const country = countries.distributorData.items.find(country => country.id == lj.country.id);
          const payload = {
            country,
            in: lj.in,
          };
          setLocale(payload)
          await AsyncStorage.setItem('locale', JSON.stringify(payload));
          //console.log('locale', locale)
        }
      }
    })

  }, [props.route.params ? props.route.params.id : 0])

  return (
    product.productIsReady && locale && locale.country ? (
      <ScrollView>
        <View style={Global.default.sectionContainer}>
          <Text style={Global.default.sectionTitle}>
            {product.item.title}
          </Text>
          <View style={styles.productCard}>
            {
              Platform.OS === 'web' &&
                Dimensions.get('window').width < 767 ?

                <ProductImagesSlider images={product.item.imagenes} /> :

                Platform.OS === 'web' &&
                  Dimensions.get('window').width > 767 ?

                  <ProductImages images={product.item.imagenes} /> :
                  <ProductImagesSlider images={product.item.imagenes} />
            }
            {Platform.OS != 'web' && (
              <View style={styles.productFootIcons}>
                <SaveAndShare item={product.item} options={{
                  share: true, download: product.item.document && product.item.document.meta ? true : false,
                  purple: true, extraDownload: product.item.document_extra && product.item.document_extra.meta ? true : false
                }} />
              </View>
            )}
            <View style={styles.detail}>
              <View style={{ flexDirection: 'column' }}>
                {
                  Platform.OS == "web" && (
                    <WebViewWeb style={{ height: 215 }} androidLayerType="software" androidHardwareAccelerationDisabled source={{
                      html: `
                  <link rel="preconnect" href="https://fonts.googleapis.com">
                  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                  <link href="https://fonts.googleapis.com/css2?family=Lato&display=swap" rel="stylesheet">
                  <style>
                    * { font-family: 'Lato'}
                    p { line-height: 24px; }
                    ul { max-width: 80%}
                  </style>
                 ${product.item.desc} `
                    }} />
                  ) || (
                    <AutoHeightWebView androidLayerType="software" androidHardwareAccelerationDisabled source={{
                      html: `
                  <link rel="preconnect" href="https://fonts.googleapis.com">
                  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                  <link href="https://fonts.googleapis.com/css2?family=Lato&display=swap" rel="stylesheet">
                  <style>
                    * { font-family: 'Lato'; widt:100%; min-width:80%; }
                    p { line-height: 24px; }
                    ul { max-width: 80%}
                  </style> 
                  ${product.item.desc} `
                    }}></AutoHeightWebView>
                  )
                }

                <View style={{ marginVertical: Dimensions.get('window').width < 767 ? 5 : 20 }}></View>
                {profile && profile.profile && profile.profile == 'distributor' && locale && (
                  <Text style={{ fontSize: 26 }}>
                    {locale.country.currency_printable_symbol} {" "}
                    {
                      product.item.prices.length > 0 ? product.item.prices.filter(value => value.country === locale.country.id).length > 0 ? product.item.prices.filter(value => value.country === locale.country.id)[0].price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ' -- ' : ' -- '
                    }
                  </Text>
                )}
                {profile && profile.profile && profile.profile == 'distributor' && (
                  <Text>
                    {props.locale.in['price_just_for_reference']}
                  </Text>
                )}
                {profile && profile.profile && profile.profile == 'distributor' && (
                  <TouchableOpacity style={styles.buyButton}
                    onPress={() => { RootNavigation.navigate('Distribuidores') }}
                  >
                    <Text style={styles.textBuyButton}>{props.locale.in['where_buy']}</Text>
                  </TouchableOpacity>
                )}
              </View>
            </View>
            {Platform.OS == 'web' && (
              <View style={styles.productFootIcons}>
                <View style={{
                  position: 'relative',
                  minHeight: 60
                }}>
                  <SaveAndShare item={product.item}
                    options={{
                      share: true, download: product.item.document && product.item.document.meta ? true : false,
                      purple: true, extraDownload: product.item.document_extra && product.item.document_extra.meta ? true : false
                    }} />
                </View>
              </View>
            )}
          </View>

          <ProductSpec
            specs={product.item.specifications.length ? product.item.specifications : []}
            typeOfProduct={product.item.specific_parent.title}
            use={product.item.use}
            inventory={product.item.inventory}
            country_id={locale.country.id}
          />

          {
            product.recommended.length > 0 && (
              <View>
                <Text style={[Global.default.sectionTitle, styles.subtitle]}>RECOMENDACIONES DE PRODUCTO</Text>
                <FlatList
                  contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}
                  data={product.recommended}
                  renderItem={({ item }) => <RecommendedProductCard product={item} locale={props.locale} navigation={props.navigation} />}
                  keyExtractor={item => String(item.id)}
                  horizontal
                  initialNumToRender={10}
                />

              </View>
            )
          }

          {
            product.related.length > 0 &&
            <View>
              <Text style={[Global.default.sectionTitle, styles.subtitle]}>ACCESORIOS COMPATIBLES</Text>
              <FlatList
                contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}
                data={product.related}
                renderItem={({ item }) => <RecommendedProductCard product={item} locale={props.locale} navigation={props.navigation} />}
                keyExtractor={item => String(item.id)}
                horizontal
                initialNumToRender={10}
              />
              {/*<View style={{flex: 1, justifyContent: 'center', alignContent: 'center'}}>
                <Text style={styles.emptyProducts}>No hay accesorios compatibles para mostrar</Text>
              </View>*/}
            </View>
          }

          {
            product.videos.length > 0 &&
            <View>
              <Text style={[Global.default.sectionTitle, styles.subtitle]}>VIDEOS</Text>
              <FlatList
                contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}
                data={product.videos}
                renderItem={({ item }) => <VideoProductCard video={item} />}
                keyExtractor={item => String(item.id)}
                horizontal
                initialNumToRender={10}
              />

            </View>
          }

          {
            product.reviews.length > 0 &&
            <View>
              <Text style={[Global.default.sectionTitle, styles.subtitle]}>REVIEWS DE INFLUENCERS</Text>
              <FlatList
                contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}
                data={product.reviews}
                renderItem={({ item }) => <ReviewProductCard review={item} />}
                keyExtractor={item => String(item.id)}
                horizontal
                initialNumToRender={10}
              />
              {/*
            <View style={{flex: 1, justifyContent: 'center', alignContent: 'center'}}>
              <Text style={styles.emptyProducts}>No hay videos para mostrar</Text>
            </View> */}
            </View>
          }
        </View>
        <Footer {...props} />
      </ScrollView>
    )
      : (
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <ActivityIndicator size="large" color={Colors.PURPLE} />
        </View>
      )

  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: Platform.OS === 'web' ? 60 : 40,
    marginVertical: 40
  },
  detail: {
    flexDirection: 'column',
    flex: Dimensions.get('window').width < 667 ? 1 : .5,
    paddingLeft: Dimensions.get('window').width < 767 ? 10 : 0,
    marginTop: Dimensions.get('window').width < 767 ? 15 : 40,
    justifyContent: Dimensions.get('window').width < 767 ? 'space-between' : 'flex-start',
    width: '100%'
  },
  productCard: {
    flexDirection: Dimensions.get('window').width < 767 ? 'column' : 'row',
    flexWrap: 'wrap',
    marginHorizontal: 'auto',
    marginVertical: Dimensions.get('window').width < 767 ? 10 : 20,
    padding: Dimensions.get('window').width < 767 ? 10 : 40,
    backgroundColor: '#fff',
    borderRadius: 10,
    width: '100%',
    justifyContent: 'space-between'
  },
  productFootIcons: {
    flexDirection: "row",
    flexWrap: 'wrap',
    justifyContent: "flex-end",
    alignItems: 'flex-end',
  },
  buyButton: {
    borderColor: Colors.PURPLE,
    borderWidth: 2,
    borderRadius: 25,
    color: Colors.WHITE,
    marginTop: 20,
    marginBottom: 60,
    overflow: "hidden",
    paddingVertical: 10,
    textAlign: 'center',
    textTransform: 'uppercase',
    width: 250,
    alignItems: 'center',
    alignSelf: Dimensions.get('window').width > 667 ? 'flex-start' : 'center'
  },
  textBuyButton: {
    paddingLeft: 10,
    textTransform: 'uppercase',
    fontWeight: '600',
    color: Colors.PURPLE,
  },
  emptyProducts: {
    color: Colors.PURPLE,
    fontSize: 30,
    textTransform: 'uppercase',
    marginTop: 15,
    textAlign: 'center'
  },
  subtitle: {
    fontSize: Dimensions.get('window') > 980 ? 28 : 22,
    marginTop: 20,
    marginBottom: 10
  }
});

const mapStateToProps = (state) => {
  return {
    locale: state.locale,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setProducts: (data) => {
      return dispatch(actions.setProducts(data))
    }
  }
}
const DetalleProducto = connect(mapStateToProps, mapDispatchToProps)(DetalleProductoSection);

export default DetalleProducto;