import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  View,
  Platform,
  useWindowDimensions,
  ActivityIndicator
} from 'react-native';
import * as actions from '../../actions/distributors';
import * as fetchData from '../../constants/actionTypes.js';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Updates from 'expo-updates';
import Select from '../Select';
import * as Colors from '../../styles/colors';

import * as lactions from '../../actions/locale';

let locale = null;
let lDispatch = null;

const LocaleComponentNewSelect = ({ route }) => {

  locale = useSelector((state) => state.locale);
  lDispatch = useDispatch()

  const [countries, setCountries] = useState([]);
  const [countryValue, setCountryValue] = useState({});
  const [componentReady, setComponentReady] = useState(false);
  const dimensions = useWindowDimensions();

  useEffect(() => {

    const getLocale = async (items) => {
      const l = await AsyncStorage.getItem('locale');

      if (l) {
        try {
          const lj = JSON.parse(l)
          const country = items.find(country => country.id == lj.country.id);
          const payload = {
            country,
            in: lj.in,
          };
          //console.log('payload',payload);
          AsyncStorage.setItem('locale', JSON.stringify(payload));
          //lDispatch(lactions.setLocale(payload))
        }
        catch (e) {
          console.log(e)
        }
      }

    }

    async function getCountries() {
      await actions
        .getCountries()
        .then(res => {
          if (res.type == fetchData.FETCHING_DISTRIBUTOR_SUCCESS) {
            setCountries(res.distributorData.items);
            getLocale(res.distributorData.items);
            setComponentReady(true);
          }
        }).catch(err => {
          console.log(err);
        });
    }

    setCountryValue({
      value: locale.country.id,
      label: locale.country.meta.name + ' ' + locale.country.currency_symbol,
    });
    getCountries();

    //return function cleanup() {
    //  setComponentReady(false);
    //}
  }, []);

  //console.log('locale',locale);

  return (
    componentReady ? (
      <View style={{
        marginTop: 10,
        width: Platform.OS == 'web' ? dimensions.width > 1024 ? '100%' : '70%' : dimensions.width * .7,
      }}>
        <Select
          name={'cboLocation'}
          isBorder={false}
          isBorderLight={route?.name === 'Home' && Platform.OS == 'web'}
          label={locale.country.meta ? (locale.country.meta.name + ' ' + locale.country.currency_symbol) : ''}
          onValueChange={(item) => {
            setCountryValue(item);
            if (item.value) {
              const country = countries.find(country => country.id == item.value);
              let dictionary = {};
              //const dictionary = require(`../../locale/${country.language}.js`).default;
              if (country.language === 'pt') {
                dictionary = require('../../locale/pt.js').default;
              } else {
                dictionary = require('../../locale/es.js').default;
              }

              const payload = {
                country,
                in: dictionary,
              };
              //dispatch({type: 'SET_LOCALE', payload});
              AsyncStorage.setItem('locale', JSON.stringify(payload));
              if (Platform.OS == 'web') {
                window.location.reload();
              } else {
                //console.log('se entró antes de actualizar');
                Updates.reloadAsync().then();
              }
            }
          }}
          dataItems={countries.map((item) => {
            return { label: item.meta.name + ' ' + item.currency_symbol, value: item.id.toString() }
          })
          }
          value={countryValue}
        />
      </View>
    ) : (
      <ActivityIndicator size="large" color={Colors.PURPLE} />
    )
  );
};


export default LocaleComponentNewSelect;

