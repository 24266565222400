import * as Colors from "../styles/colors";
import * as React from "react";
import { connect } from 'react-redux';
import {
  Dimensions,
  Image,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";

import * as globalStyles from "../styles/global";
import { BASE_URL } from "../constants/endPoints";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { WebView as WebViewWeb } from "react-native-web-webview";
import AutoHeightWebView from 'react-native-autoheight-webview'

class AllProductsCardsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: null
    }
  }
  async componentDidMount() {
    const profile = await AsyncStorage.getItem('profile');
    if (profile) {
      let p = JSON.parse(profile)
      this.setState({
        profile: p.profile
      })
      //console.log('Profile',p.profile)
    }
  }
  render() {
    const { product, locale } = this.props;
    //console.log('product', product);
    return (
      <TouchableOpacity
        style={[styles.card, this.props.style]}
        onPress={() => {
          if (Platform.OS == 'web') {
            history.pushState({}, 'Productos', document.location.href)
          }
          this.props.navigation.navigate('Detalle Producto', { id: product.id })
        }}
      >
        <View style={styles.cardBody}>
          <Image
            resizeMode={"contain"}
            source={{ uri: product.imagenes.length ? BASE_URL + product.imagenes[0].image.url : '' }}
            style={[styles.imageFLuid, { margin: "auto" }]}
          />
          <Text style={styles.cardTitle}>{product.title}</Text>
          <Text style={styles.cardSubtitle}>
            {product.intro}
          </Text>
          <View style={{ marginVertical: 15 }}>
            {
              Platform.OS == "web" && (
                <WebViewWeb androidLayerType="software" androidHardwareAccelerationDisabled style={styles.itemDescription} source={{
                  html: `
                  <link rel="preconnect" href="https://fonts.googleapis.com">
                  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                  <link href="https://fonts.googleapis.com/css2?family=Lato&display=swap" rel="stylesheet">
                  <style>
                    * { font-family: 'Lato', sans-serif; }
                    p { line-height: 24px; }
                    li { max-width: 90%}
                  </style>
                 ${product.desc} `
                }} />
              ) || (
                <AutoHeightWebView androidLayerType="software" androidHardwareAccelerationDisabled style={styles.itemDescription} source={{
                  html: `
                <link rel="preconnect" href="https://fonts.googleapis.com">
                  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                  <link href="https://fonts.googleapis.com/css2?family=Lato&display=swap" rel="stylesheet">
                  <style>
                    * { font-family: 'Lato' }
                    p { line-height: 24px;}
                    li { max-width: 80%}
                </style>
                ${product.desc} `
                }}></AutoHeightWebView>
              )
            }
          </View>
          {this.state.profile && this.state.profile == 'distributor' && (
            <Text style={[styles.cardText]}>
              {product.prices.length > 0 ? locale.country.currency_printable_symbol : ''} {" "}
              {
                product.prices.length > 0 ? product.prices.filter(value => value.country === locale.country.id)[0] ? product.prices.filter(value => value.country === locale.country.id)[0].price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ' -- ' : ' -- '
                //(product.price * locale.country.exchange * locale.country.price_factor).toFixed(2)
                //.toString()
                //.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            </Text>
          )}
          {this.state.profile && this.state.profile == 'distributor' && (
            <View
              style={{
                marginVertical:
                  Dimensions.get("window").width < 767 ? 30 : 40,
              }}
            ></View>
          )}
          <View
            style={[globalStyles.default.row, { justifyContent: "space-between" }]}
          >
            <View style={globalStyles.default.column}>
              <Text style={styles.moreInfo}>{locale.in['more_info']}</Text>
            </View>
          </View>
        </View>
        {product.outofstock && !product.isnew && (
          <View style={styles.soldOutContainer}>
            <Text style={styles.soldOut}>{locale.in['sold_out']}</Text>
          </View>
        )}
        {!product.outofstock && product.isnew && (
          <View style={styles.outOfStockContainer}>
            <Text style={styles.soldOut}>{locale.in['new']}</Text>
          </View>
        )}
      </TouchableOpacity>
    )
  }
}

const styles = StyleSheet.create({
  card: {
    backgroundColor: "#fff",
    borderRadius: 10,
    marginHorizontal: 5,
    marginVertical: 6,
    flex: Platform.OS == 'web' ? Dimensions.get('window').width > 768 ? '50%' : '25%' : 0.5,
    width: Platform.OS == 'web' ? Dimensions.get('window').width > 768 ? '33%' : '98%' : '98%'
  },
  cardBody: {
    marginHorizontal: 22,
    marginVertical: 20,
    flexDirection: 'column',
    flex: 1
  },
  cardTitle: {
    fontSize: Dimensions.get("window").width < 767 ? 16 : 22,
    fontWeight: "bold",
    flex: 1
  },
  cardSubtitle: {
    fontSize: Dimensions.get("window").width < 767 ? 14 : 20,
    color: Colors.PURPLE,
    textTransform: "uppercase",
    flex: 1
  },
  cardText: {
    color: "#888",
    fontSize: 22
  },
  moreInfo: {
    color: Colors.PURPLE,
    textTransform: "uppercase",
    fontSize: Dimensions.get("window").width < 767 ? 12 : 18,
    fontWeight: 'bold'
  },
  imageFLuid: {
    marginTop: 80,
    width:
      Platform.OS === "web" && Dimensions.get("window").width > 667
        ? "80%"
        : "100%",
    height:
      Platform.OS === "web" && Dimensions.get("window").width > 667
        ? "320px"
        : 220,
    overflow: "visible",
  },
  soldOutContainer: {
    paddingHorizontal: 30,
    paddingVertical: 10,
    position: "absolute",
    top: 40,
    right: 0,
    backgroundColor: "#666",
  },
  outOfStockContainer: {
    paddingHorizontal: 30,
    paddingVertical: 10,
    position: "absolute",
    top: 40,
    right: 0,
    backgroundColor: "#f00",
  },
  soldOut: {
    color: "#fff",
    fontWeight: "bold",
    textTransform: "uppercase",
    fontSize: Dimensions.get("window").width < 767 ? 18 : 24,
  },
  itemDescription: {
    //color: Colors.BLACK,
    paddingHorizontal: Platform.OS == 'web' ? 0 : 20,
    //fontSize: Platform.OS == 'web' ? '18px' : 18,
    //flexshrink: 3,
    minHeight: Platform.OS == 'web' ? 260 : 150,
    marginTop: 10,
    justifyContent: 'space-between',
  }
});

const mapStateToProps = (state) => {
  return {
    products: state.products,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {}
}
const AllProductsCards = connect(mapStateToProps, mapDispatchToProps)(AllProductsCardsContainer);

export default AllProductsCards;
