import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    View,
    Text,
    Image,
    StyleSheet,
    TouchableOpacity,
    Dimensions,
    Platform,
    TextInput,
} from 'react-native';
import * as Global from '../styles/global';
import * as Colors from '../styles/colors';
import MenuItems from '../constants/menuItems';
import { Entypo } from '@expo/vector-icons';
import LocaleComponentNewSelect from './menu/LocaleComponentNewSelect';
import * as pactions from '../actions/profile';
import AsyncStorage from '@react-native-async-storage/async-storage';

const menu = MenuItems.filter(menuItem => menuItem.section.includes('header'))
let query = '';
let locale = null;

const SearchComponent = ({ navigation, route }) => {
    const searchInput = useRef(null);
    return (
        <View style={{
            background: '#fff',
            borderRadius: 20
        }}>
            <TextInput
                ref={searchInput}
                style={[styles.formInput, {
                    // borderColor: route.name==='Home'? Colors.LIGTH_GRAY : Colors.PURPLE,
                    borderColor: route.name === 'Home' ? Colors.LIGTH_GRAY : 'transparent',
                    color: Colors.BLACK,
                    outline: 'none',
                }]}
                placeholderTextColor={Colors.BLACK}
                placeholder="Buscar"
                onChangeText={(text) => {
                    query = text;
                }}
                onSubmitEditing={() => {
                    searchInput.current.value = '';
                    navigation.navigate('Buscador', { query: query });
                }}
            />
            <Entypo
                onPress={() => {
                    if (searchInput.current.value === '') {
                        searchInput.current.focus()
                    }
                    else {
                        searchInput.current.value = '';
                        navigation.navigate('Buscador', { query: query });
                    }
                }}
                name="magnifying-glass" size={25} color={Colors.BLACK}
                style={{
                    position: 'absolute',
                    right: 10,
                    top: 5,
                }} />
        </View>
    )
}

const Header = ({ navigation, route }) => {
    locale = useSelector((state) => state.locale)

    const getProfile = async () => {
        const profile = await AsyncStorage.getItem('profile');

        if (profile) {
            let p = JSON.parse(profile)
            //console.log('Profile',p.profile)
            //setProfile(p.profile)
            pactions.getProfile(p.user_id).then(async (r) => {
                //console.log('r',r)
                if (r.type == 'FETCHING_PROFILE_SUCCESS') {
                    await AsyncStorage.setItem('profile', JSON.stringify(r.profileData));
                }
            })

        }
    }

    useEffect(() => {
        getProfile();
    }, [])


    return (
        <View nativeID={'MainHeader'} style={[styles.container, {
            paddingTop: Dimensions.get('window').width > 1024 ? 15 : 0,
            backgroundColor: route.name === 'Home' ? Colors.TRANSPARENT : '#ECEBEF',
            borderBottomColor: route.name === 'Home' ? Colors.TRANSPARENT : '#E3E3E6',
            borderBottomWidth: route.name === 'Home' ? 0 : 1,
        }]} horizontal={true}>
            {Dimensions.get('window').width <= 1024 && (
                <View style={{
                    flex: 1,
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    width: '100%',
                    paddingTop: Platform.OS === 'web' ? 0 :
                        Platform.OS === 'ios' ? 35 : 15,
                    backgroundColor: Colors.WHITE,
                }}>
                    <TouchableOpacity style={{ flex: 0.3, marginLeft: 10 }} onPress={() => navigation.openDrawer()}>
                        <View style={{ borderRadius: 20, backgroundColor: '#fff', width: 40, height: 40, padding: 5 }}>
                            <Entypo name="menu" size={30} color={Colors.PURPLE} />
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity style={{ flex: 0.7, paddingTop: 7, paddingLeft: 20 }} onPress={() => {
                        navigation.reset({
                            index: 0,
                            routes: [{ name: 'Home' }],
                        });
                    }}>
                        <Image
                            style={{
                                resizeMode: 'contain',
                                width: Dimensions.get('window').width / 3,
                                height: 30,
                            }}
                            source={require('../../assets/components/Footer/LOGO_FOOTER_BENQ_COLOR.png')}
                        ></Image>
                    </TouchableOpacity>
                    <TouchableOpacity style={{}} onPress={() => navigation.navigate('Perfil')}>
                        <View style={{ borderRadius: 20, backgroundColor: '#fff', width: 40, height: 40, padding: 7 }}>
                            <Image
                                style={{
                                    resizeMode: 'contain',
                                    width: 25,
                                    height: 25,
                                }}
                                source={require('../../assets/perfil-icono-app-benq.png')}
                            ></Image>
                        </View>
                    </TouchableOpacity>
                </View>
            )}
            {Dimensions.get('window').width > 1024 && menu && menu.map((item, index) => {

                return (
                    <TouchableOpacity
                        key={index}
                        style={[styles.button]}
                        onPress={() => {
                            item.component === 'Asistente' ?
                                navigation.reset({
                                    index: 0,
                                    routes: [{ name: 'Asistente' }],
                                }) :
                                navigation.dispatch(
                                    navigation.navigate(item.component)
                                );
                        }}
                    >
                        {
                            (!item.screenImage &&
                                (
                                    <Text
                                        style={[
                                            Global.default.titleFont,
                                            styles.text,
                                            {
                                                backgroundColor: route.name === item.component ? Colors.PURPLE : 'transparent',
                                                paddingHorizontal: Dimensions.get('window').width / 80,
                                                paddingVertical: 5,
                                                borderRadius: 50,
                                                color: route.name === item.component ? Colors.WHITE : route.name === 'Home' ? Colors.WHITE : Colors.BLACK,
                                            }
                                        ]}

                                    >
                                        {locale.in[item.inKey]}
                                    </Text>
                                ) ||
                                (
                                    <Image source={route.name === 'Home' ? item.screenImage : item.screenImage2} style={[styles.image]} />
                                )
                            )
                        }
                    </TouchableOpacity>
                )

            })}
            {
                Dimensions.get('window').width >= 1300 && (
                    <View style={{
                        marginLeft: Dimensions.get('window').width - (menu.length - 1) * 155 - Dimensions.get('window').width / 6
                    }}>
                        <SearchComponent navigation={navigation} route={route} />
                        <LocaleComponentNewSelect route={route} />
                    </View>
                )
            }
        </View>
    )
};

export default Header;

const styles = StyleSheet.create({
    container: {
        // alignItems:'center',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        paddingVertical: 10,
        minHeight: 60,
    },
    button: {
        marginHorizontal: 0,
        justifyContent: 'center',
    },
    text: {
        fontSize: 15,
        textTransform: 'uppercase'
    },
    image: {
        width: 200,
        height: 35,
        resizeMode: 'contain',
    },
    formInput: {
        borderRadius: 25,
        borderWidth: 1,
        padding: 7,
        paddingHorizontal: 15,
        backgroundColor: 'transparent'
    },
});
