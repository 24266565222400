import {
  FETCHING_CATEGORIES_PENDING,
  FETCHING_CATEGORIES_SUCCESS,
  FETCHING_CATEGORIES_FAILURE
} from '../constants/actionTypes';

const initialState = {
  categoriesIsFetching: false,
  categoriesError: false,
  categoriesData: {}
}

const categories = (state = initialState, action) => {

  switch (action.type) {

    case 'SET_CATEGORIES':
      return {
        categoriesData: action.payload,
          categoriesIsFetching: false,
          categoriesError: false
      };


    // Inicio de casos para API
    case FETCHING_CATEGORIES_PENDING:
      return {
        categoriesData: {},
          categoriesIsFetching: true,
          categoriesError: false
      }
      case FETCHING_CATEGORIES_SUCCESS:
        return {
          categoriesData: action.data,
            categoriesIsFetching: false,
            categoriesError: false
        }
        case FETCHING_CATEGORIES_FAILURE:
          return {
            categoriesData: {},
              categoriesError: true,
              categoriesIsFetching: false
          }
          default:
            return state
  }
}

export default categories;