import { API_URL, AUTH_TOKEN, ENV } from '../constants/endPoints';

export default (method, request) => {

  let appHeaders = new Headers();
  appHeaders.append('Content-Type', 'application/json');
  appHeaders.append('Authorization', AUTH_TOKEN);

  if (ENV === 'dev') {
    //console.log(method, request);
  }

  if (method === 'getHome') {
    //console.log(`${API_URL}pages/?type=home.HomePage&locale=${request.locale.country.language}&fields=*`);
    return fetch(`${API_URL}pages/?type=home.HomePage&locale=${request.locale.country.language}&fields=*`, {
      method: 'GET',
      headers: appHeaders,
      cache: 'default',
    })
      .then(
        (response) => Promise.all([response, response.json()])
      )
      .catch((error) => {
        console.log(error);
      })
  }

};
