import {
  API_URL,
  AUTH_TOKEN,
  ENV
} from '../constants/endPoints';

export default async (method, request) => {

  let appHeaders = new Headers();
  appHeaders.append('Content-Type', 'application/json');
  appHeaders.append('Authorization', AUTH_TOKEN);

  if (ENV === 'dev') {
    console.log(method, request);
  }

  if (method === 'registerUser') {
    try {
      const data = request;
      const response = await fetch(`${API_URL}user/register-user/`, {
        method: 'POST',
        headers: appHeaders,
        cache: 'default',
        body: data
      });
      return await Promise.all([response, response.json()]);
    } catch (error) {
      console.log(error);
    }
  }
  if (method === 'requestDeletion') {
    try {
      const data = request;
      const response = await fetch(`${API_URL}user/request-deletion/`, {
        method: 'POST',
        headers: appHeaders,
        cache: 'default',
        body: JSON.stringify(data)
      });
      return await Promise.all([response, response.json()]);
    } catch (error) {
      console.log(error);
    }
  }
  if (method === 'loginUser') {
    try {
      const response = await fetch(`${API_URL}user/login-user/?username=${request.username}&password=${request.password}`, {
        method: 'GET',
        headers: appHeaders,
        cache: 'default',
        credentials: 'include'
      });
      return await Promise.all([response, response.json()]);
    } catch (error) {
      console.log(error);
    }
  }

  if (method === 'getProfile') {
    try {
      const response = await fetch(`${API_URL}user/get-user/?id=${request}`, {
        method: 'GET',
        headers: appHeaders,
        cache: 'default'
      });
      return await Promise.all([response, response.json()]);
    }
    catch (error) {
      console.log(error);
    }
  }

  if (method === 'getProfileItems') {
    try {
      const response = await fetch(`${API_URL}user/item-user/?id=${request}`, {
        method: 'GET',
        headers: appHeaders,
        cache: 'default'
      });
      return await Promise.all([response, response.json()]);
    }
    catch (error) {
      console.log(error);
    }
  }

  if (method === 'uploadAvatar') {
      let formData = new FormData();
      formData.append('avatar', request.avatar);
      formData.append('id', request.id);
      return fetch(`${API_URL}user/upload-avatar/`, {
        method: 'POST',
        cache: 'default',
        redirect: 'follow',
        body:formData
      })
      .then((response) => Promise.all([response, response.json()]));
  }

  if (method === 'saveItem') {
    try {
      const data = request;
      const response = await fetch(`${API_URL}user/save-item-user/`, {
        method: 'POST',
        headers: appHeaders,
        cache: 'default',
        body: JSON.stringify(data)
      });
      return await Promise.all([response, response.json()]);
    } catch (error) {
      console.log(error);
    }
  }

  if (method === 'deleteItem') {
    try {
      const data = request;
      const response = await fetch(`${API_URL}user/delete-item-user/`, {
        method: 'POST',
        headers: appHeaders,
        cache: 'default',
        body: JSON.stringify(data)
      });
      return await Promise.all([response, response.json()]);
    } catch (error) {
      console.log(error);
    }
  }

  if (method === 'updateProfile') {
    try {
      const response = await fetch(`${API_URL}user/update-user/`, {
        method: 'POST',
        headers: appHeaders,
        cache: 'default',
        body: request
      });
      return await Promise.all([response, response.json()]);
    }
    catch (error) {
      console.log(error);
    }
  }

  if (method === 'updateDeviceToken') {
    try {
      const response = await fetch(`${API_URL}notificatons/register-device/`, {
        method: 'POST',
        headers: appHeaders,
        cache: 'default',
        body: request
      });
      return await Promise.all([response, response.json()]);
    }
    catch (error) {
      console.log(error);
    }
  }

  if (method === 'recoverPassword') {
    try {
      const data = request;
      const response = await fetch(`${API_URL}user/recover-password/`, {
        method: 'POST',
        headers: appHeaders,
        cache: 'default',
        body: JSON.stringify(data)
      });
      return await Promise.all([response, response.json()]);
    }
    catch (error) {
      console.log(error);
    }
  }

  if (method === 'changePassword') {
    try {
      const data = request;
      const response = await fetch(`${API_URL}user/change-password/`, {
        method: 'POST',
        headers: appHeaders,
        cache: 'default',
        body: JSON.stringify(data)
      });
      return await Promise.all([response, response.json()]);
    }
    catch (error) {
      console.log(error);
    }
  }


};