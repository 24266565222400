import React from "react";
import {
  Dimensions,
  Platform,
  Text,
  View,
  StyleSheet,
  FlatList,
  Pressable,
  Image
} from "react-native";
import { WebView as WebViewWeb }  from "react-native-web-webview";
import { WebView as WebViewMobile}  from "react-native-webview";
import youtube_parser from '../utils/extractYoutube';
import stripTags from '../utils/stripTags';
import * as Colors from "../styles/colors";
import globalStyles from '../styles/global';
import SaveAndShare from "./saveandshare/SaveAndShare";

class VideoCardProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      length: 0,
      page: 1
    };
  }

  VideoCards = (video) => {

    return (
      <View style={styles.card}>
        <View style={styles.webViewContainer}>
        {
          Platform.OS == "web" && (
            <WebViewWeb
              style={{width: "100%" }}
              javaScriptEnabled={true}
              domStorageEnabled={true}
              source={{ uri: `https://www.youtube.com/embed/${youtube_parser(video.url)}` }}
            />
          ) 
          || (
            <WebViewMobile
              style={{
                width: Dimensions.get("window").width                            
              }}
              javaScriptEnabled={true}
              domStorageEnabled={true}
              source={{ uri:`https://www.youtube.com/embed/${youtube_parser(video.url)}` }}
            />
          )
        }
        </View>
      
        <SaveAndShare item={video} options={{share:true,delete: true,download: false, relative:true}} 
            handleGetItems={this.props.handleGetItems} />

        <View>
          <Text style={[styles.category, globalStyles.boldFont]}>
            {video.category}
          </Text>
          <Text numberOfLines={2} style={[styles.title, globalStyles.boldFont]} >
            {video.title}
          </Text>
          <Text numberOfLines={4}
            style={[styles.description, globalStyles.titleFont]}
          >
            {stripTags(video.desc)}
          </Text>
        </View>
      </View>
    )
  }

  Divider = () => {
    return ( 
      <View style={{marginTop: 10}}></View> 
    );
  };

  render() {
    return (
      <View style={{marginTop: 15}}>
        <View style={styles.cardContainer}>
          {
            this.props.videos.length ? (
              <FlatList 
                contentContainerStyle={{ flex: 1, justifyContent: "center" }}
                style={{marginTop: 40}}
                data={this.props.videos}
                renderItem={({item}) => this.VideoCards(item)}
                keyExtractor={item => String(item.id)}
                numColumns={Dimensions.get('window').width < 667 ? 1 : 3}
                ItemSeparatorComponent={this.Divider}
                initialNumToRender={10}
                onEndThreshold={0.5}
              />
            ) : (
              <View style={{flex: 1, justifyContent: 'center', alignContent: 'center'}}>
                <Text style={styles.emptyProducts}>No hay videos guardados por el momento</Text>
              </View>
            )
          }
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  cardContainer: {
    maxWidth: Dimensions.get("window").width,
    alignItems: "center",
    flexGrow: 1,
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  card: {
    backgroundColor: Colors.LIGTH_GRAY,
    width:
      Dimensions.get("window").width < 767
        ? '100%'
        : Platform.OS == "web" 
        ? Dimensions.get("window").width < 1400 ? "300px" : '380px'
        : Dimensions.get("window").width - 20,
    height:
      Platform.OS == "web"
        ? "640px"
        : 640,
    margin: Platform.OS == "web" && Dimensions.get('window').width > 667 
      ? "10px" 
      : Dimensions.get('window').width > 667
      ? 10 : 0,
    marginBottom: 10,
    marginStart: 0,
    marginEnd: 20,
    alignItems: "center",
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
  },
  webViewContainer: {
    width: "100%",
    height: "55%",
    backgroundColor: Colors.BLACK,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    overflow: 'hidden'
  },
  category: {
    color: Colors.PURPLE,
    fontSize: Platform.OS == "web" ? "18px" : 18,
    paddingHorizontal: Platform.OS == "web" ? "20px" : 20,
    marginTop: 30,
  },
  title: {
    color: Colors.BLACK,
    fontSize: Platform.OS == "web" ? "25px" : 25,
    paddingHorizontal: Platform.OS == "web" ? "20px" : 20,
    marginTop: 0,
  },
  description: {
    color: Colors.GRAY,
    fontSize: Platform.OS == "web" ? "18px" : 18,
    paddingHorizontal: Platform.OS == "web" ? "20px" : 20,
    marginTop: 10
  },
  emptyProducts: {
    color: Colors.PURPLE,
    fontSize: Dimensions.get('window').width > 667 ? 30 : 22,
    textTransform: 'uppercase',
    marginTop: 15,
    textAlign: 'center'
  },
  deleteButton: {
    paddingHorizontal: Platform.OS === "web" ? "20px" : 20,
    alignSelf: 'flex-end',
    paddingTop: 10
  }
});

export default VideoCardProfile;
