import {
  FETCHING_PROFILE_PENDING,
  FETCHING_PROFILE_SUCCESS,
  FETCHING_PROFILE_FAILURE
} from '../constants/actionTypes';

const initialState = {
  profileIsFetching: false,
  profileError: false,
  profileData: {}
}

const profile = (state = initialState, action) => {

  switch (action.type) {

    case 'SET_PROFILE':
      return {
          profileData: action.payload,
          profileIsFetching: false,
          profileError: false
      };


    // Inicio de casos para API
    case FETCHING_PROFILE_PENDING:
      return {
        profileData: {},
          profileIsFetching: true,
          profileError: false
      }
      case FETCHING_PROFILE_SUCCESS:
        return {
          profileData: action.data,
            profileIsFetching: false,
            profileError: false
        }
        case FETCHING_PROFILE_FAILURE:
          return {
            profileData: {},
              profileError: true,
              profileIsFetching: false
          }
          default:
            return state
  }
}

export default profile;