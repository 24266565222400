import * as React from 'react';
import { useEffect } from 'react';
import {
  Pressable,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  View,
  TouchableOpacity,
  Text,
  Platform,
  Dimensions,
  Image,
  ActivityIndicator,
  Linking,
  ImageBackground,
} from 'react-native';
import globalStyles from '../styles/global';
import * as Colors from '../styles/colors';
import mapIcon from '../../assets/maps-icon.png';
import Select from '../components/Select';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import * as actions from '../actions/distributors';
import * as fetchData from '../constants/actionTypes.js';

import Footer from '../components/Footer';
import MapView, { Marker, Callout } from 'react-native-maps';


let markers = [];

function DistributorsSection(props) {
  const locale = useSelector((state) => state.locale)
  const [distributors, setDistributors] = React.useState(props.distributors.distributorData.items || null);
  const [distributorsReady, setDistributorsReady] = React.useState(false);

  const [country, setCountry] = React.useState(1);
  const [state, setState] = React.useState(1);
  const [countries, setCountries] = React.useState([]);
  const [states, setStates] = React.useState([]);
  const [map, setMap] = React.useState(null);
  const [countryValue, setCountryValue] = React.useState('');
  const [cityValue, setCityValue] = React.useState('');
  const [dataCountries, setDataCountries] = React.useState([]);
  const [dataCities, setDataCities] = React.useState('');
  const { width: WIDTH, height: HEIGHT } = Dimensions.get('window');

  async function getDistributors(data) {
    await actions
      .getDistributors(data)
      .then(res => {
        if (res.type == fetchData.FETCHING_DISTRIBUTOR_SUCCESS) {
          props.setDistributor(res.distributorData);
          markers = [];
          console.log('res', res);

          setDistributors(res.distributorData.items);

          setTimeout(() => {
            setDistributorsReady(true);
          }, 1500)

        }
      }).catch(err => {
        console.log(err);
      });
  }

  async function getCountries() {
    await actions
      .getCountries()
      .then(res => {
        if (res.type == fetchData.FETCHING_DISTRIBUTOR_SUCCESS) {
          setCountries(res.distributorData.items);
          getStates(res.distributorData.items[0].id);
        }
      }).catch(err => {
        console.log(err);
      });
  }

  async function getStates(country) {
    await actions
      .getStates(country)
      .then(res => {
        if (res.type == fetchData.FETCHING_DISTRIBUTOR_SUCCESS) {
          setStates(res.distributorData.items);
          getDistributors({
            country: country,
            state: state,
          });
        }
      }).catch(err => {
        console.log(err);
      });
  }

  const onChangeCountry = (value) => {
    setCountryValue(value);
  };
  const onChangeCity = (value) => {
    setCityValue(value);
  };

  React.useEffect(() => {
    if (Platform.OS == 'web') {
      var s = document.createElement('script');
      s.async = true;
      s.defer = true;
      s.type = 'text/javascript';
      s.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCgoMvc-NfTZt5N4w26-Jg4vekiAtu4uEo';
      document.querySelector('head').append(s);
    }

    setTimeout(() => {
      getCountries()
    }, 1500)
  }, []);

  useEffect(() => {
    const data = [];
    countries.forEach(item => {
      data.push({ label: item.meta.name.toUpperCase(), value: item.id.toString() });
    });
    setDataCountries(data);
  }, [countries]);

  useEffect(() => {
    const data = [];
    states.forEach(item => {
      data.push({ label: item.meta.name.toUpperCase(), value: item.id.toString() });
    });
    setDataCities(data);
  }, [states]);

  return (
    Platform.OS === 'web' ? (
      distributorsReady ? (
        <ScrollView>
          <ImageBackground source={require('../../assets/FONDO_TEXTURA_BENQ.jpg')}>
            <View style={globalStyles.sectionContainer}>
              <Text style={globalStyles.sectionTitle}>Distribuidores</Text>
              <Text style={globalStyles.sectionSubTitle}>
                {locale.in['distributors_paragraph']}
              </Text>

              <Select
                name={'cboCountry'}
                label={'SELECCIONA UN PAIS'}
                onValueChange={(newValue) => {
                  onChangeCountry(newValue);
                  if (newValue.value) {
                    setCountry(newValue.value);
                    setCityValue('');
                    getStates(newValue.value).then();
                  }
                }}
                dataItems={dataCountries}
                value={countryValue}
              />

              <Select
                name={'cboCity'}
                label={'SELECCIONA UN ESTADO'}
                onValueChange={(newValue) => {
                  onChangeCity(newValue);
                  if (newValue.value) {
                    setState(newValue.value);
                    getDistributors({
                      country: country,
                      state: newValue.value,
                    }).then();
                  }
                }}
                dataItems={dataCities}
                value={cityValue}
              />

              <View style={styles.containerSections}>
                {
                  distributors && distributors.length ? (
                    <>
                      <ScrollView style={[globalStyles.column, styles.containerDistributors, {
                        marginRight: 10,
                        flex: Dimensions.get('window').width >= 767 ? .4 : 1,
                      }]}
                      >
                        {
                          distributors ?
                            distributors.map(distributor => (
                              <View key={'distributor-' + distributor.id} style={[globalStyles.column, { margin: 15 }]}>
                                <TouchableOpacity
                                  onPress={() => {
                                    //console.log('distributor',distributor)
                                    map.animateCamera({
                                      zoom: 16,
                                      center: {
                                        latitude: distributor.latitude,
                                        longitude: distributor.longitude,
                                      },
                                    });
                                  }}
                                >
                                  <Text style={styles.distributorStore}>{distributor.title}</Text>
                                  <Text style={{ maxWidth: '80%' }}>{distributor.address}</Text>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => {
                                  Linking.openURL('tel://' + distributor.phone)
                                }}>
                                  <Text style={styles.distributorStore}>{distributor.phone}</Text>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => {
                                  Linking.openURL(distributor.website)
                                }}>
                                  <Text style={styles.distributorStore}>{distributor.website}</Text>
                                </TouchableOpacity>
                                <Pressable
                                  style={[globalStyles.row, { marginVertical: 15 }]}
                                  onPress={() => {
                                    Linking.openURL('https://www.google.com/maps/search/?api=1&query=' + distributor.latitude + ',' + distributor.longitude);
                                  }}
                                >
                                  <Image source={mapIcon} style={styles.icon} />
                                  <Text style={styles.textInMaps}>VER EN GOOGLE MAPS</Text>
                                </Pressable>
                                <View style={{ borderBottomColor: '#888', borderBottomWidth: 1 }} />
                              </View>
                            ))
                            :
                            <View style={{ flex: 1, justifyContent: 'center' }}>
                              <Text>No hay distribuidores</Text>
                            </View>
                        }
                      </ScrollView>

                      <View style={[globalStyles.column, styles.containerMap]}>
                        <MapView
                          ref={map => setMap(map)}
                          defaultZoom={12}
                          style={{
                            flex: 1,
                            width: '100%',
                            height: Dimensions.get('window').width >= 767 ? '100%' : HEIGHT,
                          }}
                          initialRegion={{
                            latitude: distributors[0].latitude ? distributors[0].latitude : 0,
                            longitude: distributors[0].longitude ? distributors[0].longitude : 0,
                            latitudeDelta: 0.0922,
                            longitudeDelta: 0.0421,
                          }}
                          options={{
                            zoomControlOptions: false,
                            mapTypeControl: false,
                            streetViewControl: false,
                            fullscreenControl: false,
                          }}
                          provider={'google'}
                        >
                          {
                            distributors.map((distributor, idx) => {
                              return (<MapView.Marker
                                key={'marker-' + distributor.id}
                                title={distributor.title}
                                description={distributor.address}
                                coordinate={{
                                  latitude: distributor.latitude ? distributor.latitude : 0,
                                  longitude: distributor.longitude ? distributor.longitude : 0,
                                }}
                                ref={marker => {
                                  markers.push(marker);
                                }}
                                onPress={() => {
                                  markers[idx].showCallout();
                                }}
                              >
                                <MapView.Callout onPress={console.log('onPress callout')}>
                                  <View style={{ padding: 10 }}>
                                    <Text>{distributor.title}</Text>
                                  </View>
                                </MapView.Callout>
                              </MapView.Marker>);
                            })
                          }
                        </MapView>
                      </View>
                    </>
                  ) : (
                    <View style={styles.emptyDistributorsContainer}>
                      {cityValue != '' && (
                        <Text style={styles.emptyDistributors}>
                          Por el momento no hay distribuidores
                        </Text>
                      ) || (
                          <Text style={styles.emptyDistributors}>
                            Selecciona un estado
                          </Text>
                        )}

                    </View>
                  )
                }
              </View>
            </View>
            <Footer {...props} />
          </ImageBackground>
        </ScrollView>
      )
        :
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <ActivityIndicator size="large" color={Colors.PURPLE} />
        </View>
    ) : (
      <ImageBackground source={require('../../assets/FONDO_TEXTURA_BENQ.jpg')} style={{ width: '100%', height: '100%' }}>
        <View style={globalStyles.sectionContainer}>
          <Text style={globalStyles.sectionTitle}>Distribuidores</Text>
          <Text style={globalStyles.sectionSubTitle}>
            {locale.in['distributors_paragraph']}
          </Text>

          <Select
            name={'cboCountry'}
            label={'SELECCIONA UN PAIS'}
            onValueChange={(newValue) => {
              onChangeCountry(newValue);
              if (newValue.value) {
                setCountry(newValue.value);
                setCityValue('');
                getStates(newValue.value);
              }
            }}
            dataItems={dataCountries}
            value={countryValue}
          />

          <Select
            name={'cboCity'}
            label={'SELECCIONA UN ESTADO'}
            onValueChange={(newValue) => {
              onChangeCity(newValue);
              if (newValue.value) {
                setState(newValue.value);
                getDistributors({
                  country: country,
                  state: newValue.value,
                }).then();
              }
            }}
            dataItems={dataCities}
            value={cityValue}
          />


          <View>
            {distributors && distributors.length > 0 && (<ScrollView style={[{
              height: 160
            }]}
            >
              {distributors.map(distributor => (
                <View key={'distributor-' + distributor.id} style={[globalStyles.column, { margin: 15 }]}>
                  <TouchableOpacity
                    onPress={() => {
                      map.animateCamera({
                        zoom: 16,
                        center: {
                          latitude: distributor.latitude,
                          longitude: distributor.longitude,
                        },
                      });
                    }}
                  >
                    <Text style={styles.distributorStore}>{distributor.title}</Text>
                    <Text style={{ maxWidth: '80%' }}>{distributor.address}</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => {
                    Linking.openURL('tel://' + distributor.phone)
                  }}>
                    <Text style={styles.distributorStore}>{distributor.phone}</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => {
                    Linking.openURL(distributor.website)
                  }}>
                    <Text style={styles.distributorStore}>{distributor.website}</Text>
                  </TouchableOpacity>
                  <Pressable
                    style={[globalStyles.row, { marginVertical: 15 }]}
                    onPress={() => {
                      Linking.openURL('https://www.google.com/maps/search/?api=1&query=' + distributor.latitude + ',' + distributor.longitude);
                    }}
                  >
                    <Image source={mapIcon} style={styles.icon} />
                    <Text style={styles.textInMaps}>VER EN GOOGLE MAPS</Text>
                  </Pressable>
                  <View style={{ borderBottomColor: '#888', borderBottomWidth: 1 }} />
                </View>
              ))}
            </ScrollView>
            )}

          </View>

          {distributors && distributors.length > 0 && (
            <MapView
              provider='google'
              ref={map => setMap(map)}
              defaultZoom={12}
              style={{
                flex: 1,
                width: '100%',
                height: Dimensions.get('window').width >= 767 ? '100%' : HEIGHT,
              }}
              initialRegion={{
                latitude: distributors[0].latitude ? distributors[0].latitude : 0,
                longitude: distributors[0].longitude ? distributors[0].longitude : 0,
                latitudeDelta: 0.0922,
                longitudeDelta: 0.0421,
              }}
              options={{
                zoomControlOptions: false,
                mapTypeControl: false,
                streetViewControl: false,
                fullscreenControl: false,
              }}
            >
              {
                distributors.map((distributor, idx) => {
                  return (<Marker
                    key={'marker-' + distributor.id}
                    title={distributor.title}
                    description={distributor.address}
                    coordinate={{
                      latitude: distributor.latitude ? distributor.latitude : 0,
                      longitude: distributor.longitude ? distributor.longitude : 0,
                    }}
                    ref={marker => {
                      markers.push(marker);
                    }}
                    onPress={() => {
                      markers[idx].showCallout();
                    }}
                  >
                    <Callout onPress={console.log('onPress callout')}>
                      <View style={{ padding: 10 }}>
                        <Text>{distributor.title}</Text>
                      </View>
                    </Callout>
                  </Marker>);
                })
              }
            </MapView>
          )}



        </View>


      </ImageBackground>
    )
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: Platform.OS === 'web' ? 60 : 40,
    marginVertical: 40,
  },
  title: {
    fontSize: Dimensions.get('window').width < 767 ? 26 : 32,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  containerDistributors: {
    borderRadius: 10,
    padding: 20,
    backgroundColor: '#fff',
    width: Platform.OS === 'web' ? 100 : '100%',
    marginBottom: Platform.OS == 'web' ? 0 : 10
  },
  containerSelect: {
    flex: 1,
    width: '100%',
    height: Platform.OS === 'web'
      ? '50px'
      : 50,
    // backgroundColor: 'red',
  },
  picker: {
    height: Platform.OS === 'web' ? 50 : 'auto',
    width: Dimensions.get('window').width < 767 ? '100%' : 310,
    marginVertical: 10,
    position: 'relative',
  },
  selectControlStyle: {
    borderRadius: 25,
    borderBottomLeftRadius: 25,
    borderBottomRightRadius: 25,
    backgroundColor: 'transparent',
    borderColor: '#400c6d',
    padding: 10,
  },
  optionsListStyle: {
    position: Platform.OS == 'web' ? 'fixed' : 'absolute',
    borderColor: '#fff',
    marginTop: Platform.OS == 'web' ? 0 : -40,
  },
  selectControlArrowImageStyle: {
    marginTop: Platform.OS == 'web' ? 0 : 15,
  },
  distributorStore: {
    fontWeight: 'bold',
    color: Colors.PURPLE,
  },
  icon: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },
  textInMaps: {
    fontWeight: 'bold',
    fontSize: 12,
    marginLeft: 10,
    marginVertical: 'auto',
  },
  containerMap: {
    justifyContent: 'center',
    backgroundColor: '#fff',
    alignItems: 'center',
    flex: 1,
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    zIndex: 5,
    elevation: 5,
  },
  containerSections: {
    height: Platform.OS == 'web' ? 400 : 'auto',
    flexDirection: Platform.OS == 'web' ? 'row' : 'column',
    width: '100%',
    marginTop: 10,
    justifyContent: 'center'
  },
  emptyDistributorsContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 100
  },
  emptyDistributors: {
    textTransform: 'uppercase',
    color: Colors.PURPLE,
    fontSize: Platform.OS == 'web' ? 28 : 14,
    fontWeight: '600'
  }
});

const mapStateToProps = (state) => {
  return {
    distributors: state.distributor,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setDistributor: (data) => {
      return dispatch(actions.setDistributor(data));
    },
  };
};
const Distributors = connect(mapStateToProps, mapDispatchToProps)(DistributorsSection);

export default Distributors;
