import {
  FETCHING_DISTRIBUTOR_PENDING,
  FETCHING_DISTRIBUTOR_SUCCESS,
  FETCHING_DISTRIBUTOR_FAILURE,
} from '../constants/actionTypes.js';
import api from '../api/distributors';

export const getDistributors = async (data) => {
  try {
    const [response, json] = await api('getDistributors', data);
    json.statusCode = response.status;
    return getDataSuccess(json);
  } catch (err) {
    console.log(err);
    return getDataFailure(err);
  }
};
export const getCountries = async (data) => {
  try {
    const [response, json] = await api('getCountries', data);
    json.statusCode = response.status;
    return getDataSuccess(json);
  } catch (err) {
    console.log(err);
    return getDataFailure(err);
  }
};
export const getStates = async (data) => {
  try {
    const [response, json] = await api('getStates', data);
    json.statusCode = response.status;
    return getDataSuccess(json);
  } catch (err) {
    console.log(err);
    return getDataFailure(err);
  }
};

export const setDistributor = (data) => {
  return {
    type: 'SET_DISTRIBUTOR',
    payload: data
  }
}

//API Requests
export const getData = () => {
  return {
    type: FETCHING_DISTRIBUTOR_PENDING,
  };
};
export const getDataSuccess = (json) => {
  return {
    type: FETCHING_DISTRIBUTOR_SUCCESS,
    distributorData: json
  };
};
export const getDataFailure = () => {
  return {
    type: FETCHING_DISTRIBUTOR_FAILURE,
  };
};