import React, {} from 'react';
import {
  ScrollView,
  View,
  Text,
  Image,
  Platform,
  Dimensions,
  FlatList,
  TouchableOpacity,
  StyleSheet, ActivityIndicator,
} from 'react-native';
import { connect }  from 'react-redux';
import * as Colors from '../styles/colors';
import * as actions from '../actions/articles';
import * as F  from '../constants/actionTypes.js';
import { BASE_URL } from "../constants/endPoints";
import stripTags from '../utils/stripTags';
import Footer from "../components/Footer";
import SaveAndShare from '../components/saveandshare/SaveAndShare';

class ArticulosContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: [],
      length: 0,
      intro: '',
      page: 1,
      articlesReady: false,
    };
  }

  async componentDidMount() {
    await actions.getArticles({
      locale: this.props.locale,
      offset: 0
    }).then(res => {
      if(res.type == F.FETCHING_ARTICLES_SUCCESS){
        //console.log(res.contentData)
        this.setState({
          intro: res.contentData.items ? res.contentData.items[0].intro : '',
          articles: res.contentData.items,
          length: res.contentData.meta.total_count,
          articlesReady: true,
        });
      }
    }).catch(err => {
      console.log(err);
    });
  }
  Header = () => {
    return (
      <View style={styles.header}>
        <Text style={{fontSize: 60, marginBottom: 20}}>
          {this.props.locale.in['articles']}
        </Text>
        <Text style={{color: 'gray', fontSize: 25, textAlign: 'center'}}>
          {this.state.intro}
        </Text>
      </View>
    );
  };
  Divider = () => {
    return (
      <View style={{marginTop:20}}>
      </View>
    );
  };
  ItemRender = (item) => {
    return (
      <TouchableOpacity
        style={styles.itemContainer}
        key={this.state.articles.id}
        onPress={() => {
          //console.log('item =>', item);
          this.props.navigation.navigate("Articulo", {
            id: item.id,
            //item: item,
          });
        }}
      >
        <View style={styles.itemImageContainer}>
          {item.image && (
            <Image
              source={{uri: BASE_URL+item.image.url}}
              style={styles.itemImage}
              resizeMode="cover"
            />
          )}
          <SaveAndShare item={item} options={{share:true,save:true,download:false}} />
        </View>
        <View>
          <Text style={styles.itemCategory}>{item.category}</Text>
          <Text numberOfLines={2} style={styles.itemTitle}>{item.title}</Text>
          <Text numberOfLines={4} style={styles.itemDescription}>{stripTags(item.desc)}</Text>
        </View>
      </TouchableOpacity>
    );
  };
  onEnd = () => {
    const pagesize = 20;
    const page = this.state.page + 1;
    const offset = (page - 1) * pagesize;
    const limit = pagesize * page;

    //console.log('offset =>', offset);
    //console.log('limit =>', limit);

    if (this.state.length > offset) {
      actions.getArticles({
        locale: this.props.locale,
        offset: offset
      }).then(res => {
        if(res.type == F.FETCHING_ARTICLES_SUCCESS){
          //console.log(res.contentData)
          this.setState({
            articles: this.state.articles.concat(res.contentData.items),
            length: res.contentData.meta.total_count,
            page: page,
          });
        }
      }).catch(err => {
        console.log(err);
      });
    }

  };

  render() {
    return (
      <View  style={styles.container}>
        {this.state.articlesReady ? (
            <FlatList
              style={{
                marginTop: Dimensions.get('window').width >= 768  || Platform.OS == 'android' ? 50 : 0,
                height: Dimensions.get('window').height,
                width: Dimensions.get('window').width,
              }}
              contentContainerStyle={{alignItems: 'center'}}
              ListHeaderComponent={this.Header}
              ListFooterComponent={<Footer {...this.props} />}
              ListHeaderComponentStyle={{alignItems:'center', width: Dimensions.get('window').width,marginBottom: 50}}
              ListFooterComponentStyle={{marginTop: 30, width: '100%'}}
              data={this.state.articles}
              renderItem={({item}) => this.ItemRender(item)}
              ItemSeparatorComponent={this.Divider}
              keyExtractor={item => item.id}
              numColumns={Dimensions.get('window').width < 767 ? 1 : 2}
              onEndReached={this.onEnd}
              initialNumToRender={10}
              onEndThreshold={0.5}
            />
        ) : (
          <View style={{flex: 1, justifyContent: 'center'}}>
            <ActivityIndicator size="large" color={Colors.PURPLE} />
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  header:{
    flexDirection: 'column',
    minHeight: 180,
    alignItems: 'center',
    width: '100%',
    maxWidth: 880,
  },
  container: {
    marginTop: Dimensions.get('window').width >= 768  ? 50 :  Platform.OS == 'ios' ? 70 :  0,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1
  },
  itemDescription:{
    color: Colors.GRAY,
    fontSize: Platform.OS == 'web' ? '18px' : 18,
    paddingHorizontal: Platform.OS == 'web' ? '20px' : 20,
    marginTop: 10,
  },
  itemTitle:{
    color: Colors.BLACK,
    fontSize: Platform.OS == 'web' ? '25px' : 25,
    paddingHorizontal: Platform.OS == 'web' ? '20px' : 20,
    marginTop: 0,
  },
  itemCategory:{
    color: Colors.PURPLE,
    fontSize: Platform.OS == 'web' ? '18px' : 18,
    paddingHorizontal: Platform.OS == 'web' ? '20px' : 20,
    marginTop: 20,
  },
  itemImage:{
    width: '100%',
    height: '100%',
  },
  itemImageContainer:{
    width: '100%',
    height: '50%',
    backgroundColor: Colors.PURPLE,
  },
  itemContainer: {
    borderRadius:15,
    backgroundColor: Colors.LIGTH_GRAY,
    width: Dimensions.get('window').width < 767 ?
      Dimensions.get('window').width - 20
      :
      Platform.OS == 'web' ? '420px' : Dimensions.get('window').width - 20,
    height: Platform.OS == 'web' ? '550px' : Dimensions.get('window').width * 1.5,
    marginHorizontal: Platform.OS == 'web' ? '10px' : 10,
    alignItems: 'center',
  }
})

const mapStateToProps = (state) => {
  return {
    locale: state.locale,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {}
}

const Articulos = connect(mapStateToProps,mapDispatchToProps)(ArticulosContainer);
export default Articulos;
