import * as React from "react";

import Contact from "../components/contact/";

function Contacto(props) {
  return (
    <>
      <Contact {...props}  />
    </>
  );
}
export default Contacto;
