import * as React from 'react';
import * as Global from "../styles/global";
import Footer from "../components/Footer";

import { 
    View,
    ScrollView,
    ImageBackground,
    Platform
} from 'react-native';
import { WebView as WebViewWeb }  from "react-native-web-webview";
import { WebView as WebViewMobile}  from "react-native-webview";

function Citas(props) {
    return (
        <ScrollView contentStyle={Global.default.sectionContainer}>
            <ImageBackground source={require('../../assets/FONDO_TEXTURA_BENQ.jpg')}>
            {
              Platform.OS == "web" && (
                <WebViewWeb
                androidLayerType="software"
                androidHardwareAccelerationDisabled
                style={{
                    minHeight: 1000,
                    marginTop: 100
                }}
                originWhitelist={['*']}
                  javaScriptEnabled={true}
                  domStorageEnabled={true}
                  source={{ html: `
                  <!-- Start of Meetings Embed Script -->
                  <div class="meetings-iframe-container" data-src=https://meetings.hubspot.com/info-latam3?embed=true></div>
                  <script type="text/javascript" src=https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js></script>
                  <!-- End of Meetings Embed Script -->
                ` }}
                />
              ) 
              || (
                <WebViewMobile
                androidLayerType="software"
                androidHardwareAccelerationDisabled
                style={{
                    minHeight: 700,
                    marginTop: 100
                }}
                  originWhitelist={['*']}
                  source={{ uri:'https://meetings.hubspot.com/info-latam3?embed=true' }}
                />
              )
            }
            <Footer {...props}/>
            </ImageBackground>
        </ScrollView>
    );
  }
export default Citas;