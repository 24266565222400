import * as React from 'react';
import {
  View,
  Text,
  StyleSheet,
  TextInput,
  Pressable,
  Dimensions,
  Platform,
  Image,
  ActivityIndicator
} from 'react-native';
import { connect } from 'react-redux';
import * as actions from '../actions/profile';

import * as Colors from '../styles/colors';
import globalStyles from '../styles/global';
import { BASE_URL, API_URL } from '../constants/endPoints';

import BouncyCheckbox from 'react-native-bouncy-checkbox';
import Select from '../components/Select';

import * as DocumentPicker from 'expo-document-picker';
import * as FileSystem from 'expo-file-system';


import * as actionsDistributors from '../actions/distributors';
import * as fetchData from '../constants/actionTypes';

class ProfileFormContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profileForm: {
        wantNotifications: false,
      },
      image: null,
      options: [
        { label: this.props.locale.in['wholesaler'].toUpperCase(), value: 'mayorista' },
        { label: this.props.locale.in['retail'].toUpperCase(), value: 'menorista' },
      ],
      userType: this.props.profile.profile === 'distributor' || this.props.profile.profile === 'wholesaler'
        ? { label: this.props.locale.in['wholesaler'].toUpperCase(), value: 'mayorista' }
        : { label: this.props.locale.in['retail'].toUpperCase(), value: 'menorista' },
      dataCountries: '',
      states: [],
      countries: [],
      country: null,
      state: null,
      offset: 0,
      countryValue: {
        label: '',
        value: '',
      },
      cityValue: {
        label: '',
        value: '',
      },
      profile: '',
    };
  }

  async componentDidMount() {
    //console.log('ProfileForm componentDidMount', this.props);

    await this.getCountries();
    const PROFILE = await this.chkProfile(this.props.profile.profile);

    this.setState({
      countryValue: {
        label: this.props.profile.country.name.toUpperCase(),
        value: this.props.profile.country.id.toString(),
      },
      cityValue: {
        label: this.props.profile.state.name.toUpperCase(),
        value: this.props.profile.state.id.toString(),
      },
      profile: PROFILE,
    });
  }

  getCountries = async () => {
    await actionsDistributors
      .getCountries()
      .then(res => {
        if (res.type == fetchData.FETCHING_DISTRIBUTOR_SUCCESS) {
          this.setState({ countries: res.distributorData.items });
          this.getStates(res.distributorData.items[0].id);
        }
      }).catch(err => {
        console.log(err);
      });
  };
  getStates = async (country) => {
    await actionsDistributors
      .getStates(country)
      .then(res => {
        //console.log('getStates => res =>', res);
        if (res.type == fetchData.FETCHING_DISTRIBUTOR_SUCCESS) {
          this.setState({ states: res.distributorData.items });
        }
      }).catch(err => {
        console.log(err);
      });
  };
  chkProfile = async (profile) => {
    return profile === 'distributor';
  };

  render() {
    if (this.props.profile && this.props.profile.user_id) {
      return (
        <View style={styles.formContainer}>
          <View style={[styles.form, { flexWrap: 'wrap' }]}>
            <View style={[styles.formColumn, { marginRight: Dimensions.get('window').width >= 767 ? 10 : 0 }]}>
              {this.state.profile === true && (
                <>
                  <View style={globalStyles.row}>
                    <View style={styles.selectLogo}>
                      {(this.props.profile.avatar || this.state.image) && (
                        <Image
                          source={{ uri: this.state.image ? BASE_URL + this.state.image : BASE_URL + this.props.profile.avatar }}
                          style={{ width: 100, height: 100, borderRadius: 25 }}
                        />
                      ) || (
                          <Text style={{ margin: 'auto', color: Colors.GRAY }}>+</Text>
                        )}
                    </View>
                    <Pressable onPress={() => {
                      DocumentPicker.getDocumentAsync({ type: 'image/*' })
                        .then(async (res) => {
                          if (res.type == 'success') {
                            if (Platform.OS !== 'web') {
                              const response = await FileSystem.uploadAsync(`${API_URL}user/upload-avatar/`, res.uri, {
                                fieldName: 'avatar',
                                httpMethod: 'POST',
                                uploadType: FileSystem.FileSystemUploadType.MULTIPART,
                                parameters: { id: this.props.profile.user_id }
                              });
                              if (response.status == 200) {
                                const body = JSON.parse(response.body)
                                this.setState({
                                  image: body.avatar,
                                });
                              }
                            }
                            else {
                              actions.uploadAvatar({
                                id: this.props.profile.user_id,
                                avatar: res.file
                              }).then(res => {
                                if (res.type == 'FETCHING_PROFILE_SUCCESS') {
                                  this.setState({
                                    image: res.profileData.avatar,
                                  });
                                }
                              }).catch(err => {
                                console.log(err);
                              });
                            }
                          }

                        })
                        .catch(err => {
                          console.log(err);
                        });

                    }}>
                      {(this.props.profile.avatar || this.state.image) && (
                        <Text style={styles.logoText}>{this.props.locale.in['change_your_logo'].toUpperCase()}</Text>
                      ) || (
                          <Text style={styles.logoText}>{this.props.locale.in['upload_your_logo'].toUpperCase()}</Text>
                        )}
                    </Pressable>
                  </View>
                  <TextInput
                    style={[styles.formInput, { marginTop: 20 }]}
                    placeholder={this.props.locale.in['company_name'].toUpperCase()}
                    value={this.props.profile.company || ''}
                    onChangeText={(text) => this.props.handleChangeText('company', text)}
                  />
                </>
              )}
              <TextInput
                style={[styles.formInput]}
                placeholder={this.props.locale.in['name'].toUpperCase()}
                value={this.props.profile.first_name || ''}
                onChangeText={(text) => this.props.handleChangeText('first_name', text)}
              />
              <TextInput
                style={[styles.formInput]}
                placeholder={this.props.locale.in['phone'].toUpperCase()}
                value={this.props.profile.phone || ''}
                keyboardType="number-pad"
                onChangeText={(text) => this.props.handleChangeText('phone', text)}
              />
              <TextInput
                style={[styles.formInput]}
                placeholder={this.props.locale.in['email'].toUpperCase()}
                value={this.props.profile.email || ''}
                keyboardType="email-address"
                onChangeText={(text) => this.props.handleChangeText('email', text)}
                editable={false}
              />
            </View>
            <View style={[styles.formColumn, { marginRight: Dimensions.get('window').width >= 767 ? 10 : 0 }]}>
              {this.state.profile === true && (
                <>
                  <Select
                    name={'cboUserType'}
                    innerStyles={{ width: '100%' }}
                    label={this.props.locale.in['choose'].toUpperCase()}
                    onValueChange={(item) => {
                      console.log('item', item)
                      if (item && item.value) {
                        this.setState({
                          ...this.state,
                          profileForm: {
                            ...this.state.profileForm,
                          },
                          userType: item,
                        });
                        this.props.userType({
                          label: item.label,
                          value: item.value,
                          id: item.value,
                        });
                      }
                    }}
                    dataItems={this.state.options}
                    value={this.state.userType}
                  />
                  <Select
                    name={'cboCountry'}
                    innerStyles={{ width: '100%' }}
                    label={this.props.locale.in['choose_a_country'].toUpperCase()}
                    onValueChange={(item) => {
                      if (item.value) {
                        this.setState({
                          state: '',
                          cityValue: {
                            label: '',
                            value: '',
                          },
                        });
                        this.setState({
                          country: {
                            label: item.label,
                            value: item.value,
                            id: item.value,
                          },
                          countryValue: {
                            label: item.label,
                            value: item.value,
                            id: item.value,
                          },
                        });
                        this.props.country({
                          label: item.label,
                          value: item.value,
                          id: item.value,
                        });
                        this.getStates(item.value);
                      }
                    }}
                    dataItems={[
                      ...this.state.countries.map(item => ({
                        label: item.meta.name.toUpperCase(),
                        value: item.id.toString(),
                      })),
                    ]}
                    value={this.state.countryValue}
                  />
                  <Select
                    name={'cboCity'}
                    label={this.props.locale.in['choose_a_state'].toUpperCase()}
                    innerStyles={{ width: '100%' }}
                    onValueChange={(item) => {
                      if (item.value) {
                        this.setState({
                          state: {
                            label: item.label,
                            value: item.value,
                            id: item.value,
                          },
                          cityValue: {
                            label: item.label,
                            value: item.value,
                            id: item.value,
                          },
                        });
                        this.props.state({
                          label: item.label,
                          value: item.value,
                          id: item.value,
                        });
                      }
                    }}
                    dataItems={[
                      ...this.state.states.map(item => ({
                        label: item.meta.name.toUpperCase(),
                        value: item.id.toString(),
                      })),
                    ]}
                    value={this.state.cityValue}
                  />
                </>
              )}
              <Text style={styles.subtitle}>{this.props.locale.in['change_your_password']}</Text>
              <TextInput
                style={[styles.formInput]}
                placeholder={this.props.locale.in['new_password'].toUpperCase()}
                onChangeText={(text) => this.props.handleChangeText('newPassword', text)}
              />
              <TextInput
                style={[styles.formInput]}
                placeholder={this.props.locale.in['confirm_password'].toUpperCase()}
                onChangeText={(text) => this.props.handleChangeText('repeatPassword', text)}
              />
              <BouncyCheckbox
                onPress={() => this.setState({
                  profileForm: {
                    ...this.state.profileForm,
                    wantNotifications: !this.state.profileForm.wantNotifications,
                  },
                })}
                text={this.props.locale.in['i_want_receive_notifications'].toUpperCase()}
                textStyle={styles.checkboxLabel}
                isChecked={this.state.profileForm.wantNotifications}
                style={{ marginTop: 20 }}
                fillColor={Colors.PURPLE}
              />
            </View>
          </View>
          <View style={[globalStyles.row]}>
            {
              this.props.editFlag ? (
                <View
                  style={[[Dimensions.get('window').width > 767 ? globalStyles.row : globalStyles.column], { width: '100%' }]}>
                  <Pressable
                    style={styles.btnSave}
                    onPress={() => {
                      this.props.handleUpdateProfile();
                    }}
                  >
                    <Text style={styles.btnText}>{this.props.locale.in['save'].toUpperCase()}</Text>
                  </Pressable>
                  <Pressable
                    style={styles.btnCancel}
                    onPress={() => {
                      this.props.handleEditProfile(false);
                    }}
                  >
                    <Text
                      style={[styles.btnText, { color: Colors.PURPLE }]}>{this.props.locale.in['cancel'].toUpperCase()}</Text>
                  </Pressable>
                </View>
              ) : (
                <Pressable
                  style={styles.btnSave}
                  onPress={() => {
                    this.props.handleEditProfile(true);
                  }}
                >
                  <Text style={styles.btnText}>{this.props.locale.in['edit']}</Text>
                </Pressable>
              )
            }
          </View>
        </View>
      );
    } else {
      return <ActivityIndicator size="large" color={Colors.PURPLE} />;
    }
  }
}

const {
  width: WIDTH,
} = Dimensions.get('window');
const styles = StyleSheet.create({
  formContainer: {
    backgroundColor: Colors.WHITE,
    borderRadius: 10,
    padding: Platform.OS == 'web' && WIDTH > 667 ? 50 : 15,
    marginVertical: 40,
  },
  form: {
    flexDirection: WIDTH >= 1200 ? 'row' : 'column',
  },
  formColumn: {
    width: '100%',
    padding: Platform.OS == 'web' && WIDTH > 667 ? 50 : 15,
  },
  selectLogo: {
    backgroundColor: '#f3f3f3',
    width: WIDTH >= 767 ? 100 : 150,
    height: WIDTH >= 767 ? 100 : 150,
    borderRadius: 10,
    alignContent: 'center',
  },
  logoText: {
    width: Platform.OS == 'web' ? '100%' : WIDTH / 2 - 15,
    marginVertical: 'auto',
    color: Colors.GRAY,
    marginLeft: WIDTH >= 767 ? 20 : 10,
  },
  formInput: {
    width: WIDTH >= 767 ? '100%' : WIDTH - 20,
    borderColor: Colors.LIGTH_GRAY,
    borderRadius: 25,
    borderWidth: 2,
    marginVertical: 5,
    //textTransform: 'uppercase',
    padding: 10,
    paddingHorizontal: 17,
    color: Colors.GRAY,
    backgroundColor: 'transparent',
  },
  subtitle: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    margin: 20,
  },
  btnSave: {
    borderRadius: 50,
    backgroundColor: Colors.PURPLE,
    width: WIDTH >= 767 ? 240 : '100%',
    paddingVertical: 20,
    marginVertical: Platform.OS === 'web' ? 30 : 20,
  },
  btnCancel: {
    borderRadius: 50,
    borderColor: Colors.PURPLE,
    backgroundColor: Colors.WHITE,
    width: WIDTH >= 767 ? 240 : '100%',
    paddingVertical: 20,
    marginVertical: 20,
    marginStart: Dimensions.get('window').width > 767 ? 10 : 0,
    borderWidth: 1,
  },
  btnText: {
    color: Colors.WHITE,
    textTransform: 'uppercase',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  selectControlArrowImageStyle: {
    marginTop: Platform.OS == 'web' ? 0 : 15,
  },
  picker: {
    height: Platform.OS === 'web' ? 50 : 'auto',
    width: '100%',
    marginVertical: 10,
    position: 'relative',
  },
  selectControlStyle: {
    borderRadius: 10,
    backgroundColor: 'transparent',
    borderColor: '#400c6d',
    padding: 10,
  },
  optionsListStyle: {
    position: Platform.OS == 'web' ? 'fixed' : 'absolute',
    borderColor: '#fff',
    marginTop: Platform.OS == 'web' ? 0 : -40,
  },
  checkboxLabel: {
    textDecorationLine: 'none',
    fontSize: WIDTH > 667 ? 16 : 12,
  },
});

const mapStateToProps = (state) => {
  return {
    locale: state.locale,
  };
};
const ProfileForm = connect(mapStateToProps, null)(ProfileFormContainer);
export default ProfileForm;
