import React, { useEffect } from 'react';
import { View, StyleSheet, Text, Platform, Dimensions, Pressable, ActivityIndicator, FlatList, ImageBackground } from 'react-native';
import AllProductsCards from '../components/AllProductsCards';
import Footer from '../components/Footer';
import * as Global from '../styles/global';
import * as Colors from "../styles/colors";
import { connect } from 'react-redux';
import * as actions from '../actions/producto';
import * as dactions from '../actions/distributors';
import * as actionsCategories from '../actions/categories';
import * as fetchData from '../constants/actionTypes.js';

import Select from '../components/Select';
import { LogBox } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

function ProductsSection(props) {
  const [productsReady, setProductsReady] = React.useState(false);
  const [categories, setCategories] = React.useState([]);
  const [products, setProducts] = React.useState({
    items: [],
    title: 'Productos',
    showProduct: 'all',
    page: 1,
    length: 0,
  });
  const [onEndReachedCalledDuringMomentum, setOnEndReachedCalledDuringMomentum] = React.useState(true)
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const [locale, setLocale] = React.useState(null);

  useEffect(() => {
    LogBox.ignoreLogs(['VirtualizedLists should never be nested']);
  }, [])

  useEffect(() => {
    //Update Locale if possible
    AsyncStorage.getItem('locale').then(async l => {
      if (l) {
        const lj = JSON.parse(l)
        const countries = await dactions.getCountries();
        //console.log('countries', countries)
        if (countries.type === 'FETCHING_DISTRIBUTOR_SUCCESS') {
          const country = countries.distributorData.items.find(country => country.id == lj.country.id);
          const payload = {
            country,
            in: lj.in,
          };
          setLocale(payload)
          await AsyncStorage.setItem('locale', JSON.stringify(payload));
          //console.log('locale', locale)
        }
      }
      else {

      }
    })

  }, [props.route.params ? props.route.params.id : 0])

  React.useEffect(() => {
    getCategories();
    const route = props.route;
    //console.log('route.params',route.params)

    if (route.params === undefined) {
      getProducts();
    } else {
      const { category, title, filter } = route.params;
      setSelectedCategory({
        value: category,
        label: title,
        slug: filter
      });
      getProductsByCategory(filter, category, title);
    }
    //return () => {}
  }, [props.route.params === undefined ? 0 : props.route.params.category]);

  async function getCategories() {
    try {
      await actionsCategories
        .getCategories({
          locale: props.locale
        })
        .then(res => {
          if (res.type === fetchData.FETCHING_CATEGORIES_SUCCESS) {
            //console.log('categories',res.categoriesData.items[0].children.pages);
            let categories = res.categoriesData.items[0].children.pages;
            categories.push({
              title: "Todos",
              slug: "all",
              id: ""
            })
            setCategories(res.categoriesData.items[0].children.pages);
          }
        });
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async function getProducts(filter, categoryId, title) {
    setProductsReady(false);

    if (filter === 'all' || filter === undefined) {
      //console.log('getAllProducts');
      getAllProducts();
    } else {
      //console.log('getProductsByCategory');
      getProductsByCategory(filter, categoryId, title);
    }
  }

  async function getAllProducts() {
    try {
      setProductsReady(false);
      await actions
        .getProducts({
          offset: 0,
          locale: props.locale

        })
        .then(res => {
          if (res.type == fetchData.FETCHING_PRODUCT_SUCCESS) {
            //props.setProducts(res.productData);
            setSelectedCategory({
              label: "Todos",
              slug: "all",
              value: ""
            })
            setProducts(prevProducts => {
              return {
                ...prevProducts,
                items: res.productData.items,
                showProduct: 'all',
                title: 'Productos',
                length: res.productData.meta.total_count,
                categoryId: null
              }
            });

            setProductsReady(true);
          }
        }).catch(err => {
          console.log(err)
        })
    } catch (err) {
      console.log(err);
    }
  }

  async function getProductsByCategory(filter, categoryId, title) {
    try {
      setProductsReady(false);
      await actions
        .getProductsByCategory({ categoryId, offset: 0, locale: props.locale })
        .then(res => {
          if (res.type == fetchData.FETCHING_PRODUCT_SUCCESS) {
            setProducts({
              categoryId: categoryId,
              items: res.productData.items,
              length: res.productData.meta.total_count,
              showProduct: filter,
              title,
              page: 1
            })
            setProductsReady(true);
          }
        }).catch(err => {
          console.log(err)
        })
    } catch (err) {
      console.log(err);
    }
  }

  function Divider() {
    return (
      <View style={{ marginTop: 20 }}></View>
    );
  };

  async function onEnd() {

    const pagesize = 20;
    const page = products.page + 1;
    const offset = (page - 1) * pagesize;
    const limit = pagesize * page;

    //console.log('onEnd ', offset);
    //console.log('products.length', products.length );
    //console.log('props.route', props.route);
    //console.log('products.categoryId', products.categoryId);

    if (products.length > offset) {
      const route = props.route;

      if (route.params === undefined && products.categoryId === null) {

        //console.log('CASO 1')

        await actions.getProducts({
          offset,
          locale: locale
        }).then(res => {
          if (res.type == fetchData.FETCHING_PRODUCT_SUCCESS) {

            setProducts({
              ...products,
              items: products.items.concat(res.productData.items),
              length: res.productData.meta.total_count,
              page
            })
          }
        }).catch(err => {
          console.log(err);
        });
      }
      else {

        //console.log('CASO 2')

        await actions
          .getProductsByCategory({ categoryId: route.params && route.params.category ? route.params.category : products.categoryId, offset, locale: locale })
          .then(res => {
            if (res.type == fetchData.FETCHING_PRODUCT_SUCCESS) {
              setProducts({
                ...products,
                items: products.items.concat(res.productData.items),
                length: res.productData.meta.total_count,
                page
              })
            }
          }).catch(err => {
            console.log(err)
          })
      }
    }
  };

  return (
    productsReady && locale ? (
      <ImageBackground source={require('../../assets/FONDO_TEXTURA_BENQ.jpg')}>
        <View style={{
          marginTop: Platform.OS === 'web' ? 50 : 80,
          maxWidth: 1200,
          width: Dimensions.get('window').width > 767
            ? "80%"
            : Dimensions.get('window').width - 20,
          marginLeft: "auto",
          marginRight: "auto",
        }}>

          <Text style={Global.default.sectionTitle}>{products.title}</Text>

          <View style={[Global.default.row, { marginTop: 10 }]}>

            <Pressable onPress={() => getAllProducts('all')}>
              <Text style={[styles.chooseProduct, Global.default.titleFont, products.showProduct === 'all' && [styles.activeProduct, Global.default.boldFont], { marginLeft: 0, paddingTop: 16 }]}>
                {locale ? locale.in['products'] : ''}
              </Text>
            </Pressable>

            <Select
              name={'cboProductType'}
              innerStyles={{
                width: '70%',
                maxWidth: 450
              }}
              onValueChange={(item) => {
                //console.log(item);
                setSelectedCategory(item);
                getProducts(item.slug, item.value, item.label);
              }}
              dataItems={categories.map((catgory) => {
                return {
                  slug: catgory.slug,
                  label: catgory.title,
                  value: catgory.id.toString()
                }
              })}
              value={selectedCategory}
            />

          </View>

          <View style={[Global.default.row, styles.productsContainer]}>
            {
              products.items.length ?
                <FlatList
                  style={{ marginTop: 20 }}
                  data={products.items}
                  initialNumToRender={6}
                  ListFooterComponent={() => {
                    return (<View style={{ width: '100%', height: 300 }}></View>)
                  }}
                  renderItem={({ item }) => <AllProductsCards product={item} {...props} locale={locale} />}
                  ItemSeparatorComponent={Divider}
                  keyExtractor={item => String(item.id)}
                  numColumns={Dimensions.get('window').width < 768 ? 1
                    : Dimensions.get('window').width >= 768 && Dimensions.get('window').width < 1100 ? 2 : 3}
                  onEndReached={() => {
                    try {
                      onEnd();
                    } catch (ex) {
                      console.log(ex);
                    }

                  }}
                  onEndThreshold={0.5}
                  contentContainerStyle={{ width: '100%', maxWidth: Dimensions.get('window').width >= 1200 ? 1200 : Dimensions.get('window').width }}
                />
                :
                <View style={{ flex: 1, justifyContent: 'center', alignContent: 'center' }}>
                  <Text style={styles.emptyProducts}>{locale.in['no_products_found']}</Text>
                </View>
            }
          </View>
        </View>
        <Footer {...props} />
      </ImageBackground>
    ) : (
      <View style={{ flex: 1, justifyContent: 'center' }}>
        <ActivityIndicator size="large" color={Colors.PURPLE} />
      </View>
    )
  );
}

const styles = StyleSheet.create({
  productsContainer: {
    //flexWrap: 'wrap',
    marginVertical: 20,
    width: '100%',
    height: Dimensions.get('window').height
  },
  chooseProduct: {
    marginHorizontal: 10,
    textTransform: 'uppercase',
    fontSize: 14
  },
  activeProduct: {
    color: Colors.PURPLE,
    fontWeight: 'bold'
  },
  emptyProducts: {
    color: Colors.PURPLE,
    fontSize: 30,
    textTransform: 'uppercase',
    marginTop: 15
  }
});


const mapStateToProps = (state) => {
  return {
    locale: state.locale
  }
}
const mapDispatchToProps = (dispatch) => {
  return {}
}
const Products = connect(mapStateToProps, mapDispatchToProps)(ProductsSection);

export default Products;