import React from "react";
import {
  Dimensions,
  Platform,
  Text,
  View,
  StyleSheet,
  FlatList,
  Pressable,
  Image
} from "react-native";

import * as Colors from "../styles/colors";
import stripTags from "../utils/stripTags";
import { BASE_URL } from "../constants/endPoints";
import SaveAndShare from "./saveandshare/SaveAndShare";

class ArticleCardProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      length: 0,
      page: 1
    };
  }

  ArticleCards = (article) => {
    return (
      <Pressable style={styles.articleContainer}
        onPress={()=>{
          this.props.navigation.navigate("Articulo", {
              id: article.id,
          });
        }}
      >
        <View style={styles.cardImageContainer}>
          <Image
            source={ { uri: BASE_URL + article.image }}
            style={styles.cardImage}
            resizeMode='cover'
          />
          <SaveAndShare item={article} options={{share:true, download:false, delete: true}} 
            handleGetItems={this.props.handleGetItems} />
        </View>
        <View>
          <Text style={styles.cardTitle}>{article.category}</Text>
          <Text style={styles.cardSubTitle}>{article.title}</Text>
          <Text numberOfLines={5} style={styles.cardDescription}>{stripTags(article.desc)}</Text>
        </View>
      </Pressable>
    )
  }

  Divider = () => {
    return ( 
      <View style={{marginTop: 10}}></View> 
    );
  };

  render() {
    return (
      <View style={{marginTop: 15}}>
        <View style={styles.cardContainer}>
          {
            this.props.articles && this.props.articles.length ? (
              <FlatList 
                style={{marginTop: 40}}
                data={this.props.articles}
                renderItem={({item}) => this.ArticleCards(item)}
                keyExtractor={item => String(item.id)}
                numColumns={Dimensions.get('window').width < 667 ? 1 : 3}
                ItemSeparatorComponent={this.Divider}
                initialNumToRender={10}
                onEndThreshold={0.5}
              />
            ) : (
              <View style={{flex: 1, justifyContent: 'center', alignContent: 'center'}}>
                <Text style={styles.emptyProducts}>No hay articulos guardados por el momento</Text>
              </View>
            )
          }
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  cardContainer: {
    maxWidth: Dimensions.get("window").width,
    alignItems: "center",
    flexGrow: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  cardDescription:{
    color: Colors.GRAY,
    fontSize: Platform.OS == "web" ? "18px" : 18,
    paddingHorizontal: Platform.OS == "web" ? "20px" : 20,
    marginTop: 10,
  },
    cardSubTitle:{
    color: Colors.BLACK,
    fontSize: Platform.OS == "web" ? "25px" : 25,
    paddingHorizontal: Platform.OS == "web" ? "20px" : 20,
    marginTop: 0,
  },
    cardTitle:{
    color: Colors.PURPLE,
    fontSize: Platform.OS == "web" ? "18px" : 18,
    paddingHorizontal: Platform.OS == "web" ? "20px" : 20,
    marginTop: 30,
  },
  cardImage:{
    width: '100%',
    height: '100%',
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25
  },
  cardImageContainer:{
    width: '100%',
    height: '50%'
  },
  articleContainer:{
    borderRadius: 15,
    backgroundColor: Colors.LIGTH_GRAY,
    width:  Dimensions.get('window').width  < 767 ?
      Dimensions.get('window').width - 20
      :
      Platform.OS == 'web' ? '420px' : Dimensions.get('window').width - 20,
    height: Platform.OS == 'web' ? '650px' : Dimensions.get('window').width * 1.5,
    marginHorizontal: Platform.OS == 'web' ? '10px' : 10,
    alignItems: 'center',

    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,


    marginBottom: 50

  },
  emptyProducts: {
    color: Colors.PURPLE,
    fontSize: Dimensions.get('window').width > 667 ? 30 : 22,
    textTransform: 'uppercase',
    marginTop: 15,
    textAlign: 'center'
  }
});

export default ArticleCardProfile;
