import React from 'react';
import {
  View,
  StyleSheet,
  ActivityIndicator,
  TextInput,
  Text,
  Pressable, Dimensions, Platform,
} from 'react-native';

import * as Colors from '../styles/colors';
import { connect }  from 'react-redux';
import * as actions from '../actions/profile';
import * as RootNavigation from '../rootNavigation';

import AsyncStorage from '@react-native-async-storage/async-storage';

class RegisterUserSection extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      username: '',
      email: '',
      password: '',
      first_name: '',
      loading: false
    }
  }

  setProfileToStorage = async (profile) => {
    const { is_user, user_id, is_active, country } = profile ;
    await AsyncStorage.setItem('profile', JSON.stringify({is_user, user_id, is_active, country}))
  }

  handleSubmit = async () => {
    const { email, password, first_name } = this.state;

    if (!email || !password || !first_name) {
      this.props.toastRef.current.show(this.props.locale.in['all_fields_are_required'], { type: 'danger', duration: 3000, placement: 'top' });
      return;
    }
    if( /\S+@\S+\.\S+/.test(email) === false) {
      this.props.toastRef.current.show(this.props.locale.in['you_have_to_put_a_valid_email'], { type: 'danger', duration: 3000, placement: 'top' });
      return;
    }

    this.setState({ loading: true });

    const data = {
      "username": email,
      "email": email.toLowerCase().trim(),
      "password": password.trim(),
      "first_name": first_name.trim(),
      "country": 1,
      "state": 3,
      "profile": "user"
    }

    await actions
      .registerUser(JSON.stringify(data))
      .then(res => {
        if (res.type === 'FETCHING_PROFILE_SUCCESS' && res.profileData.is_user) {
          RootNavigation.navigate('Login');
          this.setProfileToStorage(res.profileData);
          this.props.setProfile(res.profileData);
          this.setState({ 
            loading: false,
            email: '',
            password: '',
            first_name: '',
          });
        } else {
          this.props.toastRef.current.show(this.props.locale.in['user_cannot_be_registered'], { type: 'danger', duration: 3000, placement: 'bottom' });
          this.setState({ 
            loading: false,
            email: '',
            password: '',
            first_name: '',
          });
        }
      }).catch(err => {
        console.log(err);
      })
  }

  render() {
    return (
      <View style={{
        width:'100%',
        maxWidth: Platform.OS == 'web' ? 360 : Dimensions.get('window').width - 30,
      }}>
        <TextInput
          style={[styles.formInput, {marginTop: 20}]}
          placeholder={this.props.locale.in['name']}
          autoCapitalize={'words'}
          autoCorrect={false} 
          onChange={async (value) => {
            await this.setState({first_name: value.nativeEvent.text})
          }}
          value={this.state.first_name}
        />
        <TextInput
          style={[styles.formInput]}
          placeholder={this.props.locale.in['email']}
          autoCapitalize={'none'}
          autoCorrect={false} 
          onChange={async (value) => {
            await this.setState({email: value.nativeEvent.text})
          }}
          value={this.state.email}
          keyboardType="email-address"
        />
        <TextInput
          style={[styles.formInput]}
          placeholder={this.props.locale.in['password']}
          onChange={(value) => this.setState({password: value.nativeEvent.text})}
          value={this.state.password}
          secureTextEntry
        />
        <Pressable style={[styles.button, styles.buttonEnter]} onPress={this.handleSubmit}
          disabled={this.state.loading}
        >
          <Text style={[styles.textStyle, {color: '#fff'}]}>{this.props.locale.in['save']}</Text>
          {
            this.state.loading && (
              <ActivityIndicator size="small" color={Colors.WHITE} style={{marginStart: 10}} />
            )
          }
        </Pressable>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
    //textTransform: 'uppercase'
  },
  formInput: {
    borderColor: Colors.LIGTH_GRAY,
    borderRadius: 25,
    borderWidth: 2,
    marginVertical: 5,
    //textTransform: "uppercase",
    padding: 10,
    paddingHorizontal: 17,
    color: Colors.GRAY,
    width: '100%',
    backgroundColor: 'transparent'
  },
  button: {
    borderRadius: 20,
    padding: 10
  },
  buttonEnter: {
    backgroundColor: Colors.PURPLE,
    marginTop: 50,
    justifyContent: 'center',
    width: '100%',
    elevation: 2
  },
});

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
    locale: state.locale
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setProfile: (data) => {
      return dispatch(actions.setProfile(data))
    }
  }
}
const RegisterUser = connect(mapStateToProps, mapDispatchToProps)(RegisterUserSection);

export default RegisterUser;
