import {
  FETCHING_CATEGORIES_PENDING,
  FETCHING_CATEGORIES_SUCCESS,
  FETCHING_CATEGORIES_FAILURE,
} from '../constants/actionTypes.js';
import api from '../api/categories';

export const getCategories = async (data) => {
  try {
    const [response, json] = await api('getCategories', data);
    json.statusCode = response.status;
    return getDataSuccess(json);
  } catch (err) {
    console.log(err);
    return getDataFailure(err);
  }
};

export const setDistributor = (data) => {
  return {
    type: 'SET_CATEGORIES',
    payload: data
  }
}

//API Requests
export const getData = () => {
  return {
    type: FETCHING_CATEGORIES_PENDING,
  };
};
export const getDataSuccess = (json) => {
  return {
    type: FETCHING_CATEGORIES_SUCCESS,
    categoriesData: json
  };
};
export const getDataFailure = () => {
  return {
    type: FETCHING_CATEGORIES_FAILURE,
  };
};