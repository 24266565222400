import * as Colors from "../styles/colors";
import * as React from "react";

import {
  Dimensions,
  Image,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { connect } from 'react-redux';
import globalStyles from "../styles/global";
import { BASE_URL } from "../constants/endPoints";
import AsyncStorage from '@react-native-async-storage/async-storage';


class ProductSpecContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSpecs: 1,
      perfil: null
    };
  }

  async componentDidMount() {
    //console.log(this.profile);
    const profile = await AsyncStorage.getItem('profile');
    if (profile) {
      let p = JSON.parse(profile)
      this.setState({ perfil: p })
      //console.log(p)
    }
  }

  render() {
    const { specs, typeOfProduct, use, inventory, country_id } = this.props;

    return (
      <View style={[styles.containerSpecs]}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <TouchableOpacity
            style={styles.buttonSpecs}
            onPress={() => this.setState({ showSpecs: 0 })}
          >
            <Text
              style={[
                styles.specsTitle,
                { color: this.state.showSpecs == 0 ? Colors.PURPLE : "#000" },
              ]}
            >
              Recomendaciones de uso
            </Text>
          </TouchableOpacity>
          {Dimensions.get("window").width > 767 && <Text style={[styles.specsTitle, { marginTop: 8 }]}>|</Text>}
          <TouchableOpacity
            style={styles.buttonSpecs}
            onPress={() => this.setState({ showSpecs: 1 })}
          >
            <Text
              style={[
                styles.specsTitle,
                { color: this.state.showSpecs == 1 ? Colors.PURPLE : "#000" },
              ]}
            >
              Especificaciones
            </Text>
          </TouchableOpacity>

          {this.state.perfil && this.state.perfil.profile && this.state.perfil.profile == 'distributor' && (
            <>
              {
                (Dimensions.get("window").width > 767) && (
                  (<Text style={[styles.specsTitle, { marginTop: 8 }]}>|</Text>)
                )
              }
              <TouchableOpacity
                style={styles.buttonSpecs}
                onPress={() => this.setState({ showSpecs: 2 })}
              >
                <Text
                  style={[
                    styles.specsTitle,
                    { color: this.state.showSpecs == 2 ? Colors.PURPLE : "#000" },
                  ]}
                >
                  Inventario
                </Text>
              </TouchableOpacity>
            </>
          )
          }

        </View>



        {this.state.showSpecs == 0 && (
          <View
            style={[
              globalStyles.row,
              { justifyContent: "center", marginTop: 40, flexWrap: 'wrap' },
            ]}
          >
            {
              use.length ? (
                use.map(use => {
                  return (
                    <View key={String(use.id)} style={globalStyles.column}>
                      <Image source={{ uri: BASE_URL + use.image }} resizeMode={'center'}
                        style={styles.recommendImage} />
                      <Text style={styles.recommendTitle}>{use.name}</Text>
                    </View>
                  );
                })
              ) : (
                <View style={{ flex: 1, justifyContent: 'center', alignContent: 'center' }}>
                  <Text style={globalStyles.emptyText}>No hay recomendaciones para mostrar</Text>
                </View>
              )
            }
          </View>
        )}

        {this.state.showSpecs == 1 && (
          <View>
            <View style={[globalStyles.row, { flex: 1, marginBottom: 20 }]}>
              <Text style={styles.productType}>{typeOfProduct}</Text>
            </View>
            {
              specs.length ? (
                specs.map((spec, index) => {
                  const backgroundGray = {
                    backgroundColor: "#f3f3f3",
                  }
                  //console.log('spec',spec);
                  return spec.value.data.map((sp, idx) => {
                    return (
                      <View key={`spec-${index}-${idx}`} style={[globalStyles.row, styles.rowSpec, idx & 1 && backgroundGray]}>
                        <View style={[globalStyles.column, { flex: 1 }]}>
                          <Text>{sp[0]}</Text>
                        </View>
                        <View style={[globalStyles.column, { flex: 1 }]}>
                          <Text>{sp[1]}</Text>
                        </View>
                      </View>
                    )
                  })
                })
              ) : (
                <View style={{ flex: 1, justifyContent: 'center', alignContent: 'center' }}>
                  <Text style={globalStyles.emptyText}>No hay especificaciones para mostrar</Text>
                </View>
              )
            }
          </View>
        )}

        {this.state.showSpecs == 2 && (
          <View>
            <View style={[globalStyles.row, { flex: 1, marginBottom: 20 }]}>
              <Text style={styles.productType}>Inventario de mayoristas</Text>
            </View>

            {inventory.length && inventory.filter(value => value.country_id === country_id).length > 0 ? (

              inventory.map((inv, index) => {
                const backgroundGray = {
                  backgroundColor: "#f3f3f3",
                }
                return (
                  <View key={`inv-${index}`} style={[globalStyles.row, styles.rowSpec, index & 1 && backgroundGray]}>
                    <View style={[globalStyles.column, { flex: 1 }]}>
                      <Text>{inv.whosaler_name}</Text>
                    </View>
                    <View style={[globalStyles.column, { flex: 1 }]}>
                      <Text>{inv.model}</Text>
                    </View>
                    <View style={[globalStyles.column, { flex: 1 }]}>
                      <Text>{inv.quantity}</Text>
                    </View>
                  </View>
                )
              })

            ) : (
              <View style={{ flex: 1, justifyContent: 'center', alignContent: 'center' }}>
                <Text style={globalStyles.emptyText}>No hay inventario para mostrar</Text>
              </View>
            )}


          </View>
        )}

      </View>
    );
  }
}

const styles = StyleSheet.create({
  containerSpecs: {
    marginHorizontal: "auto",
    marginVertical: 20,
    padding: 40,
    backgroundColor: "#fff",
    borderRadius: 10,
    width: "100%",
  },
  specsTitle: {
    textTransform: "uppercase",
    fontSize: Dimensions.get("window").width < 767 ? 16 : 22,
    fontWeight: "bold",
  },
  buttonSpecs: {
    padding: 10,
  },
  productType: {
    fontSize: Dimensions.get("window").width < 767 ? 18 : 24,
    fontWeight: "bold",
  },
  rowSpec: {
    padding: 15,
  },
  recommendTitle: {
    fontWeight: '600',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: Dimensions.get("window").width < 667 ? 16 : 24,
  },
  recommendImage: {
    width: Platform.OS === 'web' ? '450px' : 450,
    height: Platform.OS === 'web' ? '320px' : 320,
  }
});


const ProductSpec = connect(null, null)(ProductSpecContainer);

export default ProductSpec;
