import {
  FETCHING_TRAINING_PENDING,
  FETCHING_TRAINING_SUCCESS,
  FETCHING_TRAINING_FAILURE
} from '../constants/actionTypes';

const initialState = {
  trainingIsFetching: false,
  trainingError: false,
  trainingData: {}
}

const training = (state = initialState, action) => {

  switch (action.type) {

    case 'SET_TRAINING':
      return {
        trainingData: action.payload,
          trainingIsFetching: false,
          trainingError: false
      };


      //Inicio de casos para API
    case FETCHING_TRAINING_PENDING:
      return {
        trainingData: {},
          trainingIsFetching: true,
          trainingError: false
      }
      case FETCHING_TRAINING_SUCCESS:
        return {
          trainingData: action.data,
            trainingIsFetching: false,
            trainingError: false
        }
        case FETCHING_TRAINING_FAILURE:
          return {
            trainingData: {},
              trainingError: true,
              trainingIsFetching: false
          }
          default:
            return state
  }
}

export default training;