import {Dimensions, Platform, StyleSheet} from 'react-native';
import * as Colors from '../../styles/colors';
const {width: WIDTH, height: HEIGHT} = Dimensions.get('window');

export const styles = StyleSheet.create({
  modal: {
    position: 'absolute',
    width: Platform.OS === 'web' ? '100%' : WIDTH,
    height: Platform.OS === 'web' ? '100%' : HEIGHT,
    paddingTop: Platform.OS === 'web' ? '10px' : 100,
    left: 0,
    top: 0,
    overflow: 'hidden',
    backgroundColor: '#00000060',
    zIndex: 1,
    elevation: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  modalContent: {
    width: Platform.OS === "web" ? '30%' :  
           Platform.OS === "android" ? WIDTH  : WIDTH - 20,
    // height: Platform.OS === 'web' ? 'auto' : HEIGHT - 200,
    // padding: Platform.OS === "web" ? '20px' : WIDTH - 20,
    margin: 'auto',
    backgroundColor: '#fefefe',
    border: '1px solid #888',
    minWidth: 200
   // border: '4mm ridge rgba(211, 220, 50, .6)'
  },
  contClose: {
    marginBottom: 25,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    marginEnd: 15,
  },
  close: {
    color: Colors.PURPLE,
    fontSize: Platform.OS === 'web' ? '42px' : 42,
    fontWeight: 'bold',

    '&:hover':  {
      color: '#000',
      textDecoration: 'none',
      cursor: 'pointer',
    },
    '&:focus':  {
      color: '#000',
      textDecoration: 'none',
      cursor: 'pointer',
    }
  },
  colorError: {
    color: 'red',
  },
  colorSuccess: {
    color: 'green',
  },
  contTextMessage: {
    width: Platform.OS === 'web' ? '100%' : WIDTH,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  textMessage: {
    width: Platform.OS === 'web' ? '100%' : WIDTH,
    fontSize: Platform.OS === "web" ? '20px' : 18,
    textAlign: 'center',
    textAlignVertical:  'center',
  },
  contFirstBtn: {
    width: Platform.OS === 'web' ? '100%' : WIDTH,
    display: 'flex' ,
    alignItems: 'center',
    marginVertical: 15,
    justifyContent: 'flex-end',
  },
  contBtn: {
    backgroundColor: Colors.GRAY,
    borderRadius: 25,
    marginVertical: 15,
    overflow: 'hidden',
    width: 150,
    marginTop: 25,
  },
});
