import React from 'react';
import {
  View,
  Text,
  Image,
  ScrollView,
  Platform,
  ActivityIndicator,
  Dimensions
} from 'react-native';
import { styles } from './Articulo.styles';
import globalStyles from '../../styles/global';
import Swiper from 'react-native-web-swiper';
import * as Colors from '../../styles/colors';
import { BASE_URL } from '../../constants/endPoints';
import { useEffect, useState } from 'react';
import * as actions from '../../actions/articles';
import * as F from '../../constants/actionTypes';
import youtube_parser from '../../utils/extractYoutube';
import Footer from '../../components/Footer';

import { WebView as WebViewWeb } from "react-native-web-webview";
import { WebView as WebViewMobile } from "react-native-webview";
import AutoHeightWebView from 'react-native-autoheight-webview'


const window = Dimensions.get('window');
const screen = Dimensions.get('screen');

const Articulo = ({ route, navigation }) => {
  const { id } = route.params || 0;
  const [state, setState] = useState({});
  const [articleReady, setArticleReady] = useState(false);
  const [dimensions, setDimensions] = useState({ window, screen });

  const fnGetArticle = async () => {
    await actions.getArticle({
      id
    }).then(res => {
      if (res.type === F.FETCHING_ARTICLES_SUCCESS) {
        //console.log('res.contentData =>', res.contentData);
        //console.log('res.contentData.imagenes =>', res.contentData.body.length);

        setState({
          intro: res.contentData.items ? res.contentData.items[0].intro : '',
          articles: res.contentData.items,
          length: res.contentData.meta.total_count,

          body: res.contentData.body,

          image: `${BASE_URL}${res.contentData.image.url}`,
          imageW: res.contentData.image.width,
          imageH: res.contentData.image.height,
          title: res.contentData.title,
          gallery: res.contentData.imagenes.length > 0 ? res.contentData.imagenes : null,
        });
        setArticleReady(true);
      }
    }).catch(err => {
      console.log(err);
    });
  };

  const fnFixHeightWebView = (index) => {
    if (Platform.OS !== 'web') { return }
    setTimeout(() => {
      const wv = document.getElementById(`webViewDesc-${index}`);
      if (wv) {
        const ifr = wv.getElementsByTagName('iframe');
        const ifrC = ifr[0]?.contentDocument?.getElementsByTagName('html');
        wv.style.height = `${ifrC[0]?.scrollHeight}px`;
      }
    }, 750);
  };

  const HeadingArticle = (data) => {
    const { value } = data.data;
    return (
      <View style={styles.contTitle}>
        <Text style={[globalStyles.sectionSubTitle, styles.subTitle]}>{value}</Text>
      </View>
    )
  };

  const ParagraphArticle = (data) => {
    const { value } = data.data;
    return (
      <View
        nativeID={`webViewDesc-${data.index}`}
        style={{
          width: '100%',
          // height: 'auto',
        }}
      >
        {
          Platform.OS == "web" && (
            <WebViewWeb
              androidLayerType="software"
              androidHardwareAccelerationDisabled
              ref={() => { }}
              javaScriptEnabled={true}
              domStorageEnabled={true}
              source={{
                html: `  
                <link rel="preconnect" href="https://fonts.googleapis.com">
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                <link href="https://fonts.googleapis.com/css2?family=Lato&display=swap" rel="stylesheet">
                <style>
                  * { font-family: 'Lato', sans-serif; }
                  p { line-height: 24px; }
                </style>
                ${value}
              `}}
            />
          )
          || (
            <AutoHeightWebView
              androidLayerType="hardware"
              style={{
                width: Dimensions.get('window').width - 15,
                // height: webHeight.id[data.index],
                marginTop: 35,
              }}
              customStyle={`
                body {
                  display: inline-block;
                }
                * { font-family: 'Lato', sans-serif; }
                p { line-height: 24px; }
              `}
              customScript={`
                // document.body.style.background = 'lightyellow';
                // const test = document.getElementById('divContent-${data.index}');
                // test.style.color = 'red';
                // test.style.height = 1800;
              `}
              onSizeUpdated={async size => {
                // console.log('data.index =>', data.index);
                // console.log('size.height =>', size.height);
              }}
              files={[{
                href: 'cssfileaddress',
                type: 'text/css',
                rel: 'stylesheet'
              }]}
              source={{
                html: `
                <link rel="preconnect" href="https://fonts.googleapis.com">
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                <link href="https://fonts.googleapis.com/css2?family=Lato&display=swap" rel="stylesheet">
                <div
                  id="divContent-${data.index}"
                  style="height: auto; min-height: 100px;"
                >
                  ${value}
                </div>
              `}}
              scalesPageToFit={false}
              viewportContent={'width=device-width, user-scalable=no'}
            />
          )
        }
      </View>
    )
  };

  const ImageArticle = (data) => {
    const { value } = data.data;
    const urlImage = `${BASE_URL}${value}`
    return (
      <View style={styles.contMainImage}>
        <Image
          resizeMode="contain"
          source={{ uri: urlImage }}
          style={{
            width: Platform.OS === "web" && dimensions.window.width > 767
              ? `${state.imageW}px`
              : Platform.OS === "web" && dimensions.window.width <= 767
                ? '100%'
                : state.imageW / 2,
            height: Platform.OS === "web" ? `${state.imageH}px` : state.imageH,
          }}
        />
      </View>
    )
  };

  const EmbedArticle = (data) => {
    const { value } = data.data;
    return (
      <View style={styles.webViewContainer}>
        {
          Platform.OS == "web" && (
            <WebViewWeb
              androidLayerType="software"
              style={{ width: "100%" }}
              javaScriptEnabled={true}
              domStorageEnabled={true}
              source={{ uri: `https://www.youtube.com/embed/${youtube_parser(value)}` }}
            />
          )
          || (
            <WebViewMobile
              androidLayerType="software"
              style={{
                width: Dimensions.get('window').width,
                height: Dimensions.get('window').height,
              }}
              javaScriptEnabled={true}
              domStorageEnabled={true}
              source={{ uri: `https://www.youtube.com/embed/${youtube_parser(value)}` }}
              injectedJavaScript={`const meta = document.createElement('meta'); meta.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0'); meta.setAttribute('name', 'viewport'); document.getElementsByTagName('head')[0].appendChild(meta); `}
              scalesPageToFit={false}
            />
          )
        }
      </View>
    )
  };

  const GalleryArticle = () => {
    return (
      <View style={{
        width: '100%',
        height: Platform.OS === 'web' ? '650px' : 500,
        marginTop: 25,
      }}>
        <View style={styles.contGallery}>
          <Swiper
            style={{
              width: '100%',
              height: '100%',
              flex: 1,
              backgroundColor: 'yellow',
            }}
            controlsProps={{
              prevPos: false,
              nextPos: false,
              dotsTouchable: true,
              DotComponent: ({ index, activeIndex, isActive, onPress }) =>
                <View>
                  <View style={{
                    width: 60,
                    height: 2,
                    backgroundColor: !isActive ? Colors.GRAY : Colors.PURPLE,
                    marginHorizontal: 7,
                    borderRadius: 3,
                  }} />
                </View>,
            }}
          >
            {
              state.gallery.map((item, index) => {
                return (
                  <View key={`slide${index}`} style={[styles.slideContainer, styles.slide]}>
                    <Image
                      resizeMode="contain"
                      source={{ uri: BASE_URL + item.image.url }}
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                    />
                  </View>
                );
              })
            }
          </Swiper>
        </View>
      </View>
    )
  };

  useEffect(() => {
    if (id) {
      fnGetArticle();
    }
  }, [route]);

  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ window, screen }) => {
      setDimensions({ window, screen });
    });
    subscription?.remove();
  });

  return (
    <ScrollView>
      <View style={[
        globalStyles.sectionContainer,
        {
          width: Platform.OS === 'web'
            ? dimensions.window.width <= 767 ? '98%' : '80%'
            : dimensions.window.width - 20,
        }
      ]}>

        {articleReady ? (
          <View style={[
            globalStyles.sectionContainer,
            styles.contMainSection,
            {
              width: Platform.OS === 'web'
                ? dimensions.window.width <= 767 ? '98%' : '100%'
                : dimensions.window.width - 20,
            }
          ]}>
            <View>
              <Text style={globalStyles.sectionTitle}>
                {state.title}
              </Text>
            </View>
            {state.body.map((item, index) => {
              return (
                <View
                  key={index}
                  style={
                    Platform.OS == 'web' ? { height: 'auto' } : { flex: 1 }
                  }
                >
                  {item.type === 'heading' ? <HeadingArticle data={item} /> : null}
                  {item.type === 'image' ? <ImageArticle data={item} /> : null}
                  {item.type === 'embed' ? <EmbedArticle data={item} /> : null}
                  {item.type === 'paragraph' ? (
                    <>
                      <ParagraphArticle index={index} data={item} />
                      {fnFixHeightWebView(index)}
                    </>
                  ) : null}
                </View>
              )
            })}
            {state.gallery ? <GalleryArticle /> : null}
          </View>
        ) : (
          <View style={{ flex: 1, justifyContent: 'center' }}>
            <ActivityIndicator size="large" color={Colors.PURPLE} />
          </View>
        )}
      </View>
      <Footer {...{ navigation }} />
    </ScrollView>
  );
};

export default Articulo;
