import {
    Dimensions,
    Platform,
    StyleSheet,
} from 'react-native';
import * as Colors from './colors';
import * as Font from 'expo-font';

let customFonts = {
    'Lato_400Regular': require('../../node_modules/@expo-google-fonts/lato/Lato_400Regular.ttf'),
    'Lato_700Bold': require('../../node_modules/@expo-google-fonts/lato/Lato_700Bold.ttf'),
};
Font.loadAsync(customFonts).then(() => {}).catch(() => {});

const windowDimension = Dimensions.get("window");

const globalStyles = StyleSheet.create({
    boldFont: {
        fontFamily: 'Lato_700Bold'
    },
    breadcrumbs: {
        flexDirection: 'row',
    },
    breadCrumbitem: {
        color: Colors.PURPLE,
        fontSize: 14,
        fontWeight: 'bold'
    },
    column: {
        flexDirection: 'column'
    },
    headerFont: {
        fontFamily: 'Lato_400Regular'
    },
    row: {
        flexDirection: 'row'
    },
    sectionContainer: {
        flex: 1,
        marginTop: Platform.OS === 'web' ? 50 : 80,
        maxWidth: 1200,
        width: windowDimension.width > 767
          ? "80%"
          : windowDimension.width - 20,
        marginLeft: "auto",
        marginRight: "auto",
    },
    sectionHeader: {
        fontSize: Platform.OS === 'web' ? 55 : 48,
        fontWeight: '600',
        marginBottom: 10,
        fontFamily: 'Lato_400Regular'
    },
    sectionSubHeader: {
        color: Colors.GRAY,
        fontSize: 18,
        fontWeight: "400",
        textAlign: 'center',
        margin: 6,
    },
    sectionTitle: {
        width: '100%',
        fontSize:   Platform.OS == 'web' ? 38 : 34,
        fontWeight: "bold",
        marginVertical: 6,
        textTransform: "uppercase",
        fontFamily: 'Lato_400Regular'
    },
    sectionSubTitle: {
        color: Colors.GRAY,
        fontSize: 18,
        fontWeight: "500",
        lineHeight: 18,
        marginBottom: 30,
        marginTop: 15,
        textShadowRadius: 1,
        margin: 6,
    },
    sectionSubtitleBlack: {
        color: Colors.BLACK,
        fontSize: 25,
        lineHeight: 25,
        marginTop: 15,
        textShadowRadius: 1,
        margin: 6,
        textTransform: 'uppercase'
    },
    textCenter: {
        textAlign: 'center'
    },
    textBold: {
        fontWeight: 'bold'
    },
    titleFont: {
        fontFamily: 'Lato_400Regular'
    },
    emptyText: {
        color: Colors.PURPLE,
        fontSize: 30,
        textTransform: 'uppercase',
        marginTop: 15,
        textAlign: 'center'
    }
});

export default globalStyles;
