import React from 'react';
import { Pressable, Text, StyleSheet, Modal, View, Image, ActivityIndicator } from 'react-native';
import { HUBSPOT_NT_URL, HUBSPOT_BQ_URL } from '../../constants/endPoints';
import * as Colors from "../../styles/colors";


import WebView from 'react-native-webview';


function Chat (props) {
  const [chatVisible, setChatVisible] = React.useState(false);

  const INJECTED_JAVASCRIPT = `(function() {
    var script = document.createElement('script');
    var meta   = document.createElement('meta');

    meta.name = 'viewport';
    meta.content = 'initial-scale=1.0, maximum-scale=1.0';
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://${HUBSPOT_BQ_URL}';
    var s = document.getElementsByTagName('head')[0];
    s.parentNode.insertBefore(meta, s);
    s.parentNode.insertBefore(script, s);
    })();
    `;

  return (
    <>
      { !chatVisible &&
        <Pressable style={styles.buttonChat} onPress={() => setChatVisible(true)}>
          <Image source={require('../../../assets/components/chat/ICONO_CHAT_WEB_BENQ.png')}
            style={styles.chatIcon} resizeMode={'contain'} />
        </Pressable>
      }
      <Modal 
        animationType="fade"
        transparent
        visible={chatVisible}
      >
        <View style={styles.centeredView}>
          <View style={[styles.modalView]}>

            <WebView 
              originWhitelist={['*']}
              onMessage={(event) => {
                console.log(event.nativeEvent);
                if(event.nativeEvent.data=='close-chat') {
                  setChatVisible(false);
                }
              }}
              javaScriptEnabled={true}
              domStorageEnabled={true}
              source={{ uri: 'https://admin.benq-latam.com/api/v2/user/chat-frame/' }}
              injectedJavaScript={INJECTED_JAVASCRIPT}
              style={{height: '100%', width: '100%'}}
              containerStyle={{height: '100%', width: '100%'}}
              renderLoading={() => <ActivityIndicator size={'large'} color={Colors.PURPLE} style={{height: '100%'}} />}
              startInLoadingState
            />

            
            <Pressable style={[styles.buttonChat, styles.chatBottom]} onPress={() => setChatVisible(false)}>
              <Image source={require('../../../assets/components/chat/ICONO_CHAT_WEB_BENQ.png')}
                style={styles.chatIcon} resizeMode={'contain'} />
            </Pressable>
          </View>
        </View>
      </Modal>
    </>
  );
}

const styles = StyleSheet.create({
  buttonChat: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 70,
    position: 'absolute',
    bottom: 20,
    right: 15,
    height: 70,
    backgroundColor: Colors.WHITE,
    borderRadius: 100,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  modalView: {
    margin: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    opacity: 1,
    maxWidth: '100%',
    width: '90%',
    height: '80%'
  },
  buttonClose: {
    backgroundColor: "#ecebef",
    paddingVertical: 10,
    paddingHorizontal: 18,
    position: "absolute",
    top: -50,
    right: 0,
    alignSelf: 'flex-end',
    borderRadius: 50
  },
  chatIcon: {
    width: 40,
    height: 40
  },
  chatBottom: {
    bottom: -64,
    right: -6
  }
});

export default Chat;