import * as React from 'react';
import { 
    ScrollView ,
    View, 
    Text,
    ActivityIndicator,
    Platform
 } from 'react-native';

import PodcastCards from '../components/PodcastCards';
import VideoCards from '../components/VideoCards';
import ArticleCards from '../components/ArticleCards';
import AssistantBanner from '../components/AssistantBanner';
import ProductCards from '../components/ProductCards';
import FullSlider from '../components/FullSlider';
import Footer from "../components/Footer";

import { connect }  from 'react-redux';
import * as actions from '../actions/home' 
import * as F  from '../constants/actionTypes.js';
import * as Colors from '../styles/colors';

import { LogBox } from 'react-native';

class HomeContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sliderItems: [],
            productItems: [],
            articleItems: [],
            podcastItems: [],
            videoItems: [],
            homeReady: false,
            menuStyle: 1
        }
    }
    async componentDidMount() {      
      LogBox.ignoreLogs(['VirtualizedLists should never be nested']);
      await  actions
            .getDirectHome({locale:this.props.locale})
            .then(res => {
                if(res.type == F.FETCHING_HOME_SUCCESS){
                    this.props.setHome(res.homeData);
                    
                    this.setState({
                        sliderItems:  res.homeData.items.imagenes ,
                        productItems: res.homeData.categories ,
                        articleItems: res.homeData.articles ,
                        podcastItems: res.homeData.podcasts ,
                        videoItems:   res.homeData.videos ,
                        homeReady: true,
                        menu: this.props.menu
                    })
                }
            }).catch(err => {
                console.log(err)
            })
        
    }
    render () {
        if(this.state.homeReady){
            return (
                <ScrollView 
                    scrollEventThrottle={50}
                    onScroll={(event) => {
                        //console.log(event.nativeEvent.contentOffset.y);
                        if(event.nativeEvent.contentOffset.y > 550 && this.state.menuStyle == 1){
                            this.setState({
                                menuStyle: 2
                            });
                            // console.log('Change menuStyle 2');
                            if(Platform.OS == 'web' && document.getElementById('MainHeader')){
                                document.getElementById('MainHeader').style.backgroundColor = 'rgb(115, 70, 150)';
                            }
                        }else if( event.nativeEvent.contentOffset.y < 550 && this.state.menuStyle == 2){
                            this.setState({
                                menuStyle: 1
                            });
                            // console.log('Change menuStyle 1');
                            if(Platform.OS == 'web' && document.getElementById('MainHeader')){
                                document.getElementById('MainHeader').style.backgroundColor = Colors.TRANSPARENT;
                            }
                        }   
                    }}
                >
                    <FullSlider />
                    <ProductCards {...this.props} />
                    <AssistantBanner {...this.props} />
                    <VideoCards   {...this.props}/>
                    <ArticleCards {...this.props} />
                    <PodcastCards {...this.props}/>
                    <Footer {...this.props} />
                </ScrollView>
                
            );
        }
        else{
            return (
                <View style={{flex: 1, justifyContent: 'center'}}>
                    <ActivityIndicator size="large" color={Colors.PURPLE} />
                </View>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        home: state.home,
        locale: state.locale,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setHome: (data) => {
            return dispatch(actions.setHome(data))
        }
    }
}

const Home = connect(mapStateToProps,mapDispatchToProps)(HomeContainer);
export default Home;
