import {
  Button,
  Text,
  View,
  Dimensions,
  Image,
  TouchableOpacity,
  Linking,
  Modal,
  StyleSheet,
  Platform
} from "react-native";
import React, { } from "react";

import { Entypo } from '@expo/vector-icons';
import * as Colors from '../styles/colors';
import MenuItems from '../constants/menuItems';
import { connect } from 'react-redux';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");
class FooterContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: props.menu,
      modalVisible: false,
      dimensions: {
        window,
        screen
      }
    };
  }
  onChange = ({ window, screen }) => {
    this.setState({ dimensions: { window, screen } });
  }
  componentDidMount() {
    if (this.props.profile && this.props.profile.profileData) {
      setTimeout(() => {
        if (!this.props.profile.profileData.user_id && this.props.route && this.props.route.name == 'Home') {
          this.setState({ modalVisible: true });
        }
      }, 30000);
    }
    this.dimensionsSubscription = Dimensions.addEventListener("change", this.onChange);
  }
  componentWillUnmount() {
    this.dimensionsSubscription.remove();
  }
  getFooterMenuItems(menuItems) {
    return menuItems.filter(item => item.section.includes('footer'));
  }

  render() {
    return (
      <View style={{ marginTop: 50 }}>
        <View style={styles.centeredView}>
          <Modal
            animationType="slide"
            transparent={true}
            visible={this.state.modalVisible}
          >
            <View style={styles.centeredView}>
              <View style={[styles.modalView, {
                backgroundColor: '#fff',
                width: 300,
                height: 180,
                borderRadius: this.state.dimensions.window.width > 667 ? 20 : 0,
              }]}>
                <TouchableOpacity style={[styles.button, styles.buttonClose]}
                  onPress={() => { this.setState({ modalVisible: false }); }}
                >
                  <Text style={[styles.textStyle, { color: '#000', fontSize: 27, fontWeight: '400' }]}>X</Text>
                </TouchableOpacity>
                <Text style={[styles.modalText, {
                  fontSize: this.state.dimensions.window.width > 667 ? 16 : 14
                }]}>
                  {this.props.locale.in['no_login_modal']}
                </Text>
                <View style={{ flexDirection: 'row', marginTop: 15 }}>
                  <TouchableOpacity style={styles.pressButton} onPress={() => { this.setState({ modalVisible: false }); this.props.navigation.navigate('Registro') }} >
                    <Text style={[styles.modalText, {
                      fontSize: this.state.dimensions.window.width > 667 ? 16 : 14
                    }]}>{this.props.locale.in['sign_up']}</Text>
                  </TouchableOpacity>
                  <TouchableOpacity style={styles.pressButton} onPress={() => { this.setState({ modalVisible: false }); this.props.navigation.navigate('Login') }}>
                    <Text style={[styles.modalText, {
                      fontSize: this.state.dimensions.window.width > 667 ? 16 : 14
                    }]}>{this.props.locale.in['sign_in']}</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
        </View>
        <View
          style={{
            flexDirection: this.state.dimensions.window.width >= 768 ? "row" : "column",
            alignItems: "center",
            backgroundColor: "rgba(225,225,225,0.5)",
            width: "100%",
            marginTop: 50,
            paddingTop: 50,
            paddingBottom: 50,
          }}
        >
          <View style={{
            flexDirection: "row",
            width: this.state.dimensions.window.width >= 768 ? "45%" : "100%",
            borderRightWidth: 1,
            borderRightColor: "#000",
            borderRightStyle: "solid",
            paddingHorizontal: 50,
            minHeight: 150,
            display: this.state.dimensions.window.width >= 768 ? "flex" : "none",
          }}>
            <Image
              reziseMode="cover"
              source={require('../../assets/components/Footer/LOGO_FOOTER_BENQ_COLOR.png')}
              style={{
                width: 150,
                height: 45,
              }}
            />
            <Text
              style={{
                marginLeft: 50,
                color: Colors.GRAY,
              }}
            >
              {this.props.locale.in['footer_paragraph']}
            </Text>
          </View>
          <View style={{
            flex: 1,
            marginHorizontal: this.state.dimensions.window.width >= 768 ? 50 : 10,
            alignItems: "center",
            flexDirection: this.state.dimensions.window.width >= 768 ? 'column' : 'row',
            justifyContent: 'center',
            flexWrap: 'wrap',
            height: this.state.dimensions.window.width >= 768 ? 'auto' : 90,
          }}>
            {(
              this.getFooterMenuItems(MenuItems).map((item, index) => {
                return (
                  <TouchableOpacity
                    style={{
                      textTransform: 'uppercase',
                      marginBottom: this.state.dimensions.window.width >= 768 ? 3 : 10,
                      marginHorizontal: this.state.dimensions.window.width >= 768 ? 0 : 5,
                    }} key={index}
                    onPress={() => {
                      item.component === 'Asistente' ?
                        this.props.navigation.dispatch(
                          this.props.navigation.reset({
                            index: 0,
                            routes: [{ name: 'Asistente' }],
                          })
                        ) :
                        this.props.navigation.dispatch(
                          this.props.navigation.navigate(item.component)
                        )
                    }}
                  >
                    <Text style={{
                      fontSize: this.state.dimensions.window.width >= 768 ? 12 : 16,
                      color: Colors.GRAY,
                    }}>{this.props.locale.in[item.inKey]}</Text>
                  </TouchableOpacity>
                )
              })
            )}
          </View>
          <View style={{
            flex: 1,
            flexDirection: 'column',
            flexWrap: 'wrap',
            marginRight: this.state.dimensions.window.width >= 768 ? 50 : 0,
            alignItems: 'flex-start',
            justifyContent: this.state.dimensions.window.width >= 768 ? 'flex-end' : 'space-between',
          }}>
            <Text style={{ color: Colors.GRAY, display: this.state.dimensions.window.width >= 768 ? 'flex' : 'none' }} >{this.props.locale.in['follow_us']}</Text>
            <View
              style={{
                marginTop: this.state.dimensions.window.width >= 768 ? 10 : 90,
                flexDirection: "row",
                justifyContent: "flex-start",
              }}>
              <Entypo onPress={() => { Linking.openURL('https://www.facebook.com/BenQMx/') }} name="facebook-with-circle" size={this.state.dimensions.window.width >= 768 ? 50 : 30} color={Colors.GRAY} style={{ marginRight: 10 }} />
              <Entypo onPress={() => { Linking.openURL('https://www.youtube.com/channel/UCyD_P00Ku_v9eiB9k4fgbuw') }} name="youtube-with-circle" size={this.state.dimensions.window.width >= 768 ? 50 : 30} color={Colors.GRAY} style={{ marginRight: 10 }} />
              <Entypo onPress={() => { Linking.openURL('https://twitter.com/benq_mexico') }} name="twitter-with-circle" size={this.state.dimensions.window.width >= 768 ? 50 : 30} color={Colors.GRAY} style={{ marginRight: 10 }} />
              <Entypo onPress={() => { Linking.openURL('https://www.linkedin.com/company/benq-m%C3%A9xico/') }} name="linkedin-with-circle" size={this.state.dimensions.window.width >= 768 ? 50 : 30} color={Colors.GRAY} style={{ marginRight: 10 }} />
            </View>
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
    locale: state.locale,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {}
}
const Footer = connect(mapStateToProps, mapDispatchToProps)(FooterContainer);
export default Footer;


const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    opacity: 1,
    width: 440,
    maxWidth: '100%',
  },
  modalText: {
    fontFamily: "Lato_400Regular",
  },
  buttonClose: {
    backgroundColor: "#ecebef",
    paddingVertical: 10,
    paddingHorizontal: 18,
    position: "absolute",
    top: -20,
    right: -20,
    alignSelf: 'flex-end',
    borderRadius: 50
  },
  pressButton: {
    backgroundColor: Colors.WHITE,
    borderRadius: 10,
    borderColor: Colors.PURPLE,
    borderWidth: 2,
    marginVertical: 5,
    marginHorizontal: 5,
    paddingVertical: 5,
    paddingHorizontal: 10,
  }
});