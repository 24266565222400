import {
    FETCHING_HOME_PENDING,
    FETCHING_HOME_SUCCESS,
    FETCHING_HOME_FAILURE,
  } from '../constants/actionTypes.js';

import api from '../api/home';

export const getDirectHome = (data) => {
    return api('getHome',data)
        .then(([response, json]) => {
            json.statusCode = response.status;
            return getDataSuccess(json);
        })
        .catch((err) => {
            console.log(err)
            return getDataFailure(err)
        });
};

export const setHome = (data) => {
    return {
        type: 'SET_HOME',
        payload: data
    }
}

//API Requests
export const getData = () => {
    return {
      type: FETCHING_HOME_PENDING,
    };
};
export const getDataSuccess = (json) => {
    return {
        type: FETCHING_HOME_SUCCESS,
        homeData: json
    };
};
export const getDataFailure = () => {
    return {
        type: FETCHING_HOME_FAILURE,
    };
}; 