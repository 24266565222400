import {
  API_URL,
  AUTH_TOKEN,
  ENV
} from '../constants/endPoints';

export default async (method, request) => {

  let appHeaders = new Headers();
  appHeaders.append('Content-Type', 'application/json');
  appHeaders.append('Authorization', AUTH_TOKEN);

  if (ENV === 'dev') {
    console.log(method, request);
  }

  if (method === 'getPromotion') {
    try {
      const response = await fetch(`${API_URL}pages/?type=promotions.PromotionsPage&order=-id&fields=*&locale=${request.locale.country.language}`, {
        method: 'GET',
        headers: appHeaders,
        cache: 'default',
      });
      return await Promise.all([response, response.json()]);
    } catch (error) {
      console.log(error);
    }
  }

};