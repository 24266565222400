import * as React from "react";
import { BASE_URL } from "../constants/endPoints";

import {
  Dimensions,
  Image,
  Linking,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
  Text
} from 'react-native';
import * as Colors from "../styles/colors";
import stripTags from "../utils/stripTags";
import globalStyles from '../styles/global';


import { WebView as WebViewWeb }  from "react-native-web-webview";
import AutoHeightWebView from 'react-native-autoheight-webview'



const fnFixHeightWebView = (index) => {
  if (Platform.OS !== 'web'){return}
  setTimeout(() => {
    const wv = document.getElementById(`webViewDesc-${index}`);
    const ifr = wv.getElementsByTagName('iframe');
    const ifrC = ifr[0]?.contentDocument?.getElementsByTagName('html');
    // console.log('fnFixHeightWebView => ifrC =>', ifrC);
    // console.log('fnFixHeightWebView => ifrC[0]?.scrollHeight =>', ifrC[0]?.scrollHeight);
    wv.style.height = `${ifrC[0]?.scrollHeight}px`;
  }, 500);
};

const HeadingArticle = (data) => {
  const {value} = data.data;
  return (
    <View style={styles.contTitle}>
      <Text style={[globalStyles.sectionSubTitle, styles.subTitle,{marginHorizontal :0}]}>{value}</Text>
    </View>
  )
};

const ParagraphArticle = (data) => {
  const {value} = data.data;
  return (
    <View
      nativeID={`webViewDesc-${data.index}`}
      style={{
        width: '100%',
        height: 'auto',
      }}
    >
      {
        Platform.OS == "web" && (
          <WebViewWeb
            androidHardwareAccelerationDisabled
            ref={() => {}}
            javaScriptEnabled={true}
            domStorageEnabled={true}
            source={{html: `  
              <link rel="preconnect" href="https://fonts.googleapis.com">
              <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
              <link href="https://fonts.googleapis.com/css2?family=Lato&display=swap" rel="stylesheet">
              <style>
                * { font-family: 'Lato'; }
                p { font-size: 19px; line-height: 21px; color: rgb(123, 123, 139); text-align:center;}
              </style>
              ${value}
            `}}
          />
        )
        || (
          <AutoHeightWebView
            androidHardwareAccelerationDisabled
            style={{
              width: Dimensions.get('window').width - 15,
              marginTop: 35,
            }}
            customStyle={`
              body {
                display: inline-block;
              }
              * { font-family: 'Lato'; textAlign: justify;}
              p { max-width: 80%; font-size: 19px; line-height:21px; width:80%; margin:0; pading:0; text-align:center; }
            `}
            customScript={`
              // document.body.style.background = 'lightyellow';
              // const test = document.getElementById('divContent-${data.index}');
              // test.style.color = 'red';
              // test.style.height = 1800;
            `}
            onSizeUpdated={async size => {
            }}
            files={[{
              href: 'cssfileaddress',
              type: 'text/css',
              rel: 'stylesheet'
            }]}
            source={{ html: `
              <link rel="preconnect" href="https://fonts.googleapis.com">
              <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
              <link href="https://fonts.googleapis.com/css2?family=Lato&display=swap" rel="stylesheet">
              <div
                id="divContent-${data.index}"
                style="height: auto; min-height: 100px;"
              >
                ${value}
              </div>
            `}}
            scalesPageToFit={false}
            viewportContent={'width=device-width, user-scalable=no'}
          />
        )
      }
    </View>
  )
};

const EmbedArticle = (data) => {
  const {value} = data.data;
  return (
    <View style={styles.webViewContainer}>
      {
        Platform.OS == "web" && (
          <WebViewWeb
          androidLayerType="software"
            style={{width: "100%" }}
            javaScriptEnabled={true}
            domStorageEnabled={true}
            source={{ uri: `https://www.youtube.com/embed/${youtube_parser(value)}`}}
          />
        )
        || (
          <WebViewMobile
          androidLayerType="software"
            style={{
              width: Dimensions.get('window').width,
              height: Dimensions.get('window').height,
            }}
            javaScriptEnabled={true}
            domStorageEnabled={true}
            source={{ uri: `https://www.youtube.com/embed/${youtube_parser(value)}`}}
            injectedJavaScript={`const meta = document.createElement('meta'); meta.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0'); meta.setAttribute('name', 'viewport'); document.getElementsByTagName('head')[0].appendChild(meta); `}
            scalesPageToFit={false}
          />
        )
      }
    </View>
  )
};

const GalleryArticle = () => {
  return (
    <View style={{
      width: '100%',
      height: Platform.OS === 'web' ? '650px' : 500,
      marginTop: 25,
    }}>
      <View style={styles.contGallery}>
        <Swiper
          style={{
            width: '100%',
            height: '100%',
            flex: 1,
            backgroundColor: 'yellow',
          }}
          controlsProps={{
            prevPos: false,
            nextPos: false,
            dotsTouchable: true,
            DotComponent: ({index, activeIndex, isActive, onPress}) =>
              <View>
                <View style={{
                  width: 60,
                  height: 2,
                  backgroundColor: !isActive ? Colors.GRAY : Colors.PURPLE,
                  marginHorizontal: 7,
                  borderRadius: 3,
                }}/>
              </View>,
          }}
        >
          {
            state.gallery.map((item, index) => {
              return (
                <View key={`slide${index}`} style={[styles.slideContainer, styles.slide]}>
                  <Image
                    resizeMode="contain"
                    source={{uri: BASE_URL + item.image.url}}
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                  />
                </View>
              );
            })
          }
        </Swiper>
      </View>
    </View>
  )
};


const window = Dimensions.get("window");
const screen = Dimensions.get("screen");
export class PromotionCard extends React.Component {
  constructor(props) {
    super(props);
  }

  handleClick = () => {
    const { promotion } = this.props;

    if (promotion.url) {
      Linking.canOpenURL(promotion.url).then(supported => {
        if (supported) {
          Linking.openURL(promotion.url).then(r => {});
        } else {
          console.log("Don't know how to open URI: " + promotion.url);
        }
      });
    }
  };


  render() {
    const { promotion } = this.props;

    return (
      <TouchableOpacity style={styles.containerCard} onPress={this.handleClick}>
        <View style={{flexDirection: "column", with:'100%'}}>
          <Image
            source={{ uri: BASE_URL + promotion.image.url }}
            style={[styles.imageFluid]}
            resizeMode="contain"
          />
        </View>
        <View style={{
          width:'100%'
        }}>
          <Text style={{
            textAlign:  'center',
            fontSize:    20,
            color:      Colors.PURPLE,
          }}>
            {stripTags(promotion.desc)}
          </Text>
          <View style={{
            textAlign:  'center',
            alignContent:'center',
            width:'100%',
            minWidth:'80%'
          }}>

            {promotion.body.map((item, index) => {
              return (
                <View
                  key={index}
                  style={{
                    width:'100%',
                  }}
                >
                  {item.type === 'heading' ? <HeadingArticle data={item}/> : null}
                  {item.type === 'embed' ? <EmbedArticle data={item}/> : null}
                  {item.type === 'paragraph' ? (
                    <>
                      <ParagraphArticle index={index} data={item}/>
                      {fnFixHeightWebView(index)}
                    </>
                  ) : null}
                </View>
              )
            })}

          </View>
        </View>
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  containerCard: {
    marginVertical: 20,
    padding: 20,
    backgroundColor: "#fff",
    borderRadius: 10,
    width: "100%",
    alignItems:'center'
  },
  imageFluid: {
    width:  Platform.OS === 'web' && Dimensions.get('window').width >= 768 ? 650 : 350,
    height: Platform.OS === 'web' && Dimensions.get('window').width >= 768 ? 300 : 200,  
    marginBottom: 20
  },
});
