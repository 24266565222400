import React, {} from 'react';
import {
  ScrollView,
  View,
  Text,
  Image,
  Platform,
  Dimensions,
  FlatList,
  TouchableOpacity,
  StyleSheet, ActivityIndicator,
} from 'react-native';
import { connect }  from 'react-redux';
import * as Colors from '../styles/colors';
import * as actions from '../actions/search';
import * as F  from '../constants/actionTypes.js';
import { BASE_URL } from "../constants/endPoints";
import stripTags from '../utils/stripTags';
import { WebView as WebViewWeb }  from "react-native-web-webview";
import { WebView as WebViewMobile}  from "react-native-webview";
import Footer from "../components/Footer";
import SaveAndShare from '../components/saveandshare/SaveAndShare';
import youtube_parser from '../utils/extractYoutube';

class BuscadorContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      length: 0,
      page: 1,
      itemsReady: false,
      query:'benq',
      locale:'es',
    };
  }
  componentWillUpdate(nextProps, nextState) {
    if( nextProps.route && nextProps.route.params 
        && nextProps.route.params.query
        && nextProps.route.params.query != this.state.query
      ){
        this.setState({
          query: nextProps.route.params.query,
          page: 1,
          items: [],
          length: 0,
          itemsReady: false
        });
        setTimeout(async () => {
          await this.start(nextProps.route.params.query);
        }, 100); 
    }
  }
  async start(query){
    await actions.searchItems({query:query,offset:0,locale:this.state.locale}).then(res => {
      if(res.type == F.FETCHING_SEARCHING_SUCCESS){
        //console.log(res.searchingData)
        this.setState({
          items:  res.searchingData.items,
          length: res.searchingData.total,
          itemsReady: true,
        });
      }
    }).catch(err => {
      console.log(err);
    });
  }
  async componentDidMount() {
    //console.log('componentDidMount', this.props);
    if(this.props.route && this.props.route.params && this.props.route.params.query){
      this.setState({query: this.props.route.params.query});

      setTimeout(async () => {
        await this.start(this.props.route.params.query);
      }, 100); 
    }
  }
  Header = () => {
    return (
      <View style={styles.header}>
        <Text style={{
          fontSize: Dimensions.get('window').width >= 1024 ? 60 : 42,
          textAlign: 'center', 
          marginBottom: 20,
          paddingTop: Platform.OS ==='ios' ? 80 : 50,
        }}>
          RESULTADOS DE BÚSQUEDA
        </Text>
        <Text style={{color: 'gray', fontSize: 25, textAlign: 'center'}}>
          Aquí tienes los resultados a tu búsqueda "{this.state.query}" en el sitio.
          Podrás encontrar artículos, noticias, eventos, etc.
        </Text>
      </View>
    );
  };
  Divider = () => {
    return (
      <View style={{marginTop:20}}>
      </View>
    );
  };
  ItemRender = (item) => {
    return (
      <TouchableOpacity
        style={styles.itemContainer}
        onPress={() => {
          //console.log('item =>', item);
          if( item.type == 'contentpage' ){
            this.props.navigation.navigate("Articulo", {
              id: item.id,
            });
          }
          else if( item.type == 'productpage' ){
            this.props.navigation.navigate("Detalle Producto", {
              id: item.id,
            });
          }
        }}
      >
        <View style={styles.itemImageContainer}>
          {
            item.type == 'contentpage' && (
              <Image
                source={{uri: BASE_URL+item.image}}
                style={styles.itemImage}
                resizeMode="cover"
              />
            )
          }
          {
            item.type == 'videopage' && Platform.OS == "web" && (
              <WebViewWeb
              androidLayerType="software"
                  androidHardwareAccelerationDisabled
                  style={{width: "100%" }}
                  javaScriptEnabled={true}
                  domStorageEnabled={true}
                  source={{ uri: `https://www.youtube.com/embed/${youtube_parser(item.url)}` }}
                />
            )
          }
          {
            item.type == 'videopage' && Platform.OS != "web" && (
              <WebViewMobile
                  style={{width: Dimensions.get('window').width }}
                  javaScriptEnabled={true}
                  domStorageEnabled={true}
                  source={{ uri: `https://www.youtube.com/embed/${youtube_parser(item.url)}`}}
                />
            )
          }
          {
            item.type == 'productpage' && (
              <Image
                source={{uri: BASE_URL+item.image}}
                style={styles.itemImage}
                resizeMode="cover"
              />
            )
          }

          {
            item.type !== 'productpage' && (
              <SaveAndShare item={item} options={{share: item.type !== 'productpage' ,save:item.type !== 'productpage',download:false}} />
            )
          }
        </View>
        <View>
          <Text style={styles.itemCategory}>{item.category}</Text>
          <Text style={styles.itemTitle}>{item.title}</Text>
          <Text numberOfLines={7} style={styles.itemDescription}>{stripTags(item.desc)}</Text>
        </View>
      </TouchableOpacity>
    );
  };
  onEnd = () => {
    const pagesize = 20;
    const page = this.state.page + 1;
    const offset = (page - 1) * pagesize;
    const limit = pagesize * page;

    //console.log('offset =>', offset);
    //console.log('limit =>', limit);

    if (this.state.length > offset) {
      actions.searchItems({query:this.state.query,offset,locale:this.state.locale}).then(res => {
        if(res.type == F.FETCHING_SEARCHING_SUCCESS){
          //console.log(res.searchingData)
          this.setState({
            items: this.state.items.concat(res.searchingData.items),
            length: res.searchingData.total,
            page: page,
          });
        }
      }).catch(err => {
        console.log(err);
      });
    }

  };

  render() {
    return (
      <View  style={styles.container}>
        {this.state.itemsReady ? (
            <FlatList
              style={{
                height: Dimensions.get('window').height,
                width: Dimensions.get('window').width,
              }}
              contentContainerStyle={{alignItems: 'center'}}
              ListHeaderComponent={this.Header}
              ListFooterComponent={<Footer {...this.props} />}
              ListHeaderComponentStyle={{alignItems:'center', width: Dimensions.get('window').width,marginBottom: 50}}
              ListFooterComponentStyle={{marginTop: 30, width: '100%'}}
              data={this.state.items}
              renderItem={({item}) => this.ItemRender(item)}
              ItemSeparatorComponent={this.Divider}
              keyExtractor={item => item.id}
              numColumns={Dimensions.get('window').width < 767 ? 1 : 2}
              onEndReached={this.onEnd}
              initialNumToRender={10}
              onEndThreshold={0.5}
            />
        ) : (
          <View style={{flex: 1, justifyContent: 'center'}}>
            <ActivityIndicator size="large" color={Colors.PURPLE} />
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  header:{
    flexDirection: 'column',
    minHeight: 180,
    alignItems: 'center',
    width: '100%',
    maxWidth: 880,
  },
  container: {
    marginTop: 0,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1
  },
  itemDescription:{
    color: Colors.GRAY,
    fontSize: Platform.OS == 'web' ? '18px' : 18,
    paddingHorizontal: Platform.OS == 'web' ? '20px' : 20,
    marginTop: 10,
  },
  itemTitle:{
    color: Colors.BLACK,
    fontSize: Platform.OS == 'web' ? '25px' : 25,
    paddingHorizontal: Platform.OS == 'web' ? '20px' : 20,
    marginTop: 0,
  },
  itemCategory:{
    color: Colors.PURPLE,
    fontSize: Platform.OS == 'web' ? '18px' : 18,
    paddingHorizontal: Platform.OS == 'web' ? '20px' : 20,
    marginTop: 20,
  },
  itemImage:{
    width: '100%',
    height: '100%',
  },
  itemImageContainer:{
    width: '100%',
    height: '50%',
    backgroundColor: Colors.WHITE,
  },
  itemContainer: {
    borderRadius:15,
    backgroundColor: Colors.LIGTH_GRAY,
    width: Dimensions.get('window').width < 767 ?
      Dimensions.get('window').width - 20
      :
      Platform.OS == 'web' ? '420px' : Dimensions.get('window').width - 20,
    height: Platform.OS == 'web' ? '550px' : Dimensions.get('window').width * 1.5,
    marginHorizontal: Platform.OS == 'web' ? '10px' : 10,
    alignItems: 'center',
  }
})

const mapStateToProps = (state) => {
  return {}
}
const mapDispatchToProps = (dispatch) => {
  return {}
}

const Buscador = connect(mapStateToProps,mapDispatchToProps)(BuscadorContainer);
export default Buscador;
