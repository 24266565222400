import React, { } from 'react';
import {
    View,
    Image,
    Pressable,
    Platform,
    Dimensions,
    StyleSheet,
    Linking,
    ActivityIndicator
} from 'react-native';
import Swiper from 'react-native-web-swiper';
import { connect } from 'react-redux';
import * as Colors from '../styles/colors';
import { BASE_URL } from "../constants/endPoints";

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");
class FullSliderContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            componentReady: false,
            dimensions: {
                window,
                screen
            }
        }
    }
    onChange = ({ window, screen }) => {
        this.setState({ dimensions: { window, screen } });
    }
    componentDidMount() {
        //console.log('FullSliderContainer componentDidMount',this.props.home);
        //console.log('this.props.home.homeData.items.imagenes',this.props.home.homeData.items)
        if (this.props.home && this.props.home.homeData && this.props.home.homeData.items) {
            //console.log(this.props.home.homeData.items);

            this.setState({
                items: this.props.home.homeData.items[0].imagenes,
                componentReady: true
            })
        }
        this.dimensionsSubscription = Dimensions.addEventListener("change", this.onChange);
    }
    componentWillUnmount() {
        this.dimensionsSubscription?.remove();
    }
    render() {
        if (this.state.componentReady) {
            return (
                <View style={{
                    width: '100%',
                    height: Platform.OS === 'web' ? this.state.dimensions.window.width > 1200 ? this.state.dimensions.window.height / 1.5 : '580px' : 580,
                    marginTop: Platform.OS === 'web' ? 0 : 20,
                }}>
                    <View
                        style={[styles.container, {
                            height: Platform.OS === 'web' ? this.state.dimensions.window.width > 1200 ? this.state.dimensions.window.height / 1.5 : '560px' : 560,
                        }]}
                    >
                        <Swiper
                            key={'fullSlider'}
                            loop={false}
                            minDistanceToCapture={10}
                            minDistanceForAction={0.3}
                            controlsProps={{
                                prevPos: false,
                                nextPos: false,
                                dotsTouchable: true,
                                DotComponent: ({ index, activeIndex, isActive, onPress }) =>
                                    <View>
                                        <View style={{
                                            width: 45,
                                            height: 2,
                                            backgroundColor: !isActive ? Colors.GRAY : Colors.PURPLE,
                                            marginHorizontal: 7,
                                            borderRadius: 3
                                        }} />
                                    </View>
                            }}
                        >
                            {
                                this.state.items.map((item, index) => {
                                    return (
                                        <View key={`slide${index}`} style={[styles.slideContainer, styles.full]}>
                                            <Pressable
                                                style={styles.full}
                                                onPress={() => {
                                                    if (item.url) {
                                                        Linking.openURL(`${item.url}`);
                                                    }
                                                }}
                                            >
                                                <Image
                                                    resizeMode="cover"
                                                    source={{
                                                        uri: this.state.dimensions.window.width >= 768 ?
                                                            item.image ? BASE_URL + item.image.url : '' :
                                                            item.mobile_image ? BASE_URL + item.mobile_image.url : null
                                                    }}
                                                    style={styles.full}
                                                />
                                            </Pressable>
                                        </View>
                                    )
                                })
                            }

                        </Swiper>

                    </View>
                </View>
            )
        }
        else {
            return (
                <View style={{ flex: 1, justifyContent: 'center' }}>
                    <ActivityIndicator size="large" color={Colors.PURPLE} />
                </View>
            )
        }

    }
}

const mapStateToProps = (state) => {
    return {
        home: state.home,
    }
}

const FullSlider = connect(mapStateToProps, null)(FullSliderContainer);
export default FullSlider;


const styles = StyleSheet.create({
    container: {
        flex: 1,
        position: Platform.OS === 'web' ? 'fixed' : 'absolute',
        width: '100%',
        top: 0
    },
    slideContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    full: {
        width: '100%',
        height: '100%',
    }
});