import {
  Dimensions,
  Image,
  ImageBackground,
  Platform,
  Pressable,
  ScrollView,
  Text,
  View,
  TouchableOpacity,
  StyleSheet,
  Modal,
  Alert
} from "react-native";
import { connect }  from 'react-redux';
import { LinearGradient } from "expo-linear-gradient";
import React from "react";
import * as Global from '../styles/global';
import * as Colors from '../styles/colors';
import stripTags from '../utils/stripTags';

import { WebView as WebViewWeb }  from "react-native-web-webview";
import AutoHeightWebView from 'react-native-autoheight-webview'


const gradientStart = {
  x: 0,
  y: 1
};
const gradientEnd = {
  x: 1,
  y: 0
};

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");
class PodcastCardsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        items:[],
        componentReady: false,
        modalVisible: false,
        selectedFrame:'',
        dimensions: {
          window,
          screen
      }
    }
  }
  onChange = ({ window, screen }) => {
    this.setState({ dimensions: { window, screen } });
  }
  componentDidMount(){
    //console.log('this.props.home.homeData.items.imagenes',this.props.home.homeData.items)
    if(this.props.home && this.props.home.homeData && this.props.home.homeData.items){
        this.setState({
            items: this.props.home.homeData.items[0].podcasts.pages.slice(0,3),
            intro: this.props.home.homeData.items[0].podcasts.intro,
            componentReady: true
        })
    }
    this.dimensionsSubscription = Dimensions.addEventListener("change", this.onChange);
  }
  componentWillUnmount() {
    this.dimensionsSubscription?.remove();
  }
  render() {
    return (
      <View style={styles.container}>
        <View style={styles.titleContainer}>
          <View  style={styles.titleBlock}>
              <Text style={Global.default.sectionHeader}>
                {this.props.locale.in['podcasts'].toUpperCase()}
              </Text>
              <Text style={Global.default.sectionSubHeader} >
                  {this.state.intro}
              </Text>
          </View>
        </View>

        <View>
          <Modal
          animationType="slide"
          transparent={true}
          visible={this.state.modalVisible}
          >
            <View style={styles.centeredView}>
              <View style={[styles.modalView,{
                minWidth: this.state.dimensions.window.width >= 768 ? 750 : 350,
              }]}>
                {
                  Platform.OS == "web" && (
                    <WebViewWeb
                    androidLayerType="software"
                      androidHardwareAccelerationDisabled
                      style={{width: "100%" }}
                      javaScriptEnabled={true}
                      domStorageEnabled={true}
                      source={{ html: `${this.state.selectedFrame}` }}
                    />
                  )
                  || (
                    <AutoHeightWebView
                    androidLayerType="software"
                    androidHardwareAccelerationDisabled
                      style={{
                        width: this.state.dimensions.window.width,
                        height: this.state.dimensions.window.width,
                      }}
                      source={{ html: `${this.state.selectedFrame}` }}
                      viewportContent={'width=device-width, user-scalable=no'}
                    />
                  )
                }
                <Pressable onPress={()=>{this.setState({modalVisible:!this.state.modalVisible})}}>
                  <Text>{this.props.locale.in['close']}</Text>
                </Pressable>
              </View>
            </View>
          </Modal>
          <ScrollView
            contentContainerStyle={styles.scrollViewContentContainer}
            style={styles.scrollViewContainer}
            horizontal={true}
          >
            {this.state.items.map((podcast, index) => {
              return (
                <LinearGradient
                  key={"card-" + index}
                  colors={["rgba(51,8,102,1)", "rgba(169,124,210,1)"]}
                  start={gradientStart}
                  end={gradientEnd}
                  style={[styles.card,{
                    width:
                      Platform.OS == "web"
                        ? "280px"
                        : this.state.dimensions.window.width * 0.9,
                      height:
                        Platform.OS == "web"
                          ? "380px"
                          : this.state.dimensions.window.width,
                  }]}
                >
                  <Image source={require("../../assets/components/PodcastCards/ICONO_PODCAST_WEB_BENQ.png")}
                    style={styles.cardImage}
                  ></Image>
                  <Text style={styles.cardTitle}
                    numberOfLines={1}
                  >
                    {podcast.title}
                  </Text>
                  <Text 
                    style={styles.cardDescription}
                    numberOfLines={4}
                  >
                    {stripTags(podcast.desc)}
                  </Text>
                  <Pressable
                    onPress={() => {
                      this.setState({
                        modalVisible: true,
                        selectedFrame: podcast.frame
                      })
                    }}
                    style={styles.cardButton}
                  >
                    <Text style={styles.cardButtonText}>
                      {this.props.locale.in['listen'].toUpperCase()}
                    </Text>
                  </Pressable>
                </LinearGradient>
              );
            })}
          </ScrollView>
        </View>

        <View style={styles.seeMoreContainer}>
          <View style={styles.seeMoreButtonContainer}>
            <TouchableOpacity style={styles.seeMoreButton}
                onPress={() => {
                  this.props.navigation.navigate("Podcasts");
                }}
            >
              <Text style={{ color: Colors.BLACK, fontSize: 18 }}>
                {this.props.locale.in['see_more'].toUpperCase()}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView:{
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    minHeight: 350,
  },

  seeMoreButton:{
    minWidth: 200,
    height: 50,
    backgroundColor: Colors.TRANSPARENT,
    paddingHorizontal: 20,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 50,
    borderRadius: 25,
    borderColor: Colors.PURPLE,
    borderWidth: 2,
  },
  seeMoreButtonContainer:{
    width: '100%',
    maxWidth: 880,
    alignItems: 'center',
  },
  seeMoreContainer:{
    minHeight: 180,
    alignItems: 'center',
  },
  cardButtonText:{
    color: "white",
    fontSize: Platform.OS == "web" ? "20px" : 20,
    fontWeight: "bold",
  },
  cardButton:{
      position: "absolute",
      bottom: 50,
  },
  cardDescription:{
    color: "white",
    marginTop: 10,
  },
  cardTitle:{
    color: "white",
    fontSize: Platform.OS == "web" ? "35px" : 35,
    marginTop: 20,
  },
  cardImage:{
    width: Platform.OS == "web" ? "120px" : 120,
    height: Platform.OS == "web" ? "90px" : 90,
  },
  card:{
    padding: 30,
    marginHorizontal: Platform.OS == "web" ? "5px" : 5,
    alignItems: "center",
    borderRadius: 10,
  },
  scrollViewContainer:{
    height: 400,
  },
  scrollViewContentContainer:{
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
  },
  titleBlock:{
    width: '100%',
    maxWidth: 880,
    alignItems: 'center',
  },
  titleContainer:{
    minHeight: 180,
    alignItems: 'center',
  },
  container: {
    marginTop: 50,
  }
});

const mapStateToProps = (state) => {
  return {
      home: state.home,
      locale: state.locale,
  }
}

const PodcastCards = connect(mapStateToProps,null)(PodcastCardsContainer);
export default PodcastCards;
