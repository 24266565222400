import {useRef, useState} from 'react';

export const useSelect = (value) => {
  const [focus, setFocus] = useState(false);

  const onFocus = () => {
    //console.log('onFocus');
    setFocus(true);
  };
  const onBlur = () => {
    //console.log('onBlur');
    setFocus(false);
  };

  return {
    onFocus,
    focus,
    onBlur,
    // showError,
    // error,
  };
};
