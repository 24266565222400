import * as Colors from "../../styles/colors";
import * as React from "react";
import {
  Dimensions,
  Image,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import stripTags from "../../utils/stripTags";

import * as globalStyles from "../../styles/global";
import { BASE_URL } from "../../constants/endPoints";

class RecommendedProductCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product: [],
    };
  }

  componentDidMount() {
    this.setState({ product: this.props.product });
  }

  render() {
    const { product } = this.state;

    return (
      <>
        { product.product &&
          <TouchableOpacity
            style={styles.card}
            onPress={() => this.props.navigation.navigate('Detalle Producto', {id: product.product.items.id})}
          >
            <View style={styles.cardBody}>
              <Image
                source={{ uri: BASE_URL + product.product.items.image }}
                style={[styles.imageFLuid, { margin: "auto" }]}
              />
              <Text style={styles.cardTitle}>{product.product.items.title}</Text>
              <Text style={styles.cardSubtitle}>
                {product.product.items.intro}
              </Text>
              <View style={{ marginVertical: 15 }}>
                <Text style={styles.itemDescription} numberOfLines={4}>{stripTags(product.product.items.desc)}</Text>
              </View>
              <Text style={[styles.cardText]}>
                {this.props.locale.country.currency_printable_symbol} {" "}
                {
                  (product.product.items.price * this.props.locale.country.exchange * this.props.locale.country.price_factor).toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
              </Text>
              <View
                style={{
                  marginVertical:
                    Dimensions.get("window").width < 767 ? 30 : 40,
                }}
              ></View>
              <View
                style={[globalStyles.default.row, { justifyContent: "space-between" }]}
              >
                <View style={globalStyles.default.column}>
                  <Text style={styles.moreInfo}>Más información</Text>
                </View>
                
              </View>
            </View>
            {product.product.items.outofstock && (
              <View style={styles.soldOutContainer}>
                <Text style={styles.soldOut}>Agotado</Text>
              </View>
            )}
            {product.product.items.isnew && (
              <View style={styles.soldOutContainer}>
                <Text style={styles.soldOut}>Nuevo</Text>
              </View>
            )}
          </TouchableOpacity>
        }
      </>
    );
  }
}

const styles = StyleSheet.create({
  card: {
    backgroundColor: "#fff",
    borderRadius: 10,
    marginEnd: 20,
    marginVertical: 6,
    width:
      Dimensions.get("window").width < 767
        ? 300
        : Platform.OS == "web"
        ? "360px"
        : Dimensions.get("window").width - 20
  },
  cardBody: {
    marginHorizontal: 22,
    marginVertical: 20,
    flexDirection: 'column',
    flex: 1
  },
  cardTitle: {
    fontSize: Dimensions.get("window").width < 767 ? 16 : 22,
    fontWeight: "bold",
    flex: 1
  },
  cardSubtitle: {
    fontSize: Dimensions.get("window").width < 767 ? 14 : 20,
    color: Colors.PURPLE,
    textTransform: "uppercase",
    flex: 1
  },
  cardText: {
    color: "#888",
    fontSize: 22
  },
  moreInfo: {
    color: Colors.PURPLE,
    textTransform: "uppercase",
    fontSize: Dimensions.get("window").width < 767 ? 12 : 18,
    fontWeight: 'bold'
  },
  imageFLuid: {
    width:
      Platform.OS === "web" && Dimensions.get("window").width > 667
        ? "300px"
        : "100%",
    height:
      Platform.OS === "web" && Dimensions.get("window").width > 667
        ? "320px"
        : 220,
    overflow: "visible",
  },
  soldOutContainer: {
    paddingHorizontal: 30,
    paddingVertical: 10,
    position: "absolute",
    top: 40,
    right: 0,
    backgroundColor: "#000",
  },
  soldOut: {
    color: "#fff",
    fontWeight: "bold",
    textTransform: "uppercase",
    fontSize: Dimensions.get("window").width < 767 ? 18 : 24,
  },
  itemDescription: {
    color: Colors.BLACK,
    fontSize: Platform.OS == 'web' ? '18px' : 18,
    paddingHorizontal: Platform.OS == 'web' ? '20px' : 20,
    marginTop: 10
  }
});

export default RecommendedProductCard;
