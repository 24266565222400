import React, {useState, useEffect, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {
  StyleSheet,
  TouchableOpacity,
  Text,
  View,
  Image,
  Dimensions,
  Platform,
  TextInput,
  ScrollView,
} from 'react-native';
import {Entypo} from '@expo/vector-icons';
import * as Colors from '../../styles/colors';
import {Animated} from 'react-native';

import MenuItems from '../../constants/menuItems';
import Collapsible from 'react-native-collapsible';
import {useDrawerStatus} from '@react-navigation/drawer';
import LocaleComponentNewSelect from './LocaleComponentNewSelect';

let query = '';

function Menu({navigation, route, options}) {
  const [menu, setMenu] = useState(null);
  const [categories, setCategories] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const isDrawerOpen = useDrawerStatus() === 'open';

  const state = useSelector((state) => state);
  const searchInput = useRef(null);

  useEffect(() => {
    setMenu(MenuItems);
  }, [menu]);

  useEffect(() => {
    //console.log('state', state);
    if ( state.home && state.home.homeData && state.home.homeData && state.home.homeData.items ) {
      const items = state.home.homeData.items[0].categories.pages;
      setCategories(items);
    }
  }, [state]);

  return Dimensions.get('window').width <= 1024 && isDrawerOpen ? (
    <Animated.View style={{
      backgroundColor: 'rgb(242, 242, 242)',
    }}>
      <View style={{
        flex: 1,
        marginTop: 10,
        flexDirection: 'row',
        justifyContent: 'space-around',
        minHeight: 60,
        //backgroundColor: Platform.OS === 'web' ? 'transparent' : Colors.WHITE,
        paddingTop: Platform.OS === 'web' ? 10 : 40,
      }}>
        <TouchableOpacity style={{flex: 0.3, marginLeft: 10}} onPress={() => navigation.closeDrawer()}>
          <View style={{borderRadius: 20, backgroundColor: '#fff', width: 40, height: 40, padding: 5}}>
            <Entypo name="cross" size={30} color={Colors.PURPLE}/>
          </View>
        </TouchableOpacity>
        <TouchableOpacity style={{flex: 0.7, paddingTop: 5}} onPress={() => {
          navigation.closeDrawer();
          navigation.reset({
              index: 0,
              routes: [{ name: 'Home' }],
          });
        }}>
          <Image
            style={{
              resizeMode: 'contain',
              width: Dimensions.get('window').width / 3,
              height: 30,
            }}
            source={require('../../../assets/components/Footer/LOGO_FOOTER_BENQ_COLOR.png')}
          ></Image>
        </TouchableOpacity>
      </View>


      <ScrollView style={{
        marginTop: 20,
        paddingLeft: 30,
        paddingRight: 10,
        height: Dimensions.get('window').height - 100,
        display: Platform.OS === 'web' ? 'block' : 'flex',
      }}>

        <View style={{
          marginVertical: 10,
          width: Dimensions.get('window').width > 1024 ? '100%' : '70%',
        }}>
          <TextInput
            ref={searchInput}
            style={[styles.formInput, {}]}
            placeholderTextColor={Colors.PURPLE}
            placeholder="Buscar"
            onChangeText={(text) => {
              query = text;
            }}
            onSubmitEditing={() => {
              searchInput.current.value = '';
              navigation.navigate('Buscador', {query: query});
            }}
          />
          <Entypo name="magnifying-glass" size={25} color={Colors.PURPLE} style={{
            position: 'absolute',
            right: 10,
            top: 4,
          }}/>
        </View>


        {menu && menu.map((menuItem, index) => {
          return (
            <View
              key={index}
              style={{
                flex: 1,
                flexDirection: 'column',
              }}
              onPress={() => {
              }}
            >
              {
                (
                  <TouchableOpacity onPress={() => {
                    navigation.closeDrawer();
                    menuItem.component === 'Asistente' ?
                    navigation.reset({
                      index: 0,
                      routes: [{name: 'Asistente'}],
                    }) :
                    navigation.jumpTo(menuItem.component)
                  }}>
                    <Text style={{
                      padding: 10,
                      fontSize: 18,
                      fontWeight: 'bold',
                      color: Colors.PURPLE,
                      textTransform: 'uppercase',
                    }}>{menuItem.screenName}</Text>
                  </TouchableOpacity>
                )
              }

            </View>
          );
        })
        }
        <LocaleComponentNewSelect route={route}/>
      </ScrollView>

    </Animated.View>
  ) : (<></>);
}

export default Menu;


const styles = StyleSheet.create({
  formInput: {
    borderColor: Colors.PURPLE,
    borderRadius: 25,
    borderWidth: 1,
    padding: 7,
    paddingHorizontal: 15,
    color: Colors.PURPLE,
    backgroundColor: 'transparent',
  },
});
