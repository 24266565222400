import * as React from 'react';
import {
  View,
  Modal,
  Text,
  StyleSheet,
  ScrollView,
  Dimensions,
  Platform,
  ImageBackground,
  TouchableOpacity,
  Image,
  ActivityIndicator,
  FlatList,
} from 'react-native';
import Select from '../components/Select';
import { connect } from 'react-redux';

import * as cactions from '../actions/caracteristics';
import * as xactions from '../actions/categories'
import * as pactions from '../actions/producto';

import bgAsistente from '../../assets/components/asistente/bg-asistente.png';
import globalStyles from '../styles/global';
import * as Colors from '../styles/colors';
import { BASE_URL } from '../constants/endPoints';

import Footer from '../components/Footer';
import AllProductsCards from '../components/AllProductsCards';
import { LogBox } from 'react-native';


class AsistenteContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keys: [],
      caracteristics: {},
      caracteristicsSelected: [],
      componentReady: false,
      modalVisible: false,
      total: 0,
      recommendationVisible: false,
      allproducts: [],
      products: [],
      view: 'category',
      category: '',
      history: [],
      orderValue: []
    };
  }

  async getInitialData() {
    const xcategories = await xactions.getCategories({ locale: this.props.locale })
    const xcaracteristics = await cactions.getCaracteristics()
    const xproducts = await pactions.getAllProducts()

    let categories = [];
    let caracteristics = [];
    let keys = []
    let allproducts = [];


    if (xcategories.type == 'FETCHING_CATEGORIES_SUCCESS') {
      const items = xcategories.categoriesData.items[0];
      categories = items.children.pages.map((item, index) => {
        return {
          id: item.id,
          image: item.image,
          name: item.title,
          type: 'category',
          category: item.category
        }
      })
      categories = categories.filter(o => {
        if (o.category) { return o; }
      })
    }
    if (xcaracteristics.type == 'FETCHING_CARACTERISTICS_SUCCESS') {
      const items = xcaracteristics.caracteristicsData;
      let keysAux = Object.keys(items).filter((o) => {
        if (items[o].length > 0) {
          return o;
        }
      });
      let data = items;
      data.category = categories;
      caracteristics = data;
      keys = ['category', ...keysAux]
      if (data.monitorscreensize) {
        data.monitorscreensize = data.monitorscreensize.sort((a, b) => a.name > b.name ? 1 : -1);
      }
    }
    if (xproducts.type == 'FETCHING_PRODUCT_SUCCESS') {
      allproducts = xproducts.productData.filter(item => item.country.some(e => e.id == this.props.locale.country.id))
    }
    console.log('allproducts', allproducts)
    //console.log('keys', keys)
    delete (caracteristics['statusCode'])
    //console.log('caracteristics', caracteristics)

    this.setState({
      keys,
      caracteristics,
      caracteristicsSelected: [],
      allproducts,
      total: 0,
      componentReady: true,
      recommendationVisible: false,
      products: [],
      view: '',
      category: '',
      history: [],
      orderValue: []
    });

  }
  async componentDidMount() {
    LogBox.ignoreLogs(['VirtualizedLists should never be nested']);
    //console.log('componentDidMount', this.props.locale);
    await this.getInitialData();
  }
  componentDidUpdate() {
    //console.log('currentSlide', this.state.currentSlide );
    //console.log('keys.length',  this.state.keys.length);
    //console.log('keys',  this.state.keys);
    //console.log('key',  this.state.keys[this.state.currentSlide - 1]);
    //console.log('C K',  this.state.caracteristics[this.state.keys[this.state.currentSlide -1 ]]);

  }

  Divider = () => {
    return (
      <View style={{ marginTop: 10 }}></View>
    );
  };
  processData = (item) => {
    console.log('Item', item);
    let products = [];
    let categories = []
    let nextCategory = ''
    let category = this.state.category
    let qty = 0
    let caracteristics = this.state.caracteristics
    let recommendationVisible = this.state.recommendationVisible
    let history = this.state.history

    if (item.type === 'category') {
      if (item.category === 1) {
        //Proyectores
        products = this.state.allproducts.filter(p =>
          p.proyectorplace.length ||
          p.proyectorcontent.length ||
          p.proyectorlighttype.length ||
          p.proyectorlightcontrol.length ||
          p.proyectordistance.length ||
          p.proyectortechnology.length
        )
        console.log('Products', products)
        categories = this.state.keys.filter(c => c.indexOf('proyector') === 0)
        category = 1
      }
      else {
        //Monitores
        products = this.state.allproducts.filter(p =>
          p.monitorplace.length ||
          p.monitoruse.length ||
          p.monitorscreensize.length ||
          p.monitorresolution.length ||
          p.monitorpanel.length ||
          p.monitorfrequency.length
        )
        console.log('Products', products)
        categories = this.state.keys.filter(c => c.indexOf('monitor') === 0)
        category = 2
      }

      //Seleccionamos la caracteristica a mostrar
      this.state.caracteristicsSelected.forEach(c => delete (categories[categories.indexOf(c)]))
      categories = categories.filter(n => n)

      categories.forEach(category => {
        const aux = products.filter(product => product[category].length).length
        if (aux > qty) { nextCategory = category }
        qty = aux
      })

      //console.log('categories', categories)
      //console.log('nextCategory', nextCategory)
    }
    else {
      //Filtros de productos
      products = this.state.products.filter(product => product[item.type].some(e => e.id === item.id))
      if (category === 1) { categories = this.state.keys.filter(c => c.indexOf('proyector') === 0) }
      else { categories = this.state.keys.filter(c => c.indexOf('monitor') === 0) }

      this.state.caracteristicsSelected.forEach(c => delete (categories[categories.indexOf(c)]))

      categories = categories.filter(n => n)
      categories.forEach(category => {
        const aux = products.filter(product => product[category].length).length
        if (aux > qty) { nextCategory = category }
        qty = aux
      })

      if (nextCategory !== '') {
        caracteristics[nextCategory] = caracteristics[nextCategory].filter(c => products.filter(p => p[c.type].some(s => s.id === c.id)).length)
      }
      if (products.length === 1 || nextCategory === '') { recommendationVisible = true }

      //console.log('len', products.length)
      //console.log('products', products)
      //console.log('categories', categories)
      //console.log('caracteristics', caracteristics)
      //console.log('nextCategory', nextCategory)
    }

    history.push({
      ...this.state,
      category,
      products,
      caracteristics: caracteristics,
      caracteristicsSelected: [...this.state.caracteristicsSelected, nextCategory],
      view: nextCategory,
      total: products.length,
      componentReady: true,
      recommendationVisible
    })

    this.setState({
      ...this.state,
      history,
      category,
      products,
      caracteristics,
      caracteristicsSelected: [...this.state.caracteristicsSelected, nextCategory],
      view: nextCategory,
      total: products.length,
      componentReady: true,
      recommendationVisible
    })
  }
  ItemRender = (item) => {
    return (
      <TouchableOpacity
        onPress={() => {
          this.processData(item)
        }}
        style={[styles.card, {
          backgroundColor: item.selected ? 'rgba(125,125,125,0.5)' : 'white',
        }]}
      >
        <View style={styles.cardBody}>
          <View>
            <Image
              resizeMode="contain"
              source={{ uri: `${BASE_URL}${item.image}` }}
              style={styles.imageFLuid} />
            <Text numberOfLines={2} style={styles.cardTitle}>
              {item.name}
            </Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  };
  Assistant = () => {
    return (
      <View>
        <View style={{ justifyContent: 'center' }}>
          <Image
            resizeMode="contain"
            source={require('../../assets/components/asistente/EFECTO_RECOMENDACION.png')}
            style={{
              width: '100%',
              height: Dimensions.get('window').width >= 768 ? 120 : 80,
              marginTop: 20,
            }}
          />
          <Text style={[globalStyles.textCenter, styles.titleAssistant, { marginVertical: 10, fontSize: 20 }]}>
            {this.props.locale.in[`assistant_question_${this.state.keys[this.state.currentSlide - 1]}`]}
          </Text>
        </View>
        <View style={{ paddingHorizontal: Dimensions.get('window').width >= 768 ? 5 : 0 }}>
          <View>
            {this.state.view === '' && (
              <FlatList
                ref={elem => this[`FlatList-category`] = elem}
                key={'category'}
                scrollEnabled={false}
                columnWrapperStyle={{ justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap' }}
                data={this.state.caracteristics['category']}
                renderItem={({ item }) => this.ItemRender(item)}
                ItemSeparatorComponent={this.Divider}
                keyExtractor={item => item.id}
                initialNumToRender={10}
                numColumns={
                  Dimensions.get('window').width > 768 ? 4 : 2
                }
              />
            )}

            {this.state.view == 'proyectorplace' && (
              <FlatList
                ref={elem => this[`FlatList-proyectorplace`] = elem}
                key={'proyectorplace'}
                scrollEnabled={false}
                columnWrapperStyle={{ justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap' }}
                data={this.state.caracteristics.proyectorplace}
                renderItem={({ item }) => this.ItemRender(item)}
                ItemSeparatorComponent={this.Divider}
                keyExtractor={item => item.id}
                initialNumToRender={10}
                numColumns={
                  Dimensions.get('window').width > 768 ? 4 : 2
                }
              />
            )}
            {this.state.view == 'proyectorcontent' && (
              <FlatList
                ref={elem => this[`FlatList-proyectorcontent`] = elem}
                key={'proyectorcontent'}
                scrollEnabled={false}
                columnWrapperStyle={{ justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap' }}
                data={this.state.caracteristics.proyectorcontent}
                renderItem={({ item }) => this.ItemRender(item)}
                ItemSeparatorComponent={this.Divider}
                keyExtractor={item => item.id}
                initialNumToRender={10}
                numColumns={
                  Dimensions.get('window').width > 768 ? 4 : 2
                }
              />
            )}
            {this.state.view == 'proyectortechnology' && (
              <FlatList
                ref={elem => this[`FlatList-proyectortechnology`] = elem}
                key={'proyectortechnology'}
                scrollEnabled={false}
                columnWrapperStyle={{ justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap' }}
                data={this.state.caracteristics.proyectortechnology}
                renderItem={({ item }) => this.ItemRender(item)}
                ItemSeparatorComponent={this.Divider}
                keyExtractor={item => item.id}
                initialNumToRender={10}
                numColumns={
                  Dimensions.get('window').width > 768 ? 4 : 2
                }
              />
            )}
            {this.state.view == 'proyectorlighttype' && (
              <FlatList
                ref={elem => this[`FlatList-proyectorlighttype`] = elem}
                key={'proyectorlighttype'}
                scrollEnabled={false}
                columnWrapperStyle={{ justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap' }}
                data={this.state.caracteristics.proyectorlighttype}
                renderItem={({ item }) => this.ItemRender(item)}
                ItemSeparatorComponent={this.Divider}
                keyExtractor={item => item.id}
                initialNumToRender={10}
                numColumns={
                  Dimensions.get('window').width > 768 ? 4 : 2
                }
              />
            )}
            {this.state.view == 'proyectorlightcontrol' && (
              <FlatList
                ref={elem => this[`FlatList-proyectorlightcontrol`] = elem}
                key={'proyectorlightcontrol'}
                scrollEnabled={false}
                columnWrapperStyle={{ justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap' }}
                data={this.state.caracteristics.proyectorlightcontrol}
                renderItem={({ item }) => this.ItemRender(item)}
                ItemSeparatorComponent={this.Divider}
                keyExtractor={item => item.id}
                initialNumToRender={10}
                numColumns={
                  Dimensions.get('window').width > 768 ? 4 : 2
                }
              />
            )}
            {this.state.view == 'proyectordistance' && (
              <FlatList
                ref={elem => this[`FlatList-proyectordistance`] = elem}
                key={'proyectordistance'}
                scrollEnabled={false}
                columnWrapperStyle={{ justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap' }}
                data={this.state.caracteristics.proyectordistance}
                renderItem={({ item }) => this.ItemRender(item)}
                ItemSeparatorComponent={this.Divider}
                keyExtractor={item => item.id}
                initialNumToRender={10}
                numColumns={
                  Dimensions.get('window').width > 768 ? 4 : 2
                }
              />
            )}


            {this.state.view == 'monitorplace' && (
              <FlatList
                ref={elem => this[`FlatList-monitorplace`] = elem}
                key={'monitorplace'}
                scrollEnabled={false}
                columnWrapperStyle={{ justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap' }}
                data={this.state.caracteristics.monitorplace}
                renderItem={({ item }) => this.ItemRender(item)}
                ItemSeparatorComponent={this.Divider}
                keyExtractor={item => item.id}
                initialNumToRender={10}
                numColumns={
                  Dimensions.get('window').width > 768 ? 4 : 2
                }
              />
            )}
            {this.state.view == 'monitoruse' && (
              <FlatList
                ref={elem => this[`FlatList-monitoruse`] = elem}
                key={'monitoruse'}
                scrollEnabled={false}
                columnWrapperStyle={{ justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap' }}
                data={this.state.caracteristics.monitoruse}
                renderItem={({ item }) => this.ItemRender(item)}
                ItemSeparatorComponent={this.Divider}
                keyExtractor={item => item.id}
                initialNumToRender={10}
                numColumns={
                  Dimensions.get('window').width > 768 ? 4 : 2
                }
              />
            )}
            {this.state.view == 'monitorscreensize' && (
              <FlatList
                ref={elem => this[`FlatList-monitorscreensize`] = elem}
                key={'monitorscreensize'}
                scrollEnabled={false}
                columnWrapperStyle={{ justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap' }}
                data={this.state.caracteristics.monitorscreensize}
                renderItem={({ item }) => this.ItemRender(item)}
                ItemSeparatorComponent={this.Divider}
                keyExtractor={item => item.id}
                initialNumToRender={10}
                numColumns={
                  Dimensions.get('window').width > 768 ? 4 : 2
                }
              />
            )}
            {this.state.view == 'monitorresolution' && (
              <FlatList
                ref={elem => this[`FlatList-monitorresolution`] = elem}
                key={'monitorresolution'}
                scrollEnabled={false}
                columnWrapperStyle={{ justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap' }}
                data={this.state.caracteristics.monitorresolution}
                renderItem={({ item }) => this.ItemRender(item)}
                ItemSeparatorComponent={this.Divider}
                keyExtractor={item => item.id}
                initialNumToRender={10}
                numColumns={
                  Dimensions.get('window').width > 768 ? 4 : 2
                }
              />
            )}
            {this.state.view == 'monitorpanel' && (
              <FlatList
                ref={elem => this[`FlatList-monitorpanel`] = elem}
                key={'monitorpanel'}
                scrollEnabled={false}
                columnWrapperStyle={{ justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap' }}
                data={this.state.caracteristics.monitorpanel}
                renderItem={({ item }) => this.ItemRender(item)}
                ItemSeparatorComponent={this.Divider}
                keyExtractor={item => item.id}
                initialNumToRender={10}
                numColumns={
                  Dimensions.get('window').width > 768 ? 4 : 2
                }
              />
            )}
            {this.state.view == 'monitorfrequency' && (
              <FlatList
                ref={elem => this[`FlatList-monitorfrequency`] = elem}
                key={'monitorfrequency'}
                scrollEnabled={false}
                columnWrapperStyle={{ justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap' }}
                data={this.state.caracteristics.monitorfrequency}
                renderItem={({ item }) => this.ItemRender(item)}
                ItemSeparatorComponent={this.Divider}
                keyExtractor={item => item.id}
                initialNumToRender={10}
                numColumns={
                  Dimensions.get('window').width > 768 ? 4 : 2
                }
              />
            )}

          </View>
        </View>
        <View>
          <TouchableOpacity
            onPress={() => {
              this.setState({ recommendationVisible: true });
            }}
            disabled={this.state.total > 10 || this.state.total === 0}
            style={{ marginVertical: 15 }}
          >
            <Text style={[styles.textBtnNext, {
              color: this.state.total > 10 || this.state.total === 0 ? Colors.GRAY : Colors.PURPLE,
            }]}>{this.props.locale.in['see']} {this.state.total ? this.state.total : ''} {this.props.locale.in['results']}</Text>
          </TouchableOpacity>
        </View>
        <View>
          {
            this.state.history.length > 0 && (
              <TouchableOpacity
                style={[styles.btnNext, {
                  backgroundColor: Colors.PURPLE,
                }]} onPress={this.prev}
              >
                <Text style={styles.textBtnNext}>{this.props.locale.in['previous']}</Text>
              </TouchableOpacity>
            )
          }

          {
            !!this.state.query && (
              <TouchableOpacity
                style={[styles.btnNext, {
                  marginTop: 0,
                  backgroundColor: Colors.PURPLE,
                }]} onPress={this.reset}
              >
                <Text style={styles.textBtnNext}>{this.props.locale.in['reset']}</Text>
              </TouchableOpacity>
            )
          }
        </View>
        <View style={{ width: '100%', height: 30 }}></View>
      </View>
    );
  };

  Recommendation = () => {
    return (
      <View>
        <Text style={[globalStyles.textCenter, styles.titleAssistant, { marginTop: 50, color: Colors.PURPLE }]}>
          {this.props.locale.in['our_recommendations']}
        </Text>
        <Text style={[globalStyles.textCenter, styles.textAssitant, { marginTop: 10 }]}>
          {this.props.locale.in['assistant_recommendation_paragraph']}
        </Text>
        <View style={{ width: '100%', alignItems: 'center' }}>
          <Select
            name={'cboOrderBy'}
            label={this.props.locale.in['order_by']}
            onValueChange={(item) => {
              // console.log('item =>', item);
              if (item.value) {
                const products = this.state.products.sort((a, b) => {
                  if (item.value === 'precio') {
                    return a.price - b.price;
                  } else {
                    return a.title.localeCompare(b.title);
                  }
                });
                this.setState({ products });
                this.setState({
                  ...this.state,
                  orderValue: item
                });
              }
            }}
            dataItems={[
              { label: this.props.locale.in['price'], value: 'precio' },
              { label: this.props.locale.in['name'], value: 'nombre' },
            ]}
            value={this.state.orderValue}
          />
        </View>
        <View style={{ alignItems: 'center', marginBottom: 50 }}>
          {
            Platform.OS == 'web' && (
              <View style={[globalStyles.row, { maxWidth: '80%', flexWrap: 'wrap', justifyContent: 'center' }]}>
                {
                  this.state.products && this.state.products.map((product, index) => {
                    return (
                      <AllProductsCards style={{ maxWidth: 350 }} {...this.props} product={product} key={'product-' + index} />
                    );
                  })
                }
              </View>
            ) || (
              this.state.products && this.state.products.map((product, index) => {
                return (
                  <AllProductsCards {...this.props} product={product} key={'product-' + index} />
                );
              })
            )
          }
        </View>
        <TouchableOpacity
          style={[styles.btnNext, {
            marginTop: 0,
            backgroundColor: Colors.PURPLE,
          }]} onPress={() => {
            this.reset()
          }}
        >
          <Text style={styles.textBtnNext}>{this.props.locale.in['reset']}</Text>
        </TouchableOpacity>
      </View>
    );
  };
  reset = () => {
    this.setState({
      componentReady: false,
      view: 'unlnown',
    })
    this.getInitialData();
  };
  prev = async () => {

    if (this.state.history.length <= 2) {
      this.reset()
    }
    else {
      console.log('this.state.history.length', this.state.history.length);

      let h = this.state.history;
      h.pop();
      const d = h[h.length - 1]

      console.log('d', d);

      this.setState({
        ...this.state,
        history: h,
        category: d.category,
        products: d.products,
        caracteristics: d.caracteristics,
        caracteristicsSelected: d.caracteristicsSelected,
        view: d.view,
        total: d.products.length,
        recommendationVisible: d.recommendationVisible
      })
    }




  };

  render() {
    if (this.state.componentReady) {
      return (
        <ScrollView>
          <View style={globalStyles.sectionContainer}>
            <View>
              <Text style={globalStyles.sectionTitle}>{this.props.locale.in['assistant']}</Text>
              <Text style={globalStyles.sectionSubTitle}>
                {this.props.locale.in['assistant_paragraph']}
              </Text>
            </View>
            <ImageBackground
              resizeMode="cover"
              source={bgAsistente}
              imageStyle={styles.containerAsistente}
              style={styles.containerImageBackground}
            >
              {
                !this.state.recommendationVisible && this.Assistant() || this.Recommendation()
              }
            </ImageBackground>
          </View>
          <Footer {...this.props} />

          <Modal
            animationType="slide"
            transparent={true}
            visible={this.state.modalVisible}
          >
            <View style={styles.centeredView}>
              <View style={[styles.modalView, {
                backgroundColor: '#fff', width: 300, height: 180, borderRadius: 20
              }]}>
                <TouchableOpacity style={[styles.button, styles.buttonClose]}
                  onPress={() => { this.setState({ modalVisible: false }); this.reset(); }}
                >
                  <Text style={[styles.textStyle, { color: '#000', fontSize: 27, fontWeight: '400' }]}>X</Text>
                </TouchableOpacity>
                <Text style={[styles.modalText, {
                  fontSize: 15
                }]}>
                  {this.props.locale.in['assistant_noresults_message']}
                </Text>
                <View style={{ flexDirection: 'row', marginTop: 15 }}>
                  <TouchableOpacity style={styles.pressButton} onPress={() => { this.setState({ modalVisible: false }); this.reset(); }}>
                    <Text style={[styles.modalText, {
                      fontSize: 15
                    }]}>{this.props.locale.in['reset']}</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
        </ScrollView>
      );
    } else {
      return (
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <ActivityIndicator size="large" color={Colors.PURPLE} />
        </View>
      );
    }
  }
}

const styles = StyleSheet.create({
  picker: {
    height: Platform.OS === 'web' ? 50 : 'auto',
    width: Dimensions.get('window').width < 767 ? '100%' : 310,
    marginVertical: 10,
    position: 'relative',
  },
  selectControlStyle: {
    borderRadius: 25,
    borderBottomLeftRadius: 25,
    borderBottomRightRadius: 25,
    backgroundColor: 'transparent',
    borderColor: '#400c6d',
    padding: 10,
  },
  optionsListStyle: {
    position: Platform.OS == 'web' ? 'fixed' : 'absolute',
    borderColor: '#fff',
  },
  selectControlArrowImageStyle: {
    marginTop: Platform.OS == 'web' ? 0 : 15,
  },
  textAssitant: {
    fontSize: Dimensions.get('window').width < 767 ? 16 : 19,
    color: '#888',
    marginHorizontal: Dimensions.get('window').width < 767 ? 20 : 200,
    marginBottom: 30,
  },
  card: {
    backgroundColor: '#fff',
    borderRadius: 10,
    marginHorizontal: 10,
    marginVertical: 10,
    padding: 5,
    width: Dimensions.get('window').width < 767 ? Dimensions.get('window').width / 2 - 30 :
      Dimensions.get('window').width >= 1200 ? 1200 / 4 - 50 : Dimensions.get('window').width / 4 - 20,
    height: Dimensions.get('window').width < 767 ? 250 : 300,
    justifyContent: 'center',
  },
  cardBody: {
    marginHorizontal: Platform.OS == 'web' ? 22 : 10,
    marginVertical: 10,
  },
  cardTitle: {
    fontSize: Dimensions.get('window').width < 767 ? 14 : 19,
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  cardText: {
    color: '#888',
  },
  imageFLuid: {
    width: Platform.OS === 'web' && Dimensions.get('window').width > 767 ? 180 : 150,
    height: Platform.OS === 'web' && Dimensions.get('window').width > 767 ? 240 : 180,
    maxWidth: '100%',
  },
  titleAssistant: {
    fontFamily: 'Lato_400Regular',
    fontSize: Dimensions.get('window').width < 767 ? 28 : 40,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  containerAsistente: {
    borderRadius: 10,
  },
  containerImageBackground: {
    justifyContent: 'center',
  },
  btnNext: {
    marginVertical: 40,
    marginHorizontal: 'auto',
    paddingVertical: 10,
    maxWidth: '100%',
    width: Platform.OS === 'web' ? 300 : '100%',
    borderRadius: 50,
    textTransform: 'uppercase',
  },
  textBtnNext: {
    fontSize: Dimensions.get('window').width < 767 ? 12 : 18,
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#fff',
  },
  buttonClose: {
    backgroundColor: "#ecebef",
    paddingVertical: 10,
    paddingHorizontal: 18,
    position: "absolute",
    top: -20,
    right: -20,
    alignSelf: 'flex-end',
    borderRadius: 50
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    opacity: 1,
    width: 440,
    maxWidth: '100%',
  },
  modalText: {
    fontFamily: "Lato_400Regular",
  },
  pressButton: {
    backgroundColor: Colors.WHITE,
    borderRadius: 10,
    borderColor: Colors.PURPLE,
    borderWidth: 2,
    marginVertical: 5,
    marginHorizontal: 5,
    paddingVertical: 5,
    paddingHorizontal: 10,
  }
});

const mapStateToProps = (state) => {
  return {
    home: state.home,
    locale: state.locale,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

const Asistente = connect(mapStateToProps, mapDispatchToProps)(AsistenteContainer);
export default Asistente;
