import React, {useEffect,useRef, useState} from 'react';
import {Text, View, Image, TouchableOpacity, Platform, Dimensions, Pressable} from 'react-native';
import {Picker} from 'react-native-woodpicker';
import {styles} from './Select.style';
import {useSelect} from './useSelect';
import {useSelector} from 'react-redux';

import * as Colors from '../../styles/colors';
const iconSelect = require('./arrow-down.png');
const iconSelectLight = require('./arrow-down-light.png');

export const Select = ({
    name,
    label,
    value,
    onValueChange,
    dataItems,
    isBorder = true,
    isBorderLight = false,
    innerStyles,
  }) => {
  const {
    onFocus,
    focus,
    onBlur,
  } = useSelect(value);
  const {width: WIDTH, height: HEIGHT} = Dimensions.get('window');
  const inputEl = useRef(null);
  const [item, setItem] = useState(null)

  const locale = useSelector((state) => state.locale);

  useEffect(() => {
    if (Platform.OS === "web") {
      const selects = document.querySelectorAll('select');
      selects.forEach(item => {
        item.style = `width: 500px; min-width: 200px; height: 40px; top: -20px; z-index: 1;`;
      });
    }
  }, []);

  return (
    <View style={[
      styles.containerPrincipal,
      innerStyles,
      {
        borderWidth: isBorder ? 1 : 0,
        borderColor: isBorderLight ? Colors.WHITE : Colors.GRAY,
      }
    ]}>
      <TouchableOpacity
        style={focus || value.value ? styles.containerTextFocus : styles.containerText}
        onPress={() => {
          onFocus();
        }}
      >
        <Text style={[
          styles.labelText,
          {
            color: isBorderLight ? Colors.WHITE : Colors.GRAY,
          }
        ]}>
          {label}
        </Text>
      </TouchableOpacity>
      <View style={styles.containerSelect}>
        <Pressable
            style={{
               position:'absolute',
               right: Platform.OS == 'web' ? 15 : 30
            }}
          >
            <Image source={isBorderLight ? iconSelectLight : iconSelect} style={styles.fileIco} />
        </Pressable>
        <Picker
          ref={inputEl}
          id={name}
          name={name}
          style={[
            styles.select,
            {
              zIndex: 1,
              elevation: 1,
            }
          ]}
          textInputStyle={
            isBorderLight ? [styles.selectTextSelected,{
              color: Colors.WHITE,
            }] : styles.selectTextSelected
          }
          item={value}
          items={dataItems}
          onItemChange={ (elem => {
            setItem(elem);
            if (Platform.OS!='ios') {
              onValueChange(elem)
            }
          })  
          }
          isNullable={true}
          onOpen={onFocus}
          onClose={onBlur}
          mode={'dialog'}
          themeVariant={'dark'}
          DoneBarComponent={()=>{ return (
            <View 
            style={{
              backgroundColor:'#FFF',
              width: '100%',
              opacity:1
            }}
            containerStyle={{
              background:'red',
              width: 50,
              height:50,
            }}
            >
              <Pressable style={{
                alignItems:'flex-end',
                paddingRight:10,
                paddingTop: 5,
              }} 
                onPress={()=>{
                  onValueChange(item)
                }}
              >
                <Text style={{
                  fontSize: 20,
                  fontWeight: 'bold',
                }}>{locale.in['choose']}</Text>
              </Pressable>
            </View>
          )}}
        />
      </View>
    </View>
  );
};
