export const ENV = 'prod';   //dev

const API_URL_DEV = 'http://192.168.100.148:8000/api/v2/';
const API_URL_PROD = 'https://admin.benq-latam.com/api/v2/';

export const URL_DEV = 'http://192.168.100.148:8000';
export const URL_PROD = 'https://admin.benq-latam.com';

const AUTH_TOKEN_DEV = '';
const AUTH_TOKEN_PROD = '';

export const BASE_URL = ENV === 'prod' ? URL_PROD : URL_DEV;
export const API_URL = ENV === 'prod' ? API_URL_PROD : API_URL_DEV;
export const AUTH_TOKEN = ENV === 'prod' ? AUTH_TOKEN_PROD : AUTH_TOKEN_DEV;

export const HUBSPOT_NT_URL = '//js-na1.hs-scripts.com/19623465.js';
export const HUBSPOT_BQ_URL = '//js-na1.hs-scripts.com/159104.js';

