import React from 'react';
import {View, StyleSheet, TextInput, Text, Platform, ActivityIndicator, Pressable, Dimensions} from 'react-native';
import * as Colors from '../styles/colors';

import * as actions from '../actions/profile';
import * as actionsDistributors from '../actions/distributors';
import * as fetchData from '../constants/actionTypes.js';
import * as RootNavigation from '../rootNavigation';

import AsyncStorage from '@react-native-async-storage/async-storage';
import {connect} from 'react-redux';
import Select from '../components/Select';

class RegisterDistributorSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      email: '',
      password: '',
      first_name: '',
      last_name: '',
      loading: false,
      company: '',
      address: '',
      place: '',
      phone: '',
      states: [],
      countries: [],
      country: '',
      state: '',
      offset: 0,
      countryValue: {
        label: '',
        value: '',
      },
      cityValue: {
        label: '',
        value: '',
      }
    };
    this.countryRef = React.createRef();
  }

  async componentDidMount() {
    await this.getCountries();
  }

  setProfileToStorage = async (profile) => {
    const {is_user, user_id, is_active, country} = profile;
    await AsyncStorage.setItem('profile', JSON.stringify({is_user, user_id, is_active, country}));
  };

  handleSubmit = async () => {
    const {email, password, first_name, last_name, company, address, place, phone, country, state} = this.state;

    if ( !email || !password || !first_name || !last_name || !company || !address || !place || !phone || !country || !state ) {
      this.props.toastRef.current.show(this.props.locale.in['all_fields_are_required'], {
        type: 'danger',
        duration: 3000,
        placement: 'top',
      });
      return;
    }
    if( /\S+@\S+\.\S+/.test(email) === false) {
      this.props.toastRef.current.show(this.props.locale.in['you_have_to_put_a_valid_email'], { type: 'danger', duration: 3000, placement: 'top' });
      return;
    }


    this.setState({loading: true});

    const data = {
      'username': email.toLowerCase().trim(),
      'email': email.toLowerCase().trim(),
      'password': password.trim(),
      'first_name': first_name.trim(),
      'last_name': last_name.trim(),
      'country': country,
      'state': state,
      'company': company.trim(),
      'address': address.trim(),
      'place': place.trim(),
      'phone': phone.trim(),
      'profile': 'distributor',
    };

    await actions
    .registerUser(JSON.stringify(data))
    .then(res => {
      if ( res.type === 'FETCHING_PROFILE_SUCCESS' && res.profileData.is_user ) {
        RootNavigation.navigate('Login');
        this.setProfileToStorage(res.profileData);
        this.props.setProfile(res.profileData);
        this.setState({loading: false});
      } else {
        this.props.toastRef.current.show('No se pudo registrar el usuario.', {
          type: 'danger',
          duration: 3000,
          placement: 'bottom',
        });
        this.setState({loading: false});
      }
    }).catch(err => {
      console.log(err);
    });

  };

  getCountries = async () => {
    await actionsDistributors
    .getCountries()
    .then(res => {
      if ( res.type == fetchData.FETCHING_DISTRIBUTOR_SUCCESS ) {
        this.setState({countries: res.distributorData.items,states:[]});
        //this.getStates(res.distributorData.items[0].id);
      }
    }).catch(err => {
      console.log(err);
    });
  };

  getStates = async (country) => {
    await actionsDistributors
    .getStates(country)
    .then(res => {
      if ( res.type == fetchData.FETCHING_DISTRIBUTOR_SUCCESS ) {
        this.setState({states: res.distributorData.items});
      }
    }).catch(err => {
      console.log(err);
    });
  };

  render() {
    return (
      <View style={{
        width:'100%',
        maxWidth: Platform.OS == 'web' ? 360 : Dimensions.get('window').width - 30,
      }}>
        <TextInput
          style={[styles.formInput, {marginTop: 20}]}
          placeholder={this.props.locale.in['name']}
          autoCapitalize={'words'}
          autoCorrect={false}
          onChangeText={async(text) => {
            await this.setState({first_name: text})
          }}
        />
        <TextInput
          style={[styles.formInput]}
          placeholder={this.props.locale.in['last_name']}
          autoCapitalize={'words'}
          autoCorrect={false}
          onChangeText={async (text) => {
            await this.setState({last_name: text})
          }}
        />
        <TextInput
          style={[styles.formInput]}
          placeholder={this.props.locale.in['email']}
          autoCapitalize={'none'}
          autoCorrect={false}
          onChangeText={async (text) => {
            await this.setState({email: text})
          }}
        />
        <TextInput
          style={[styles.formInput]}
          placeholder={this.props.locale.in['password']}
          onChangeText={(text) => this.setState({password: text})}
          secureTextEntry
        />
        <TextInput
          style={[styles.formInput]}
          autoCapitalize={'none'}
          autoCorrect={false}
          placeholder={this.props.locale.in['phone']}
          onChangeText={async (text) => {
            await this.setState({phone: text})
          }}
        />
        <TextInput
          style={[styles.formInput]}
          placeholder={this.props.locale.in['address']}
          autoCapitalize={'sentences'}
          autoCorrect={false}
          onChangeText={async (text) => {
            await this.setState({address: text})
          }}
        />
        <Select
          name={'cboCountry'}
          label={this.props.locale.in['country']}
          innerStyles={{width: '100%'}}
          onValueChange={(item) => {
            if (item.value) {
              this.setState({
                state: '',
                cityValue: {
                  label: '',
                  value: '',
                }
              });
              this.setState({
                country: item.value,
                countryValue: {
                  label: item.label,
                  value: item.value,
                }
              });
              this.getStates(item.value);
            }
          }}
          dataItems={[
            ...this.state.countries.map(item => ({label: item.meta.name.toUpperCase(), value: item.id.toString()})),
          ]}
          value={this.state.countryValue}
        />
        <Select
          name={'cboCity'}
          label={'Entidad Federativa'}
          innerStyles={{width: '100%'}}
          onValueChange={(item) => {
            if (item.value) {
              this.setState({
                state: item.value,
                cityValue: {
                  label: item.label,
                  value: item.value,
                }
              });
              this.getStates(item.value);
            }
          }}
          dataItems={[
            ...this.state.states.map(item => ({label: item.meta.name.toUpperCase(), value: item.id.toString()})),
          ]}
          value={this.state.cityValue}
        />
        <TextInput
          style={[styles.formInput]}
          placeholder={this.props.locale.in['company_name']}
          autoCapitalize={'words'}
          autoCorrect={false}
          onChangeText={async (text) =>{
            await this.setState({company: text})
          }}
        />
        <TextInput
          style={[styles.formInput]}
          placeholder={this.props.locale.in['where_buy']}
          autoCapitalize={'words'}
          autoCorrect={false}
          onChangeText={async (text) => {
            await this.setState({place: text})
          }}
        />
        <Pressable
          style={[styles.button, styles.buttonEnter]}
          onPress={this.handleSubmit}
          disabled={this.state.loading}
        >
          <Text style={[styles.textStyle, {color: '#fff'}]}>{this.props.locale.in['save']}</Text>
          {
            this.state.loading && (
              <ActivityIndicator size="small" color={Colors.WHITE} style={{marginStart: 10}}/>
            )
          }
        </Pressable>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    //textTransform: 'uppercase',
  },
  formInput: {
    borderColor: Colors.LIGTH_GRAY,
    borderRadius: 25,
    borderWidth: 2,
    marginVertical: 5,
    //textTransform: 'uppercase',
    padding: 10,
    paddingHorizontal: 17,
    color: Colors.GRAY,
    width: '100%',
    backgroundColor: 'transparent',
  },
  selectControlArrowImageStyle: {
    marginTop: Platform.OS == 'web' ? 0 : 15,
  },
  picker: {
    height: Platform.OS === 'web' ? 50 : 'auto',
    width: '100%',
    maxWidth: 300,
    marginVertical: 10,
    position: 'relative',
  },
  selectControlStyle: {
    borderRadius: 25,
    borderBottomLeftRadius: 25,
    borderBottomRightRadius: 25,
    backgroundColor: 'transparent',
    borderColor: Colors.BLACK,
    padding: 10,
  },
  optionsListStyle: {
    position: Platform.OS == 'web' ? 'fixed' : 'absolute',
    borderColor: '#fff',
    //marginTop: Platform.OS == 'web' ? -100 : -40,
    maxWidth: 300,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonEnter: {
    backgroundColor: Colors.PURPLE,
    marginTop: 50,
    justifyContent: 'center',
    width: '100%',
  },
});

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
    locale: state.locale,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setProfile: (data) => {
      return dispatch(actions.setProfile(data));
    },
  };
};
const RegisterDistributor = connect(mapStateToProps, mapDispatchToProps)(RegisterDistributorSection);

export default RegisterDistributor;
