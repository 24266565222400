import {
    FETCHING_SEARCHING_PENDING,
    FETCHING_SEARCHING_SUCCESS,
    FETCHING_SEARCHING_FAILURE,
  } from '../constants/actionTypes.js';
  
  import api from '../api/search.js';
  
  export const searchItems = (data) => {
    return api('searchItems', data)
    .then(([response, json]) => {
      json.statusCode = response.status;
      return getDataSuccess(json);
    })
    .catch((err) => {
      console.log(err);
      return getDataFailure(err);
    });
  };

  
  //API Requests
  export const getData = () => {
    return {
      type: FETCHING_SEARCHING_PENDING,
    };
  };
  export const getDataSuccess = (json) => {
    return {
      type: FETCHING_SEARCHING_SUCCESS,
      searchingData: json,
    };
  };
  export const getDataFailure = () => {
    return {
      type: FETCHING_SEARCHING_FAILURE,
    };
  };
  