import React, {useEffect, useState} from 'react';
import {View, Text, TextInput, Button, ScrollView, Platform} from 'react-native';
import * as yup from 'yup';
import * as Global from '../../styles/global';
import Select from '../../components/Select';
import {styles} from './Contact.styles';
import * as Colors from '../../styles/colors';
import {BASE_URL} from '../../constants/endPoints';
import * as actions from '../../actions/distributors';
import {useSelector} from 'react-redux';
import ModalMessage from '../modalMessage';
import Footer from '../Footer';

const initialValues = {
  name: '',
  last_name: '',
  phone: '',
  email: '',
  country: '',
  state: '',
  message: '',
};

const countryCodes = {
  "1" : {
    name:'México',
    code:'+52'
  },
  "2" : {
    name:'México',
    code:'+55'
  },
  "3" : {
    name:'México',
    code:'+57'
  },
  "4" : {
    name:'México',
    code:'+51'
  },
  "5" : {
    name:'México',
    code:'+54'
  }
};


export const Contact = (props) => {
  const locale = useSelector( (state) => state.locale)
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [typeMessage, setTypeMessage] = useState('normal');
  const [contact, setContact] = useState(initialValues);

  const [state, setState]   = React.useState(1);
  const [states, setStates] = React.useState([]);

  const URL = `${BASE_URL}/api/v2/contact/send-message/`;

  const schema = yup.object().shape({
    message: yup.string().required(locale.in['you_have_to_put_a_message']),
    state:   yup.string().required(locale.in['you_have_to_put_a_city']),
    email:   yup.string().email(locale.in['you_have_to_put_a_valid_email']).required(locale.in['you_have_to_put_an_email']),
    phone:   yup.string().required(locale.in['you_have_to_put_a_phone']),
    last_name: yup.string().required(locale.in['you_have_to_put_a_last_name']),
    name:      yup.string().required(locale.in['you_have_to_put_a_name']),
  });

  const onShowModal = () => {
    setShowModal(true);
  };
  const onCloseModal = () => {
    setShowModal(false);
  };

  useEffect( () => {
    const getStates = async (country) => {
      const estados = await actions.getStates(country);
      const items   = estados.distributorData.items;
      let data = []
      items.forEach(item => {
        data.push({label: item.meta.name.toUpperCase(), value: item.id.toString()});
      });
      setStates(data)
    }
    getStates(locale.country.id)
  },[null])

  const handleInputChange = async (e) => {
    const {name, value, ctrl = 'input'} = e;
    await setContact({
      ...contact,
      [name]: ctrl === 'input' ? value : value.label,
    });
  };

  const handledSubmit = async () => {
    try {
      await schema.validate(contact);
      contact.phone = ( countryCodes[locale.country.id].code + contact.phone );
      
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(contact),
        redirect: 'follow'
      };
      await fetch(URL, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.status === 'success') {
            setTypeMessage('normal');
            setModalMessage('Gracias por escribir.');
          } else {
            setTypeMessage('error');
            setModalMessage('Hubo un error en el envío, intente de nuevo por favor.');
          }
          onShowModal();
          setContact(initialValues);
        })
        .catch(error => {
          // console.log('error', error);
        });
    } catch (e) {
      console.log(e);
      setTypeMessage('error');
      setModalMessage(e.message);
      onShowModal();
      setContact(initialValues);
    }
  };
  
  return (
    <ScrollView>
      <View style={{marginTop: Platform.OS == 'ios' ? 50 : 0}}>
        <Text style={Global.default.sectionTitle}>{locale.in['contacto']}</Text>
          <Text style={[Global.default.sectionSubTitle,{textAlign: 'center'}]}>
            {locale.in['contacto_paragraph']}
          </Text>
        <View style={{alignItems:'center'}}>
          <View style={styles.contColumn}>
            <TextInput
              style={styles.textInput}
              placeholder={locale.in['name']}
              name="name"
              value={contact.name}
              autoCapitalize={'words'}
              autoCorrect={false}
              onChangeText={async (e) => {
                await handleInputChange({name: 'name', value: e})
              }}
            />
            <TextInput
              style={styles.textInput}
              placeholder={locale.in['last_name']}
              name="last_name"
              value={contact.last_name}
              onChangeText={(e) => handleInputChange({name: 'last_name', value: e})}
            /> 
            <View style={[{flexDirection:'row',flexWrap:'nowrap',justifyContent:'flex-start',width: '100%'}]}>
              <View style={[styles.textInput,{width:'20%',textAlign:'center',justifyContent:'center'}]}>
                <Text>
                  {countryCodes[locale.country.id].code}  
                </Text>                           
              </View>
              <View style={[{width:'80%',}]}>
                <TextInput
                  style={styles.textInput}
                  placeholder={locale.in['phone']}
                  name="phone"
                  value={contact.phone}
                  keyboardType='numeric'
                  onChangeText={(e) => handleInputChange({name: 'phone', value: e})}
                />
              </View>
            </View>
            <TextInput
              style={styles.textInput}
              placeholder={locale.in['email']}
              name="email"
              value={contact.email}
              onChangeText={(e) => handleInputChange({name: 'email', value: e})}
            />
          </View>
          <View style={styles.contColumn}>
            <Select
              menuIsOpen
              name={'cboCity'}
              label={state.label ? state.label : 'SELECCIONA UNA ENTIDAD'}
              innerStyles={{width: '100%'}}
              onValueChange={(item) => {
                setState(item)

                setContact({
                  ...contact,
                  state:   item.label,
                  country: locale.country.meta.name
                });
              }}
              value={state}
              dataItems={states}
            />
            <TextInput
              style={styles.textInput}
              placeholder={locale.in['message']}
              multiline={true}
              numberOfLines={5}
              name="message"
              value={contact.message}
              onChangeText={(e) => handleInputChange({name: 'message', value: e})}
            />
            </View>
              <View style={styles.submitButton}>
                <Button
                  color={Colors.PURPLE}
                  title={locale.in['send']}
                  onPress={handledSubmit}
                />
            </View>
              {showModal && <ModalMessage message={modalMessage} type={typeMessage} onClose={onCloseModal}/>}
        </View>
      </View>
      <Footer {...props}/>
    </ScrollView>
  );  
};
