const dictionary = {
    k:    'es',
    home: 'Inicio',
    profile: 'Perfil',
    products: 'Productos',
    monitores: 'Monitores',
    proyectors: 'Proyectores',
    interactive_displays: 'Pantalas interactivas',
    assistant: 'Asistente',
    training: 'Capacitación',
    distributor:  'Distribuidor',
    distributors: 'Distribuidores',
    promotions: 'Promociones',
    appointments: 'Citas',
    contact: 'Contacto',
    search: 'Buscar',
    report_an_error: 'Reportar un error',

    videos: 'Videos',
    articles: 'Artículos',
    podcasts: 'Podcasts',
    see_more: 'Ver más',
    see_less: 'Ver menos',
    see:  'Ver',
    tips: 'Tips',
    follow_us: 'Síguenos',
    listen:    'Escuchar',
    close:      'Cerrar',
    results:    'Resultados',
    error:      'Error',
    message:    'Mensaje',
    send:       'Enviar',

    change_your_logo:     'Cambiar su logotipo',
    upload_your_logo:     'Suba su logotipo',
    change_your_password: 'Cambiar su contraseña',
    new_password:         'Nueva contraseña',
    confirm_password:     'Confirmar contraseña',
    i_want_receive_notifications: 'Quiero recibir notificaciones',
    
    filter_by: 'Filtrar por',
    order_by: 'Ordenar por',
    date: 'Fecha',
    title: 'Título',
    reviews: 'Comentários',

    wholesaler: 'Mayorista',
    retail:     'Minorista',
    price:    'Precio',
    new:      'Nuevo',
    sold_out: 'Agotado',

    share:  'Compartir',
    save:   'Guardar',
    cancel: 'Cancelar',
    edit:   'Editar',
    delete: 'Eliminar',
    saved:  'Guardado',
    user:   'Usuario',
    email:  'Correo electrónico',
    password: 'Contraseña',
    enter:  'Ingresar',
    next:   'Siguiente',
    upcoming: 'Próximos',
    reset:  'Reiniciar',
    previous: 'Anterior',

    forgot_your_password: '¿Olvidaste tu contraseña?',
    new_password: 'Nueva contraseña',
    repeat_password: 'Repetir contraseña',
    not_have_an_account: '¿No tienes una cuenta?',
    have_you_an_account: '¿Ya tienes una cuenta?',
    sign_in_here:       'Inicia sesión aquí',
    sign_in:            'Iniciar sesión',
    what_use_will_you:  '¿Qué uso le daras a tu producto?',
    our_recommendations: 'Nuestras recomendaciones',

    sign_up_here: 'Regístrate aquí',
    sign_up: 'Registrarse',

    choose:           'Selecciona',
    choose_a_profile: 'Selecciona un perfil',
    name:            'Nombre',
    last_name:      'Apellido',
    phone:          'Teléfono',
    address:        'Dirección',
    country:        'País',
    state:          'Estado',
    company_name:   'Razón social',
    where_buy:      '¿Dónde compra?',
    items_saved:    'Elementos guardados',
    more_info:      'Más información',

    price_just_for_reference: 'Precio de referencia, para mayor información consulte con su ejecutivo de cuenta.',
    where_buy: 'Dónde comprar',

    all_fields_are_required: 'Todos los campos son obligatorios',
    user_cannot_be_registered: 'El usuario no puede ser registrado',
    document_not_available: 'El documento no está disponible',
    saved_successfully: 'Guardado correctamente',
    deleted_successfully: 'Eliminado correctamente',
    user_or_password_incorrect: 'Usuario o contraseña incorrectos',
    user_incorrect: 'Usuario incorrecto o no se encuentra registrado',
    passwords_do_not_match: 'Las contraseñas no coinciden',
    password_does_not_match: 'La contraseña no coincide',
    choose_a_country: 'Selecciona un país',
    choose_a_state: 'Selecciona un estado',
    data_save_successfully: 'Datos guardados correctamente',
    no_products_found: 'No hay productos para mostrar',
    no_webinars_found: 'No hay webinars para mostrar',
    no_videos_found: 'No hay videos para mostrar',
    no_distributors_found: 'No hay distribuidores para mostrar',
    no_promotions_found: 'No hay promociones para mostrar',
    recoverpass_request_sent: 'Se ha enviado un correo para recuperar la contraseña',
    successfully_operaion: 'Operación exitosa',

    you_have_to_put_a_message: 'Tienes que poner un mensaje',
    you_have_to_put_a_city: 'Tienes que poner tu ciudad',
    you_have_to_put_a_country: 'Tienes que poner tu pais',
    you_have_to_put_an_email: 'Tienes que poner tu email',
    you_have_to_put_a_valid_email: 'Tienes que poner un email válido',
    you_have_to_put_a_phone: 'Tienes que poner tu teléfono',
    you_have_to_put_a_name: 'Tienes que poner tu nombre',
    you_have_to_put_a_last_name: 'Tienes que poner tu apellido',

    no_login_modal:   'Te invitamos a registrarte y disfrutar de todos los beneficios que te ofrecemos.',
    profile_paragraph:'Manten actualizada tu información para que podamos brindarte la mejor experiencia de usuario.',
    footer_paragraph: 'La prioridad de BenQ es crear impacto en las áreas más importantes de la vida de sus usuarios —mejorar su experiencia y su calidad de vida. Todo lo que es importante para ustedes, es importante para nosotros… Because It Matters!',
    login_paragraph:  'Ingresa con tu correo electrónico y contraseña.',
    recover_password_paragraph:  'Coloca tu nombre de usuario o email.',
    assistant_paragraph: 'Te ayudamos a encontrar el producto que necesitas, utiliza el asistente seleccionando las carácteristicas que mejor se adecuen a lo que necesitas.',
    assistant_recommendation_paragraph: 'Aquí encontrarás las recomendaciones de productos que tenemos para tí.',
    distributors_paragraph: 'Encuentra un distribuidor cerca de tí, te ayudarán con los mejores productos al mejor precio.',
    promotions_paragraph: 'Aprovecha nuestras promociones, consigue lo mejor al mejor precio, siempre estamos actualizando nuestras ofertas y beneficios.',
    contacto_paragraph: 'Si tienes dudas, sugerencias o algún problema, ponte en contacto con nosotros, llena el siguiente formulario y responderemos a la brevedad.',


    assistant_question_distance : '¿A qué distancia lo instalaras?',
    assistant_question_frequency: '¿Que frecuencia necesitas?',
    assistant_question_hardwarecalibrable: '¿Con calibración por hardware?',
    assistant_question_installation: '¿Cómo lo instalaras?',
    assistant_question_lightcontrol: '¿Con control de luz?',
    assistant_question_lighttype: '¿Qué tipo de iluminación prefieres?',
    assistant_question_panel: '¿Qué tipo de panel?',
    assistant_question_place: '¿En que lugar lo instalaras?',
    assistant_question_position: '¿En que posición lo instalaras?',
    assistant_question_purpose: '¿Qué propósito tiene?',
    assistant_question_resolution: '¿Qué resolución necesitas?',
    assistant_question_responsetime: '¿Qué tiempo de respuesta esperas?',
    assistant_question_screensize: '¿Qué tamaño de pantalla necesitas?',
    assistant_question_size: '¿Qué tamaño necesitas?',
    assistant_question_speakers: '¿Qué altavoces buscas?',
    assistant_question_technology: '¿Qué tecnología prefieres?',
    assistant_question_touch: '¿Con función touch?',
    assistant_question_use: '¿Qué uso le daras a tu producto?',
    assistant_question_worktime: '¿Qué tipo de trabajo hará?',

    assistant_noresults_message: '¡No hay resultados para tu selección!, ¿Deseas intentar con otra búsqueda?',
}
export default dictionary;