import React, { useState, useEffect } from 'react';
import {
    StyleSheet,
    TouchableOpacity,
    Text,
    View,
    Image,
    Modal,
    Platform,
    Share,
    Linking
} from "react-native";
import * as Colors from '../../styles/colors';
import { BASE_URL } from "../../constants/endPoints";

import { RWebShare } from "react-web-share";
import { useSelector } from 'react-redux';
import { useToast } from "react-native-toast-notifications";

import { navigationRef } from '../../rootNavigation';


import * as actions from '../../actions/profile';

function SaveAndShare({ item, options, handleGetItems, navigation }) {
    const state = useSelector((state) => state);
    const toast = useToast();
    const [modalVisible, setModalVisible] = useState(false)


    return (
        <View style={{
            backgroundColor: options.purple ? 'transparent' : 'rgba(0,0,0,0.5)',
            position: options.relative ? 'relative' : 'absolute',
            bottom: 0,
            right: 0,
            height: options.relative ? 45 : 50,
            minWidth: 200,
            width: '100%',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            padding: options.relative ? 0 : 5
        }}>

            <View style={styles.centeredView}>
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={modalVisible}
                >
                    <View style={styles.centeredView}>
                        <View style={[styles.modalView, {
                            backgroundColor: '#fff',
                            width: 300,
                            height: 180,
                            borderRadius: 20,
                        }]}>
                            <TouchableOpacity style={[styles.button, styles.buttonClose]}
                                onPress={() => { setModalVisible(false) }}
                            >
                                <Text style={[styles.textStyle, { color: '#000', fontSize: 27, fontWeight: '400' }]}>X</Text>
                            </TouchableOpacity>
                            <Text style={[styles.modalText, {
                                fontSize: 14
                            }]}>
                                {state.locale.in['no_login_modal']}
                            </Text>
                            <View style={{ flexDirection: 'row', marginTop: 15 }}>
                                <TouchableOpacity style={styles.pressButton} onPress={() => {
                                    setModalVisible(false);
                                    navigationRef.navigate('Registro')
                                }} >
                                    <Text style={[styles.modalText, {
                                        fontSize: 14
                                    }]}>{state.locale.in['sign_up']}</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={styles.pressButton} onPress={() => {
                                    setModalVisible(false);
                                    navigationRef.navigate('Login')
                                }}>
                                    <Text style={[styles.modalText, {
                                        fontSize: 14
                                    }]}>{state.locale.in['sign_in']}</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </Modal>
            </View>



            {
                options.download && (
                    <TouchableOpacity onPress={() => {
                        if (item.document) {
                            //if (state.profile && state.profile.profileData && state.profile.profileData.user_id) {
                            const url = item.document.meta.download_url;
                            Linking.openURL(url);
                            //}
                            //else {
                            //    setModalVisible(true)
                            //}
                        } else {
                            toast.show('Documento no disponible', { type: 'normal', duration: 1000 })
                        }
                    }} style={{ marginHorizontal: 15 }}>
                        <Image
                            source={
                                options.purple ?
                                    require('../../../assets/components/ShareAndSave/documento/ICONO_DOCUMENTO-01.png') :
                                    require('../../../assets/components/ShareAndSave/documento/ICONO_DOCUMENTO-01Negro.png')
                            }
                            style={{
                                width: 40,
                                height: 40,
                            }}
                            resizeMode={'contain'}
                        />
                    </TouchableOpacity>
                )
            }
            {
                options.extraDownload && (
                    <TouchableOpacity onPress={() => {
                        if (item.document_extra) {
                            //if (state.profile && state.profile.profileData && state.profile.profileData.user_id) {
                            const url = item.document_extra.meta.download_url;
                            Linking.openURL(url);
                            //}
                            //else {
                            //    setModalVisible(true)
                            //}
                        } else {
                            toast.show('Documento no disponible', { type: 'normal', duration: 1000 })
                        }
                    }} style={{ marginHorizontal: 15 }}>
                        <Image
                            source={
                                options.purple ?
                                    require('../../../assets/components/ShareAndSave/documento/ICONO_DOCUMENTO-02.png') :
                                    require('../../../assets/components/ShareAndSave/documento/ICONO_DOCUMENTO-02Negro.png')
                            }
                            style={{
                                width: 40,
                                height: 40,
                            }}
                            resizeMode={'contain'}
                        />
                    </TouchableOpacity>
                )
            }
            {
                options.save && state.profile && state.profile.profileData && state.profile.profileData.user_id && (
                    <TouchableOpacity onPress={async () => {
                        await actions.saveItem({
                            id: state.profile.profileData.user_id,
                            item: item.id
                        });
                        toast.show("Guardado correctamente!", { type: 'success', duration: 2000 });
                    }} style={{ marginHorizontal: 15 }}>
                        <Image
                            source={
                                options.purple ?
                                    require('../../../assets/components/ShareAndSave/guardar/guardar-icon-benq.png') :
                                    require('../../../assets/components/ShareAndSave/guardar/guardar-icon-blanco-benq.png')
                            }
                            style={{
                                width: 40,
                                height: 40,
                            }}
                        />
                    </TouchableOpacity>
                )
            }
            {
                options.delete && state.profile && state.profile.profileData && state.profile.profileData.user_id && (
                    <TouchableOpacity onPress={async () => {
                        await actions.deleteItem({
                            id: state.profile.profileData.user_id,
                            item: item.id
                        }).then(() => {
                            toast.show("¡Eliminado correctamente!", { type: 'success', duration: 2000 });
                            handleGetItems(state.profile.profileData.user_id);
                        });
                    }} style={{ marginHorizontal: 15 }}>
                        <Image
                            source={
                                options.purple ?
                                    require('../../../assets/components/ShareAndSave/eliminar/ICONOS-morado.png') :
                                    require('../../../assets/components/ShareAndSave/eliminar/ICONOS-blanco.png')
                            }
                            style={{
                                width: 40,
                                height: 40,
                            }}
                        />
                    </TouchableOpacity>
                )
            }
            {
                options.share && Platform.OS === 'web' && item.document && (
                    <View style={{ marginHorizontal: 15, cursor: 'pointer' }}>
                        {/*  {
                            state.profile && state.profile.profileData && state.profile.profileData.user_id && (
                        */}
                        <RWebShare
                            onClick={() => {
                                //console.log('item', item);
                            }}
                            closeText={"Cerrar"}
                            data={{
                                url: item.document.meta.download_url,
                                title: " " + item.title,
                            }}>
                            <Image
                                source={
                                    options.purple ?
                                        require('../../../assets/components/ShareAndSave/compartir/compartir-icon-benq.png') :
                                        require('../../../assets/components/ShareAndSave/compartir/compartir-icon-blanco-benq.png')
                                }
                                style={{
                                    width: 40,
                                    height: 40,
                                }}
                                resizeMode={'contain'}
                            />
                        </RWebShare>
                        {/* 
                            ) || (
                                <TouchableOpacity onPress={() => {
                                    setModalVisible(true)
                                }} style={{ marginHorizontal: 15 }}>
                                    <Image
                                        source={
                                            options.purple ?
                                                require('../../../assets/components/ShareAndSave/compartir/compartir-icon-benq.png') :
                                                require('../../../assets/components/ShareAndSave/compartir/compartir-icon-blanco-benq.png')
                                        }
                                        style={{
                                            width: 40,
                                            height: 40,
                                        }}
                                        resizeMode={'contain'}
                                    />
                                </TouchableOpacity>
                            )
                        }
                        */}


                    </View>
                )
            }
            {
                options.share && Platform.OS === 'web' && !item.document && (
                    <View style={{ marginHorizontal: 15, cursor: 'pointer' }}>
                        <RWebShare
                            onClick={() => {
                                //console.log('item', item);
                            }}
                            closeText={"Cerrar"}
                            data={{
                                url: item.path ? item.url : BASE_URL + item.url,
                                title: " " + item.title,
                            }}>
                            <Image
                                source={
                                    options.purple ?
                                        require('../../../assets/components/ShareAndSave/compartir/compartir-icon-benq.png') :
                                        require('../../../assets/components/ShareAndSave/compartir/compartir-icon-blanco-benq.png')
                                }
                                style={{
                                    width: 40,
                                    height: 40,
                                }}
                                resizeMode={'contain'}
                            />
                        </RWebShare>
                    </View>
                )
            }
            {
                options.share && Platform.OS !== 'web' && (
                    <TouchableOpacity onPress={() => {
                        //console.log('share',item);
                        const url = item.path ? item.url : BASE_URL + item.url;
                        Share.share({
                            url: url,
                            message: Platform.OS === 'android' ? item.title +
                                '\n\n' + url : item.title
                        }, {
                            dialogTitle: "BenQ"
                        });
                    }} style={{ marginHorizontal: 15 }}>
                        <Image
                            source={
                                options.purple ?
                                    require('../../../assets/components/ShareAndSave/compartir/compartir-icon-benq.png') :
                                    require('../../../assets/components/ShareAndSave/compartir/compartir-icon-blanco-benq.png')
                            }
                            style={{
                                width: 40,
                                height: 40,
                            }}
                        />
                    </TouchableOpacity>
                )
            }
        </View>
    )
}
export default SaveAndShare;


const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center"
    },
    modalView: {
        margin: 20,
        backgroundColor: "white",
        padding: 35,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        opacity: 1,
        width: 440,
        maxWidth: '100%',
    },
    modalText: {
        fontFamily: "Lato_400Regular",
    },
    pressButton: {
        backgroundColor: Colors.WHITE,
        borderRadius: 10,
        borderColor: Colors.PURPLE,
        borderWidth: 2,
        marginVertical: 5,
        marginHorizontal: 5,
        paddingVertical: 5,
        paddingHorizontal: 10,
    },
    buttonClose: {
        backgroundColor: "#ecebef",
        paddingVertical: 10,
        paddingHorizontal: 18,
        position: "absolute",
        top: -20,
        right: -20,
        alignSelf: 'flex-end',
        borderRadius: 50
    }
})