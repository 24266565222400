import React, { } from 'react';
import {
    ScrollView,
    View,
    Text,
    ImageBackground,
    Image,
    Pressable,
    Platform,
    Dimensions,
    StyleSheet,
    FlatList
} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import * as Global from '../styles/global';
import { BASE_URL } from "../constants/endPoints";
import { connect } from 'react-redux';


const gradientStart = {
    x: 0,
    y: 1
};
const gradientEnd = {
    x: 1,
    y: 0
};
const hexToRgb = (hex) => {
    let result = '169,124,210'
    if (hex.length === 7) {
        result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        result = `${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)}`;
    }
    else if (hex.length === 4) {
        result = /^#?([a-f\d]{1})([a-f\d]{1})([a-f\d]{1})$/i.exec(hex);
        result = `${parseInt(result[1], 8)},${parseInt(result[2], 8)},${parseInt(result[3], 8)}`;
    }
    return result;
}



const window = Dimensions.get("window");
const screen = Dimensions.get("screen");
class ProductCardsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            intro: '',
            items: [],
            componentReady: false,
            dimensions: {
                window,
                screen
            }
        }
    }
    onChange = ({ window, screen }) => {
        this.setState({ dimensions: { window, screen } });
    }
    componentDidMount() {
        //console.log('ProductCardsContainer componentDidMount',this.props.home);
        //console.log('this.props.home.homeData.items.imagenes',this.props.home.homeData.items)
        if (this.props.home && this.props.home.homeData && this.props.home.homeData.items) {
            this.setState({
                items: this.props.home.homeData.items[0].categories.pages,
                intro: this.props.home.homeData.items[0].categories.intro,
                componentReady: true
            })
        }
        this.dimensionsSubscription = Dimensions.addEventListener("change", this.onChange);
    }
    componentWillUnmount() {
        this.dimensionsSubscription?.remove();
    }
    Divider = () => {
        return (
            <View style={{}}>
            </View>
        );
    };
    ItemRender = (product) => {
        return (
            <Pressable style={[styles.card, {
                flex: Platform.OS == 'web' ? this.state.dimensions.window.width > 768 ? '50%' : '25%' : 0.5,
                width: Platform.OS == 'web' ? this.state.dimensions.window.width > 768 ? '33%' : '50%' : this.state.dimensions.window.width
            }]}
                onPress={() => {
                    this.props.navigation.navigate('Productos', {
                        category: product.id,
                        title: product.title,
                        filter: product.slug
                    })
                }}
            >
                <LinearGradient
                    colors={[`rgba(${hexToRgb(product.background_color_start)},1)`, `rgba(${hexToRgb(product.background_color_end)},1)`]}
                    start={gradientStart}
                    end={gradientEnd}
                    style={styles.gradient}>
                    <Image
                        source={
                            { uri: BASE_URL + product.image }
                        }
                        style={[styles.cardImage, {
                            marginTop: this.state.dimensions.window.width > 768 ? 30 : 0
                        }]}
                        resizeMode="contain"
                    ></Image>
                    <Text
                        style={[styles.cardTitle, {
                            fontSize: this.state.dimensions.window.width > 1024 ? 30 : 768 ? 22 : 15,
                            marginTop: this.state.dimensions.window.width > 768 ? 20 : 10,
                        }]}
                    >{product.title}</Text>
                </LinearGradient>
            </Pressable>
        )
    }
    render() {
        return (
            <View style={styles.container}>
                <View style={[styles.titleContainer, {
                    maxWidth: Dimensions.get('window').width >= 1200 ? 1200 : '100%',
                }]}>
                    <Text style={Global.default.sectionHeader} adjustsFontSizeToFit={true}>
                        {this.props.locale.in['products'].toUpperCase()}
                    </Text>
                    <Text style={Global.default.sectionSubHeader} >
                        {this.state.intro}
                    </Text>
                </View>

                <View
                    style={[styles.gridContainer, {
                        width: this.state.dimensions.window.width,
                        maxWidth: this.state.dimensions.window.width >= 1200 ? 1200 : this.state.dimensions.window.width,
                    }]}
                >
                    {
                        this.state.dimensions.window.width > 768 && (
                            <FlatList
                                key={'3cFL'}
                                scrollEnabled={true}
                                style={{
                                    width: '100%',
                                    minHeight: 300,
                                }}
                                data={this.state.items}
                                renderItem={({ item }) => this.ItemRender(item)}
                                ItemSeparatorComponent={this.Divider}
                                keyExtractor={item => item.id}
                                initialNumToRender={10}
                                numColumns={3}
                            />
                        ) || (
                            <FlatList
                                key={'2cFL'}
                                scrollEnabled={true}
                                style={{
                                    width: '100%',
                                    minHeight: 300,
                                }}
                                data={this.state.items}
                                renderItem={({ item }) => this.ItemRender(item)}
                                ItemSeparatorComponent={this.Divider}
                                keyExtractor={item => item.id}
                                initialNumToRender={10}
                                numColumns={2}
                            />
                        )
                    }
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    cardTitle: {
        textAlign: 'center',
        color: 'white',
        fontWeight: 'bold',
        paddingHorizontal: 10,
        marginBottom: 20,
        fontFamily: 'Lato_400Regular',
        textTransform: 'uppercase',
    },
    cardImage: {
        width: '100%',
        height: '50%',
    },
    gradient: {
        flex: 1,
        paddingTop: 20,
        borderRadius: 10,
        width: '100%'
    },
    card: {
        padding: 10,
        minHeight: Platform.OS == 'web' ? 420 : 250,
        alignItems: Platform.OS == 'web' ? 'normal' : 'space-between',
        justifyContent: 'flex-start',
    },
    titleContainer: {
        flexDirection: 'column',
        minHeight: 180,
        alignItems: 'center',
        width: '100%',
    },
    container: {
        marginVertical: 50,
        alignItems: 'center',
        width: '100%',
        minHeight: Platform.OS == 'web' ? 420 : 300,
    },
    gridContainer: {
        flex: 1,
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
    }
})

const mapStateToProps = (state) => {
    return {
        home: state.home,
        locale: state.locale,
    }
}

const ProductCards = connect(mapStateToProps, null)(ProductCardsContainer);
export default ProductCards;