import React, { useEffect, useState } from 'react';
import {
  ScrollView,
  View,
  Text,
  StyleSheet,
  Dimensions,
  Pressable,
  ActivityIndicator,
  FlatList,
  ImageBackground,
} from 'react-native';
import VideoCardTraning from '../components/VideoCardsTraining';
import WebinarCard from '../components/WebinarCard';
import * as Colors from '../styles/colors';
import * as Global from '../styles/global';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import * as actions from '../actions/training';
import * as fetchData from '../constants/actionTypes.js';
import Footer from '../components/Footer';
import Select from '../components/Select';

const window = Dimensions.get('window');
const screen = Dimensions.get('screen');

function TrainingSection(props) {
  const locale = useSelector((state) => state.locale)
  const [trainingReady, setTrainingReady] = React.useState(false);
  const [training, setTraining] = React.useState({
    webinars: [],
    videos: [],
    intro: '',
    page: 1,
    length: 0,
  });
  const [dimensions, setDimensions] = useState({ window, screen });
  const columnsCards = dimensions.window.width > 1450 ? 4 : 3;
  const [webinarsToShow, setWebinarsToShow] = React.useState(columnsCards);
  const [webinarValue, setWebinarValue] = React.useState('');
  const [videoFilterValue, setVideoFilterValue] = React.useState('');
  const [videoOrderValue, setVideoOrderValue] = React.useState('');

  const getTrainingWebinar = async (type, data = 'date') => {
    data = {
      order: data,
      locale: locale
    };

    await actions
      .getTrainingData({
        type: type,
        data: data,
      })
      .then(res => {
        if (res.type == fetchData.FETCHING_TRAINING_SUCCESS) {
          props.setTraining(res.trainingData);
          setTrainingReady(true);
          setTraining(prevTraining => ({
            ...prevTraining,
            webinars: res.trainingData?.items,
            length: res.trainingData?.items?.meta?.total_count,
          }),
          );
        }
      }).catch(err => {
        console.log(err);
      });
  };

  const getTrainingVideo = async (type, data = 'date') => {

    //console.log(videoOrderValue);

    const ORDER = videoOrderValue && videoOrderValue?.value !== '' ? videoOrderValue.value : 'title';

    data = {
      filter: 'isLearning=true',
      order: ORDER,
      locale: locale
    };

    //console.log('getTrainingVideo',data);

    switch (videoFilterValue.value) {
      case 'training':
        data = {
          filter: 'isLearning=true',
          order: ORDER,
          locale: locale
        };
        break;
      case 'reviews':
        data = {
          filter: 'isReview=true',
          order: ORDER,
          locale: locale
        };
        break;
      case 'products':
        data = {
          filter: 'isProduct=true',
          order: ORDER,
          locale: locale
        };
        break;
    }

    await actions
      .getTrainingData({
        type: type,
        data: data
      })
      .then(res => {
        if (res.type == fetchData.FETCHING_TRAINING_SUCCESS) {
          props.setTraining(res.trainingData);
          setTrainingReady(true);
          setTraining(prevTraining => ({
            ...prevTraining,
            videos: res.trainingData?.items,
          }),
          );
        }
      }).catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    async function getTraining() {
      await actions
        .getTraining({
          locale: locale,
          offset: 0
        })
        .then(res => {
          if (res.type == fetchData.FETCHING_TRAINING_SUCCESS) {
            props.setTraining(res.trainingData);
            setTrainingReady(true);
            setTraining(prevTraining => ({
              ...prevTraining,
              webinars: res.trainingData.items[0].webinars.pages,
              videos: res.trainingData.items[0].videos.pages,
              intro: res.trainingData.items[0].webinars.intro,
              length: res.trainingData.items[0].videos.pages.length,
            }),
            );
          }
        }).catch(err => {
          console.log(err);
        });
    }

    getTraining().then();
  }, []);

  function showMore() {
    setWebinarsToShow(training.webinars.length);
  }

  function showLess() {
    setWebinarsToShow(columnsCards);
  }

  function Divider() {
    return (
      <View style={{ marginTop: 20 }}></View>
    );
  }

  function onEnd() {
    const pagesize = 20;
    const page = training.page + 1;
    const offset = (page - 1) * pagesize;
    const limit = pagesize * page;

    if (training.videos.length > offset) {
      actions.getTraining({
        locale: locale,
        offset: offset,
      }).then(res => {
        if (res.type == fetchData.FETCHING_TRAINING_SUCCESS) {
          setTraining(prevTraining => {
            return {
              ...prevTraining,
              items: training.videos.concat(res.trainingData.items[0].videos.pages),
              length: res.trainingData.items[0].videos.pages.length,
              page,
            };
          });
        }
      }).catch(err => {
        console.log(err);
      });
    }
  }

  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ window, screen }) => {
      setDimensions({ window, screen });
    });
    subscription?.remove();
  });

  const dataCboWebinars = [
    { label: 'Fecha ↑', value: 'date' },
    { label: 'Fecha ↓', value: '-date' },
    { label: 'Título ↑', value: 'title' },
    { label: 'Título ↓', value: '-title' },
  ];
  const onChangeWebinars = (value) => {
    setWebinarValue(value);
  };

  const dataCboVideosFilter = [
    { label: 'Capacitación', value: 'training' },
    { label: 'Reviews', value: 'reviews' },
    { label: 'Productos', value: 'products' },
  ];
  const onChangeVideosFilter = (value) => {
    setVideoFilterValue(value);
  };
  const dataCboVideosOrder = [
    { label: 'Título ↑', value: 'title' },
    { label: 'Título ↓', value: '-title' },
  ];
  const onChangeVideosOrder = (value) => {
    setVideoOrderValue(value);
  };

  useEffect(() => {
    if (videoFilterValue || videoOrderValue) {
      getTrainingVideo('getFilterVideo', 'newValue.value').then();
    }
  }, [videoFilterValue, videoOrderValue]);

  return (
    trainingReady ? (
      <ScrollView>
        <ImageBackground source={require('../../assets/FONDO_TEXTURA_BENQ.jpg')}>
          <View style={Global.default.sectionContainer}>
            {/*
                Platform.OS === 'web' && dimensions.window.width > 667 && (
                  <View style={Global.default.breadcrumbs}>
                    <Text style={Global.default.breadCrumbitem}>{locale.in['home']} / </Text>
                    <Text style={Global.default.breadCrumbitem}>{locale.in['training']}</Text>
                  </View>
                )
              */}
            <Text style={Global.default.sectionTitle}>{locale.in['training']} / Webinars</Text>
            <Text style={[styles.paragraph, Global.default.sectionSubTitle]}>
              {training.intro}
            </Text>
            <View>
              <Text style={[Global.default.sectionSubtitleBlack, Global.default.boldFont]}>{locale.in['upcoming']} webinars</Text>
              <View style={[Global.default.row, { flexWrap: 'wrap' }]}>
                {/*<Text style={[Global.default.sectionSubtitleBlack, Global.default.boldFont]}>Webinars</Text>*/}
                <Select
                  name={'cboWebinars'}
                  label={'Ordenar por'}
                  onValueChange={(newValue) => {
                    onChangeWebinars(newValue);
                    if (newValue.value) {
                      getTrainingWebinar('getOrderWebinar', newValue.value).then();
                    }
                  }}
                  dataItems={dataCboWebinars}
                  value={webinarValue}
                />
              </View>

              <View style={styles.webinarsContainer}>
                {
                  training.webinars.length > 0 ?
                    training.webinars.slice(0, webinarsToShow).map(item => {
                      return (
                        <WebinarCard key={String(item.id)} webinar={item} />
                      );
                    })
                    :
                    <View style={{ flex: 1, justifyContent: 'center', alignContent: 'center' }}>
                      <Text>{locale.in['no_webinars_found']}</Text>
                    </View>
                }
              </View>
              {
                (webinarsToShow === columnsCards && training.webinars.length > columnsCards) ? (
                  <Pressable
                    onPress={showMore}
                  >
                    <Text style={styles.showMore}>{locale.in['see_more']}</Text>
                  </Pressable>
                ) : (webinarsToShow > columnsCards) ? (
                  <Pressable
                    onPress={showLess}
                  >
                    <Text style={styles.showMore}>{locale.in['see_less']}</Text>
                  </Pressable>
                ) : null
              }
            </View>

            <View style={{ marginVertical: 10 }}>
              <View style={[Global.default.row, { flexWrap: 'wrap' }]}>
                <Text style={[Global.default.sectionSubtitleBlack, Global.default.boldFont]}>Videos</Text>
                <Select
                  name={'cboVideosFilter'}
                  label={'Filtrar por'}
                  onValueChange={(newValue) => {
                    onChangeVideosFilter(newValue);
                    /*if (newValue.value) {
                      getTrainingVideo('getFilterVideo', newValue.value).then();
                    }*/
                  }}
                  dataItems={dataCboVideosFilter}
                  value={videoFilterValue}
                />
                <Select
                  name={'cboVideosOrder'}
                  label={'Ordenar por'}
                  onValueChange={(newValue) => {
                    onChangeVideosOrder(newValue);
                  }}
                  dataItems={dataCboVideosOrder}
                  value={videoOrderValue}
                />
              </View>

              <View style={{ marginTop: 15 }}>
                <View style={styles.videosContainer}>
                  {
                    dimensions.window.width < 667 ?
                      training.videos.length ?
                        <FlatList
                          style={{ marginTop: 40 }}
                          data={training.videos}
                          renderItem={({ item }) => <VideoCardTraning video={item} />}
                          ItemSeparatorComponent={Divider}
                          keyExtractor={item => '_' + String(item.id)}
                          numColumns={1}
                          onEndReached={onEnd}
                          onEndThreshold={0.5}
                          listKey={667}
                          key={'_'}
                          initialNumToRender={10}
                        />
                        :
                        <View style={{ flex: 1, justifyContent: 'center', alignContent: 'center' }}>
                          <Text>{locale.in['no_videos_found']}</Text>
                        </View>
                      : dimensions.window.width < 1025 ?
                        training.videos.length ?
                          <FlatList
                            style={{ marginTop: 40 }}
                            data={training.videos}
                            renderItem={({ item }) => <VideoCardTraning video={item} />}
                            ItemSeparatorComponent={Divider}
                            keyExtractor={item => '-' + String(item.id)}
                            numColumns={2}
                            onEndReached={onEnd}
                            onEndThreshold={0.5}
                            listKey={1025}
                            key={'-'}
                            initialNumToRender={10}
                          />
                          :
                          <View style={{ flex: 1, justifyContent: 'center', alignContent: 'center' }}>
                            <Text>{locale?.in['no_videos_found']}</Text>
                          </View>
                        : training.videos.length ?
                          <FlatList
                            columnWrapperStyle={{ flex: 1, justifyContent: "center" }}
                            style={{ marginTop: 40 }}
                            data={training.videos}
                            renderItem={({ item }) => <VideoCardTraning video={item} />}
                            ItemSeparatorComponent={Divider}
                            keyExtractor={item => '#' + String(item.id)}
                            numColumns={3}
                            onEndReached={onEnd}
                            onEndThreshold={0.5}
                            listKey={1400}
                            key={'#'}
                            initialNumToRender={10}
                          />
                          :
                          <View style={{ flex: 1, justifyContent: 'center', alignContent: 'center' }}>
                            <Text>{locale.in['no_videos_found']}</Text>
                          </View>
                  }
                </View>
              </View>
            </View>
          </View>
          <Footer {...props} />
        </ImageBackground>
      </ScrollView>
    )
      : (
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <ActivityIndicator size="large" color={Colors.PURPLE} />
        </View>
      )
  );
}

const { width: WIDTH, height: HEIGHT } = Dimensions.get('window');
const styles = StyleSheet.create({
  bcontainter: {
    width: WIDTH, //for full screen
    height: HEIGHT, //for full screen
  },
  container: {
    marginHorizontal: 'auto',
    width: WIDTH >= 767 ? '70%' : WIDTH,
    paddingBottom: 40,
  },
  title: {
    fontSize: WIDTH >= 767 ? 32 : 18,
    fontWeight: 'bold',
    marginVertical: 10,
    textTransform: 'uppercase',
  },
  paragraph: {
    color: Colors.GRAY,
    fontWeight: '500',
    lineHeight: 18,
    marginVertical: 6,
    fontSize: WIDTH >= 767 ? 18 : 14,
  },
  chooseSection: {
    marginHorizontal: 10,
    textTransform: 'uppercase',
    fontSize: 14,
  },
  activeSection: {
    color: Colors.PURPLE,
    fontWeight: 'bold',
  },
  webinarsContainer: {
    maxWidth: Dimensions.get('window').width,
    alignItems: 'center',
    flexGrow: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginVertical: 15,
  },
  showMore: {
    color: Colors.PURPLE,
    textTransform: 'uppercase',
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: Global.default.boldFont.fontFamily,
    textAlign: 'right',
    marginEnd: 15,
  },
  picker: {
    borderColor: Colors.GRAY,
    backgroundColor: 'transparent',
    borderRadius: 25,
    borderWidth: 2,
    height: 40,
    marginVertical: 5,
    padding: 10,
    paddingHorizontal: 17,
    width: Dimensions.get('window').width > 667 ? '200px' : '100%',
  },
  videosContainer: {
    maxWidth: Dimensions.get('window').width,
    alignItems: 'center',
    flexGrow: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});

const mapStateToProps = (state) => {
  return {
    training: state.training,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setTraining: (data) => {
      return dispatch(actions.setTraining(data));
    },
  };
};
const Training = connect(mapStateToProps, mapDispatchToProps)(TrainingSection);

export default Training;
