import React,{} from 'react';
import {
    Image,
    Pressable,
    Platform,
    Dimensions,
    StyleSheet
} from 'react-native';
import { BASE_URL } from "../constants/endPoints";
import { connect }  from 'react-redux';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");
class AssistantBannerContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items:[],
            mobile_items:[],
            componentReady: false,
            dimensions: {
                window,
                screen
            }
        }
    }
    onChange = ({ window, screen }) => {
        this.setState({ dimensions: { window, screen } });
    }
    componentDidMount(){
        if(this.props.home && this.props.home.homeData && this.props.home.homeData.items){
            this.setState({
                assistant_image:   this.state.dimensions.window.width >= 768 ? this.props.home.homeData.items[0].assistant_image : this.props.home.homeData.items[0].assistant_mobile_image,
                componentReady: true
            })
            //console.log( this.state );
        }
        this.dimensionsSubscription = Dimensions.addEventListener("change", this.onChange);
    }
    componentWillUnmount() {
        this.dimensionsSubscription?.remove();
    }
    render() {
        if(this.state.componentReady && this.state.assistant_image){ 
            return (
                <Pressable
                    onPress={() => this.props.navigation.navigate('Asistente')}
                    style={{
                        width:  this.state.dimensions.window.width,
                        marginVertical: 50,
                        alignItems: 'center',
                    }}
                >
                    <Image
                        resizeMode="contain"
                        source={{
                            uri:  BASE_URL + this.state.assistant_image.url
                        }}
                        style={{
                            width:   this.state.dimensions.window.width,
                            height:  this.state.dimensions.window.width  > 768 ?
                                     this.state.dimensions.window.width * 0.5 :  560,
                        }}
                    />
                </Pressable>
            )
        }
        else{
            return (
                <></>
            )
        }
    }
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
      position: Platform.OS === 'web' ? 'fixed' : 'absolute',
      width: '100%',
      height: Platform.OS === 'web' ? '500px' : 500,
      top:0
    }
})

const mapStateToProps = (state) => {
    return {
        home: state.home
    }
}

const AssistantBanner = connect(mapStateToProps,null)(AssistantBannerContainer);
export default AssistantBanner;