import * as React from 'react';
import {View, Text, ScrollView, ActivityIndicator, ImageBackground} from 'react-native';
import {PromotionCard} from '../components/PromotionCard';
import {useSelector} from 'react-redux';
import {connect} from 'react-redux';
import * as actions from '../actions/promotions';
import * as F from '../constants/actionTypes.js';
import * as Colors from '../styles/colors';
import * as Global from '../styles/global';
import Footer from '../components/Footer';

function PromotionsSection(props) {
  const locale = useSelector( (state) => state.locale)
  const [promotionsReady, setPromotionReady] = React.useState(false);

  React.useEffect(() => {
    async function getPromos() {
      await actions
      .getPromotions({
        locale: locale,
      })
      .then(res => {
        if ( res.type == F.FETCHING_PROMOTION_SUCCESS ) {
          props.setPromotion(res.promotionData);
          setPromotionReady(true);
        }
      }).catch(err => {
        console.log(err);
      });
    }

    getPromos().then(r => {});
  }, []);

  return (
    promotionsReady ? (
        <ScrollView>
          <ImageBackground source={require('../../assets/FONDO_TEXTURA_BENQ.jpg')}>
            <View style={Global.default.sectionContainer}>
              <Text style={Global.default.sectionTitle}>{locale.in['promotions'].toUpperCase()}</Text>
              <Text style={Global.default.sectionSubTitle}>
                {locale.in['promotions_paragraph']}
              </Text>

              <View style={{flex: 1}}>
                {
                  props.promotions.promotionData.items ?
                    props.promotions.promotionData.items.map((promotion, index) => {
                      return (
                        <PromotionCard
                          key={`promotion-${index}`}
                          promotion={promotion}
                        />
                      );
                    })
                    :
                    <Text>{locale.in['no_promotions_found']}</Text>
                }
              </View>
            </View>
            <Footer {...props}/>
          </ImageBackground>
        </ScrollView>
      )
      :
      <View style={{flex: 1, justifyContent: 'center'}}>
        <ActivityIndicator size="large" color={Colors.PURPLE}/>
      </View>
  );
}

const mapStateToProps = (state) => {
  return {
    promotions: state.promotion,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setPromotion: (data) => {
      return dispatch(actions.setPromotion(data));
    },
  };
};
const Promotions = connect(mapStateToProps, mapDispatchToProps)(PromotionsSection);

export default Promotions;
