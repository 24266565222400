import { Dimensions, Platform, StyleSheet } from 'react-native';
import * as Colors from '../../styles/colors';
import globalStyles from '../../styles/global';
const { width: WIDTH, height: HEIGHT } = Dimensions.get('window');

export const styles = StyleSheet.create({
  containerPrincipal: {
    position: 'relative',
    width: Platform.OS === 'web' ? WIDTH > 667 ? '250px' : WIDTH - 20 : (WIDTH - 20),
    height: Platform.OS === 'web' ? WIDTH > 667 ? '40px' : 40 : 40,
    borderColor: Colors.GRAY,
    backgroundColor: 'transparent',
    borderRadius: 25,
    borderWidth: 2,
    marginVertical: 5,
    // paddingHorizontal: 17,
    // cursor: 'pointer',
    overflow: 'hidden',
  },
  containerText: {
    position: 'absolute',
    width: Platform.OS === 'web' ? WIDTH > 667 ? '230px' : WIDTH - 20 : (WIDTH - 20),
    height: Platform.OS === 'web' ? WIDTH > 667 ? '40px' : 40 : 40,
    top: -2,
    paddingTop: 10,
    paddingLeft: 15,
    fontFamily: globalStyles.titleFont.fontFamily,
    fontSize: 15,
    color: Colors.PURPLE,
    zIndex: -1,
    elevation: -1,
    display: 'flex',
    // backgroundColor: 'red',
  },
  containerTextFocus: {
    display: 'none',
  },
  labelText: {
    color: Colors.PURPLE,
  },
  containerSelect: {
    flex: 1,
    position: 'relative',
    // width: WIDTH > 667 ? '100%' : WIDTH - 20,
    height: Platform.OS === 'web' ? WIDTH > 667 ? '40px' : 40 : 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    // backgroundColor: 'yellow',
    zIndex: 1,
    elevation: 1,
  },
  select: {
    position: 'absolute',
    width: Platform.OS === 'web' ? WIDTH > 667 ? '100%' : WIDTH - 20 : (WIDTH - 20),
    height: Platform.OS === 'web' ? WIDTH > 667 ? '40px' : 40 : 40,
    top: Platform.OS === "web" ? '-22px' : -20,
    paddingTop: 3,
    paddingLeft: 15,
    textAlign: 'left',
    fontFamily: globalStyles.titleFont.fontFamily,
    fontSize: 15,
    color: Colors.PURPLE,
    zIndex: 1,
    elevation: 1,
    // backgroundColor: 'red',
  },
  selectTextSelected: {
    fontFamily: globalStyles.titleFont.fontFamily,
    fontSize: 15,
    color: Colors.PURPLE,
  },
  containerIco: {
    flex: 0.1,
    position: 'relative',
    width: Platform.OS === 'web' ? '12px' : 12,
    height: Platform.OS === 'web' ? '12px' : 12,
    top: Platform.OS === 'web' ? '-13px' : -13,
    right: Platform.OS === 'web' ? '15px' : 20,
    alignSelf: 'flex-end',
    zIndex: 0,
    elevation: 0,
    // backgroundColor: 'green',
  },
  fileIco: {
    position: 'relative',
    width: Platform.OS === 'web' ? '12px' : 12,
    height: Platform.OS === 'web' ? '12px' : 12,
    alignSelf: 'flex-end',
    zIndex: 0,
    // backgroundColor: 'green',
  },
  containerIcoText: {
    position: 'absolute',
    width: Platform.OS === 'web' ? '30px' : 30,
    height: Platform.OS === 'web' ? '30px' : 30,
    top: 8,
    right: 10,
    alignSelf: 'flex-end',
    zIndex: 0,
    elevation: 0,
    // backgroundColor: 'green',
  },
});


