import {API_URL, AUTH_TOKEN, ENV} from '../constants/endPoints';

export default (method, request) => {

    let appHeaders = new Headers();
    appHeaders.append('Content-Type', 'application/json');
    appHeaders.append('Authorization', AUTH_TOKEN);
    
    if(ENV==='dev'){console.log(method, request);}

    if (method === 'getVideos') {
        //console.log(`${API_URL}pages/?type=video.VideoPage&fields=*&offset=${request.offset}&locale=${request.locale.country.language}`);
        return fetch(`${API_URL}pages/?type=video.VideoPage&order=-id&fields=*&offset=${request.offset}&locale=${request.locale.country.language}`, {
          method: 'GET',
          headers: appHeaders,
          cache: 'default',
        })
        .then(
            (response) => Promise.all([response, response.json()])
        )
        .catch( (error) => {
          console.log(error);
        })
    }
    
};
