import * as React from 'react';
import { 
  View, 
  StyleSheet, 
  ImageBackground, 
  Text, 
  Pressable, 
  TextInput, 
  Platform, 
  ActivityIndicator, 
  Dimensions
} from 'react-native';
import {useSelector} from 'react-redux';
import * as Colors from '../styles/colors';
import globalStyles from '../styles/global';

import { connect }  from 'react-redux';
import * as actions from '../actions/profile';
import * as RootNavigation from '../rootNavigation';

import AsyncStorage from '@react-native-async-storage/async-storage';
import Toast from "react-native-toast-notifications";
import { ScrollView } from 'react-native-gesture-handler';
import Footer from '../components/Footer';

function LoginSection({ navigation, setProfile }) {
  const locale = useSelector( (state) => state.locale)
  const toastRef = React.useRef();
  const [loading, setLoading] = React.useState(false);
  const [userData, setUserData] = React.useState({
    username: '',
    password: '',
    profile: ''
  });

  const setProfileToStorage = async (profile) => {
    const { is_user, user_id, is_active, country} = profile ;
    await AsyncStorage.setItem('profile', JSON.stringify({is_user, user_id, is_active, country, profile}))
    setTimeout(() => {
      RootNavigation.navigate('Home');
    }, 1000);
  }

  const handleLogin = async () => {
    const { username, password, profile } = userData;
    
    if (!username || !password /*|| !profile*/) {
      toastRef.current.show(locale.in['all_fields_are_required'], { type: 'danger', duration: 3000, placement: 'bottom' });
      return;
    }
    if( /\S+@\S+\.\S+/.test(username) === false) {
      toastRef.current.show(locale.in['you_have_to_put_a_valid_email'], { type: 'danger', duration: 3000, placement: 'bottom' });
      return;
    }

    setLoading(true);

    await actions
      .loginUser({ username: username.toLowerCase().trim(), password: password.trim() })
      .then(res => {
        if (res.type === 'FETCHING_PROFILE_SUCCESS' && res.profileData.is_user) {
          setProfile(res.profileData);

          const token = AsyncStorage.getItem('PushToken').then(async token => {
            if (token) {
              await actions.updateDeviceToken({ 
                token: token, 
                id: res.profileData.user_id ,
                device_type: Platform.OS
              });
            }
          })
          setLoading(false);
          setProfileToStorage(res.profileData);
        } else {
          toastRef.current.show(locale.in['user_or_password_incorrect'], { type: 'danger', duration: 3000, placement: 'bottom' });
          setLoading(false);
          setUserData({
            username: '',
            password: '',
            profile: ''
          })
        }
      })
      .catch(err => {
        console.log(err);
      }
    );
  }

  return (
    <ScrollView>
    <ImageBackground source={require('../../assets/FONDO_TEXTURA_BENQ.jpg')}>
      <View style={styles.centeredView}>
        <Toast ref={toastRef} />
        <View style={[styles.centeredView, {
            backgroundColor: 'rgba(252, 252, 252, 1)',
            marginVertical: 50,
            padding: 50,
            borderRadius: 10,
            maxWidth: 600,
            width: '100%'
          }]}>
          <Text style={{
            fontSize: 30,
            fontFamily: 'Lato_400Regular',
            marginBottom: 20,
          }}>{locale.in['sign_in'].toUpperCase()}</Text>
          <Text style={{textAlign:'center',color:Colors.GRAY}}>
            {locale.in['login_paragraph']}
          </Text>
          <TextInput 
            autoCorrect={false} 
            autoCapitalize="none"
            style={[styles.formInput, {marginTop: 50}]}
            placeholder={locale.in['email']}
            onChange={async (text) => {
              await setUserData({...userData, username: text.nativeEvent.text})
            }}
            value={userData.username}
            keyboardType="email-address"
          />
          <TextInput
            autoCorrect={false} 
            autoCapitalize="none"
            style={[styles.formInput]}
            placeholder={locale.in['password']}
            onChange={(text) => setUserData({...userData, password: text.nativeEvent.text})}
            value={userData.password}
            secureTextEntry
            onKeyPress={(event)=>{
              if (event.key === 'Enter') {
                handleLogin();
              }
            }}
          />
          <Pressable style={[styles.button,
           styles.buttonEnter,
           globalStyles.row]}
           onPress={handleLogin}
           disabled={loading}
           >
            <Text style={[styles.textStyle,{color:Colors.WHITE}]}>Entrar</Text>
            {
              loading && (
                <ActivityIndicator size="small" color={Colors.WHITE} style={{marginStart: 10}} />
              )
            }
          </Pressable>
        </View>
      </View>

      <View style={{marginBottom:50}}>
        <Pressable
          style={[styles.button, {paddingBottom: 0, backgroundColor: 'transparent'}]}
          onPress={() => {navigation.navigate('RecuperarPassword')}}
        >
          <Text style={[styles.textStyle, {fontWeight: '400'}]}>{locale.in['forgot_your_password']}</Text>
        </Pressable>
        <Pressable
          style={[styles.button]}
          onPress={() => {navigation.navigate('Registro')}}
        >
          <Text style={[styles.textStyle, {fontWeight: '400'}]}>
            {locale.in['not_have_an_account']} {""}
            <Text style={[globalStyles.textBold, {marginLeft: 8}]}>{locale.in['sign_up_here'].toUpperCase()}</Text>
          </Text>
        </Pressable>
      </View>

    <Footer {...{navigation}} />
    </ImageBackground>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    borderRadius: 20,
    padding: 10
  },
  buttonEnter: {
    backgroundColor: Colors.PURPLE,
    width: Dimensions.get('window').width > 667 ? '50%' : '100%',
    textAlign: 'center',
    marginTop: 50,
    justifyContent: 'center',
    elevation: 2
  },
  textStyle: {
    fontWeight: "bold",
    textAlign: "center",
    //textTransform: 'uppercase',
    color: Colors.GRAY
  },
  formInput: {
    borderColor: Colors.LIGTH_GRAY,
    borderRadius: 25,
    borderWidth: 2,
    marginVertical: 5,
    //textTransform: "uppercase",
    padding: 10,
    paddingHorizontal: 17,
    color: Colors.GRAY,
    width: '100%',
    backgroundColor: 'transparent'
  },
  selectControlArrowImageStyle:{
    marginTop : Platform.OS == 'web' ? 0 : 15,
    tintColor: '#fff'
  },
  selectControlStyle:{
    borderRadius: 25,
    borderBottomLeftRadius: 25,
    borderBottomRightRadius: 25,
    backgroundColor: 'transparent',
    borderColor: Colors.WHITE,
    padding: 10
  },
  optionsListStyle:{
    position: Platform.OS == 'web' ? 'fixed' : 'absolute',
    borderColor: '#fff',
    marginTop: Platform.OS == 'web' ? 0 : -40
  }
});

const mapStateToProps = (state) => {
  return {
      profile: state.profile
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
      setProfile: (data) => {
          return dispatch(actions.setProfile(data))
      }
  }
}
const Login = connect(mapStateToProps, mapDispatchToProps)(LoginSection);

export default Login;