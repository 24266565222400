import {
  API_URL,
  AUTH_TOKEN,
  ENV
} from '../constants/endPoints';

export default async (method, request) => {

  let appHeaders = new Headers();
  appHeaders.append('Content-Type', 'application/json');
  appHeaders.append('Authorization', AUTH_TOKEN);

  if (ENV === 'dev') {
    console.log(method, request);
  }

  if (method === 'getDistributors') {
    try {
      if(!request){request={}}
      if(!request.country){request.country=''}
      if(!request.state){request.state=''}
      const response = await fetch(`${API_URL}pages/?type=dealers.DealerPage&fields=*&country=${request.country}&state=${request.state}`, {
        method: 'GET',
        headers: appHeaders,
        cache: 'default',
      });
      return await Promise.all([response, response.json()]);
    } catch (error) {
      console.log(error);
    }
  }
  if (method === 'getCountries') {
    try {
      const response = await fetch(`${API_URL}countries/?fields=*`, {
        method: 'GET',
        headers: appHeaders,
        cache: 'no-cache',
      });
      return await Promise.all([response, response.json()]);
    } catch (error) {
      console.log(error);
    }
  }
  if (method === 'getStates') {
    try {
      const response = await fetch(`${API_URL}states/?country=${request}&limit=50`, {
        method: 'GET',
        headers: appHeaders,
        cache: 'default',
      });
      return await Promise.all([response, response.json()]);
    } catch (error) {
      console.log(error);
    }
  }

};