import {
  FETCHING_DISTRIBUTOR_PENDING,
  FETCHING_DISTRIBUTOR_SUCCESS,
  FETCHING_DISTRIBUTOR_FAILURE
} from '../constants/actionTypes';

const initialState = {
  distributorIsFetching: false,
  distributorError: false,
  distributorData: {}
}

const distributor = (state = initialState, action) => {

  switch (action.type) {

    case 'SET_DISTRIBUTOR':
      return {
        distributorData: action.payload,
          distributorIsFetching: false,
          distributorError: false
      };


      //Inicio de casos para API
    case FETCHING_DISTRIBUTOR_PENDING:
      return {
        distributorData: {},
          distributorIsFetching: true,
          distributorError: false
      }
      case FETCHING_DISTRIBUTOR_SUCCESS:
        return {
          distributorData: action.data,
            distributorIsFetching: false,
            distributorError: false
        }
        case FETCHING_DISTRIBUTOR_FAILURE:
          return {
            distributorData: {},
              distributorError: true,
              distributorIsFetching: false
          }
          default:
            return state
  }
}

export default distributor;