import { combineReducers } from 'redux';

import producto       		from '../reducers/producto'
import home       		    from '../reducers/home'
import promotion       		from '../reducers/promotions'
import distributor          from '../reducers/distributors'
import training             from '../reducers/training'
import categories           from '../reducers/categories'
import profile              from '../reducers/profile'
import locale              from '../reducers/locale'


let store = combineReducers(
    { 
        products: producto,
        home: home,
        promotion: promotion,
        distributor: distributor,
        training: training,
        categories: categories,
        profile: profile,
        locale: locale
    }
);
export default store;