import React from "react";
import {
  Dimensions,
  Platform,
  Text,
  View,
  StyleSheet
} from "react-native";
import { WebView as WebViewWeb }  from "react-native-web-webview";
import { WebView as WebViewMobile}  from "react-native-webview";
import youtube_parser from "../utils/extractYoutube";

import * as Colors from "../styles/colors";
import globalStyles from '../styles/global';
import SaveAndShare from "./saveandshare/SaveAndShare";
import stripTags from '../utils/stripTags';

class VideoCardTraning extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { video } = this.props;

    return (
      <View style={styles.card}>
        <View style={styles.webViewContainer}>
          { Platform.OS == "web" ?
            <WebViewWeb
              style={{width: "100%" }}
              javaScriptEnabled={true}
              domStorageEnabled={true}
              source={{ uri: `https://www.youtube.com/embed/${youtube_parser(video.url)}` }}
            /> :
            <WebViewMobile
              style={{width: '100%' }}
              javaScriptEnabled={true}
              domStorageEnabled={true}
              source={{ uri: `https://www.youtube.com/embed/${youtube_parser(video.url)}`}}
            />
          }
          <SaveAndShare item={video} options={{share: true,save: true, download: false,relative:true}} />
        </View>
        <View>
          <Text style={[styles.category, globalStyles.boldFont]}>
            {video.category}
          </Text>
          <Text
            numberOfLines={2} 
            style={[styles.title, globalStyles.boldFont]} >
            {video.title}
          </Text>
          <Text
            numberOfLines={4}
            style={[styles.description, globalStyles.titleFont]}
          >
            {stripTags(video.desc)}
          </Text>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  card: {
    backgroundColor: Colors.LIGTH_GRAY,
    width:
      Dimensions.get("window").width < 767 ? '100%'
        : Platform.OS == "web" ? Dimensions.get("window").width > 1024 ? "30%" : 
          Dimensions.get("window").width > 768 ? "48%" : "100%"
        : Dimensions.get("window").width - 20,
    height:
      Platform.OS == "web"
        ? "550px"
        : Dimensions.get("window").width * 1.3,
    margin: Platform.OS == "web" && Dimensions.get('window').width > 667
      ? "10px"
      : Dimensions.get('window').width > 667
      ? 10 : 0,
    alignItems: "center",
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 1,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
  },
  webViewContainer: {
    width: "100%",
    height: "55%",
    backgroundColor: Colors.BLACK,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    overflow: 'hidden'
  },
  category: {
    color: Colors.PURPLE,
    fontSize: Platform.OS == "web" ? "18px" : 18,
    paddingHorizontal: Platform.OS == "web" ? "20px" : 20,
    marginTop: 30,
  },
  title: {
    color: Colors.BLACK,
    fontSize: Platform.OS == "web" ? "25px" : 25,
    paddingHorizontal: Platform.OS == "web" ? "20px" : 20,
    marginTop: 0,
  },
  description: {
    color: Colors.GRAY,
    fontSize: Platform.OS == "web" ? "18px" : 18,
    paddingHorizontal: Platform.OS == "web" ? "20px" : 20,
    marginTop: 10
  }
});

export default VideoCardTraning;
