import React,{} from 'react';
import {
    ScrollView,
    View,
    Text,
    ImageBackground,
    Image,
    Pressable,
    Platform,
    Dimensions,
    FlatList,
    TouchableOpacity,
    StyleSheet,
} from 'react-native';

import { connect }  from 'react-redux';
import * as Colors from '../styles/colors';

import * as actions from '../actions/videos';
import * as F  from '../constants/actionTypes.js';
import { BASE_URL } from "../constants/endPoints";
import stripTags from '../utils/stripTags';
import youtube_parser from '../utils/extractYoutube';

import Footer from "../components/Footer";
import { WebView as WebViewWeb }  from "react-native-web-webview";
import { WebView as WebViewMobile}  from "react-native-webview";
import SaveAndShare from '../components/saveandshare/SaveAndShare';

class VideosContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videos: [],
      length: 0,
      intro: '',
      page: 1,
    };
  }
  async componentDidMount(){
    await actions.getVideos({
      locale: this.props.locale,
      offset: 0
    }).then(res => {
      if(res.type == F.FETCHING_VIDEOS_SUCCESS){
        //console.log(res.contentData)
        this.setState({
          intro: res.contentData.items ? res.contentData.items[0].intro : '',
          videos: res.contentData.items,
          length: res.contentData.meta.total_count,
        });
      }
    }).catch(err => {
      console.log(err);
    });
  }
  Header = () => {
    return (
      <View style={styles.header}>
        <Text style={{fontSize: 60, marginBottom: 20}}>
          VIDEOS
        </Text>
        <Text style={{color: 'gray', fontSize: 25, textAlign: 'center'}}>
          {this.state.intro}
        </Text>
      </View>
    );
  };
  Divider = () => {
      return (
          <View style={{marginTop:20}}>
          </View>
      );
  }
  ItemRender = (item) => {
      return (
          <View style={styles.itemContainer}>
            <View style={styles.item}>
            {
              Platform.OS == "web" && (
                <WebViewWeb
                  style={{width: "100%" }}
                  javaScriptEnabled={true}
                  domStorageEnabled={true}
                  source={{ uri: `https://www.youtube.com/embed/${youtube_parser(item.url)}` }}
                />
              ) 
              || (
                <WebViewMobile
                  style={{width: Dimensions.get('window').width }}
                  javaScriptEnabled={true}
                  domStorageEnabled={true}
                  source={{ uri: `https://www.youtube.com/embed/${youtube_parser(item.url)}`}}
                />
              )
            }
            </View>
            <SaveAndShare item={item} options={{share:true,save:true,download:false,relative:true}} />
            <View>
              <Text style={styles.itemCategory}>{item.category}</Text>
              <Text numberOfLines={2} style={styles.itemTitle}>{item.title}</Text>
              <Text numberOfLines={4} style={styles.itemDescription}>{stripTags(item.desc)}</Text>
            </View>
          </View>
      )
  }
  onEnd = () => {
    const pagesize = 20;
    const page = this.state.page + 1;
    const offset = (page - 1) * pagesize;
    const limit = pagesize * page;

    //console.log('offset =>', offset);
    //console.log('limit =>', limit);

    if (this.state.length > offset) {
      actions.getVideos({
        locale: this.props.locale,
        offset: offset
      }).then(res => {
        if(res.type == F.FETCHING_VIDEOS_SUCCESS){
          //console.log(res.contentData)
          this.setState({
            videos: this.state.videos.concat(res.contentData.items),
            length: res.contentData.meta.total_count,
            page: page,
          });
        }
      }).catch(err => {
        console.log(err);
      });
    }

  };
  render() {
      return (
      <View style={styles.container}
        horizontal={true}
      >
        <FlatList
            style={{
              height: Dimensions.get('window').height,
              width: Dimensions.get('window').width,
            }}
            contentContainerStyle={{alignItems: 'center'}}
            ListHeaderComponent={this.Header}
            ListFooterComponent={<Footer {...this.props} />}
            ListHeaderComponentStyle={{alignItems:'center', width: Dimensions.get('window').width,marginBottom: 50}}
            ListFooterComponentStyle={{marginTop: 30, width: '100%'}}
            data={this.state.videos}
            renderItem={({ item }) => this.ItemRender(item)}
            ItemSeparatorComponent={this.Divider}
            keyExtractor={item => item.id}
            numColumns={Dimensions.get('window').width < 767 ? 1 : 2}
            initialNumToRender={10}
            onEndReached={this.onEnd}
        />
        </View>
    );
  }
}

const styles = StyleSheet.create({
  itemDescription:{
    color: Colors.GRAY,
    fontSize: Platform.OS == "web" ? "18px" : 18,
    paddingHorizontal: Platform.OS == "web" ? "20px" : 20,
    marginTop: 10,
  },
  itemTitle:{
    color: Colors.BLACK,
    fontSize: Platform.OS == "web" ? "25px" : 25,
    paddingHorizontal: Platform.OS == "web" ? "20px" : 20,
    marginTop: 0,
  },
  itemCategory:{
    color: Colors.PURPLE,
    fontSize: Platform.OS == "web" ? "18px" : 18,
    paddingHorizontal: Platform.OS == "web" ? "20px" : 20,
    marginTop: 30,
  },
  webview:{
    width: "100%",
  },
  item:{
    width: "100%",
    height: "50%",
    backgroundColor: Colors.PURPLE,
  },
  itemContainer:{
    backgroundColor: Colors.LIGTH_GRAY,
    width:
      Dimensions.get("window").width < 767
        ? Dimensions.get("window").width - 20
        : Platform.OS == "web"
        ? "420px"
        : Dimensions.get("window").width - 20,
    height:
      Platform.OS == "web"
        ? "600px" :
        600,
    marginHorizontal: Platform.OS == "web" ? "10px" : 10,
    alignItems: "center",
  },
  header:{
    flexDirection: 'column',
    minHeight: 180,
    alignItems: 'center',
    width: '100%',
    maxWidth: 880,
  },
  container: {
    marginTop: Dimensions.get('window').width >= 768  || Platform.OS == 'android' ? 50 :  Platform.OS == 'ios' ? 70 :   0,
    alignItems: 'center',
    flex: 1
  }
});


const mapStateToProps = (state) => {
  return {
    locale: state.locale,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {}
}

const Videos = connect(mapStateToProps,mapDispatchToProps)(VideosContainer);
export default Videos;