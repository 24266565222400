import {
  FETCHING_PROMOTION_PENDING,
  FETCHING_PROMOTION_SUCCESS,
  FETCHING_PROMOTION_FAILURE
} from '../constants/actionTypes'

const initialState = {
  promotionIsFetching: false,
  promotionError: false,
  promotionData: {}
}

const promotion = (state = initialState, action) => {

  switch (action.type) {

    case 'SET_PROMOTION':
      return {
        promotionData: action.payload,
          promotionIsFetching: false,
          promotionError: false
      };


      //Inicio de casos para API
    case FETCHING_PROMOTION_PENDING:
      return {
        promotionData: {},
          promotionIsFetching: true,
          promotionError: false
      }
      case FETCHING_PROMOTION_SUCCESS:
        return {
          promotionData: action.data,
            promotionIsFetching: false,
            promotionError: false
        }
        case FETCHING_PROMOTION_FAILURE:
          return {
            promotionData: {},
              promotionError: true,
              promotionIsFetching: false
          }
          default:
            return state
  }
}

export default promotion;