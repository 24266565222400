import {Dimensions, Platform, StyleSheet} from 'react-native';
import globalStyles from '../../styles/global';
import * as Colors from '../../styles/colors';
const {width: WIDTH} = Dimensions.get('window');

export const styles = StyleSheet.create({
  contMainImage: {
    width: Platform.OS === 'web' ? '100%' : WIDTH - 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contMainSection: {
    marginBottom: 35,
  },
  contTitle: {
    height: 'auto',
    paddingVertical: Platform.OS === "web" && Dimensions.get("window").width > 767
      ? "0px"
      : 10,
  },
  title: {
    fontFamily: globalStyles.titleFont.fontFamily,
    fontSize: Platform.OS === "web" && Dimensions.get("window").width > 767
      ? "34px"
      : 24,
    color: Colors.PURPLE,
  },
  subTitle: {
    fontFamily: globalStyles.titleFont.fontFamily,
    fontSize: Platform.OS === "web" && Dimensions.get("window").width > 767
      ? "24px"
      : 14,
    color: Colors.PURPLE,
    lineHeight: Platform.OS === "web" && Dimensions.get("window").width > 767
      ? "24px"
      : 14,
  },
  webViewContainer: {
    width: "100%",
    height: Platform.OS === "web" && Dimensions.get("window").width > 767
      ? "750px"
      : 320,
    marginVertical: 25,
    backgroundColor: Colors.PURPLE,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    overflow: 'hidden'
  },
  contImage: {
    width: Platform.OS === 'web' ? '320px' : 320,
    height: Platform.OS === 'web' ? '320px' : 320,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contGallery: {
    position: 'relative',
    width: '100%',
    height: Platform.OS === 'web' ? '650px' : 500,
    flex: 1,
  },
  slideContainer: {
    width: '100%',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  slide: {
    width: '100%',
    height: '100%',
    flex: 1,
    backgroundColor: 'rgba(20,20,200,0.3)',
  },
});
