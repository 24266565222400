import React from "react";
import {
  Dimensions,
  Platform,
  Text,
  View,
  StyleSheet,
  FlatList,
  Image,
  Pressable
} from "react-native";

import * as Colors from "../styles/colors";

import { LinearGradient } from "expo-linear-gradient";

const gradientStart = {
  x: 0,
  y: 1
};
const gradientEnd = {
  x: 1,
  y: 0
};

class PodcastCardProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      length: 0,
      page: 1,
      length: 0
    };
  }

  PodcastCards = (podcast) => {
    return (
      <LinearGradient
        colors={["rgba(51,8,102,1)", "rgba(169,124,210,1)"]}
        start={gradientStart}
        end={gradientEnd}
        style={styles.card}
      >
        <Image source={require("../../assets/components/PodcastCards/ICONO_PODCAST_WEB_BENQ.png")}
          style={styles.cardImage}
        ></Image>
        <Text style={styles.cardTitle} numberOfLines={1}>
          {podcast.title}
        </Text>
        <Text style={styles.cardDescription} numberOfLines={4}>
          {stripTags(podcast.desc)}
        </Text>
        <Pressable
          onPress={() => {
            this.setState({
              modalVisible: true,
              selectedFrame: podcast.frame
            })
          }}
          style={styles.cardButton}
        >
          <Text style={styles.cardButtonText}>
            ESCUCHAR
          </Text>
        </Pressable>
      </LinearGradient>
    )
  }

  Divider = () => {
    return (
      <View style={{ marginTop: 10 }}></View>
    );
  };

  render() {
    return (
      <View style={{ marginTop: 15 }}>
        <View style={styles.cardContainer}>
          {
            this.props.podcasts.length ? (
              <FlatList
                style={{ marginTop: 40 }}
                data={this.props.podcasts}
                renderItem={({ item }) => this.PodcastCards(item)}
                keyExtractor={item => String(item.id)}
                numColumns={Dimensions.get('window').width < 667 ? 1 : 3}
                ItemSeparatorComponent={this.Divider}
                initialNumToRender={10}
                onEndThreshold={0.5}
              />
            ) : (
              <View style={{ flex: 1, justifyContent: 'center', alignContent: 'center' }}>
                <Text style={styles.emptyProducts}>No hay podcasts guardados por el momento</Text>
              </View>
            )
          }
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  cardContainer: {
    maxWidth: Dimensions.get("window").width,
    alignItems: "center",
    flexGrow: 1,
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  cardButtonText: {
    color: "white",
    fontSize: Platform.OS == "web" ? "20px" : 20,
    fontWeight: "bold",
  },
  cardButton: {
    position: "absolute",
    bottom: 50,
  },
  cardDescription: {
    color: "white",
    marginTop: 10,
  },
  cardTitle: {
    color: "white",
    fontSize: Platform.OS == "web" ? 40 : 40,
    marginTop: 20,
  },
  cardImage: {
    width: Platform.OS == "web" ? "120px" : 120,
    height: Platform.OS == "web" ? "90px" : 90,
  },
  card: {
    width:
      Platform.OS == "web"
        ? "280px"
        : Dimensions.get("window").width - 20,
    height:
      Platform.OS == "web"
        ? "380px"
        : Dimensions.get("window").width,
    padding: 30,
    marginHorizontal: Platform.OS == "web" ? "5px" : 5,
    alignItems: "center",
    borderRadius: 10,
  },
  emptyProducts: {
    color: Colors.PURPLE,
    fontSize: Dimensions.get('window').width > 667 ? 30 : 22,
    textTransform: 'uppercase',
    marginTop: 15,
    textAlign: 'center'
  }
});

export default PodcastCardProfile;
