const dictionary = {
    k: 'pt',
    home: 'Inicio',
    profile: 'Perfil',
    products: 'Produtos',
    monitores: 'Monitores',
    proyectors: 'Projetores',
    signaling: 'Sinalização',
    assistant: 'Participante',
    training: 'Treinamento',
    distributor: 'Revendedor',
    distributors: 'Revendedores',
    promotions: 'Promoções',
    appointments: 'Agendamentos',
    contact: 'Contato',
    search: 'Buscar',
    report_an_error: 'Reportar um erro',

    videos: 'Videos',
    articles: 'Artigos',
    podcasts: 'Podcasts',
    see_more: 'Ver mais',
    see_less: 'Ver menos',
    see: 'Ver',
    tips: 'Dicas',
    follow_us: 'Siga-nos',
    listen: 'Ouvir',
    close: 'Fechar',
    results: 'Resultados',
    error: 'Erro',
    message: 'Mensagem',
    send: 'Enviar',

    change_your_logo: 'Alterar seu logotipo',
    upload_your_logo: 'Carregar seu logotipo',
    change_your_password: 'Alterar sua senha',
    new_password: 'Nova senha',
    confirm_password: 'Confirmar senha',
    i_want_receive_notifications: 'Quero receber notificações',

    filter_by: 'Filtrar por',
    order_by: 'Organizar por',
    date: 'Data',
    title: 'Título',
    reviews: 'Comentários',

    wholesaler: 'Atacadista',
    retail: 'Varejo',
    price: 'Preço',
    new: 'Novo',
    sold_out: 'Esgotado',

    share: 'Compartilhar',
    save: 'Salvar',
    cancel: 'Cancelar',
    edit: 'Editar',
    delete: 'Remover',
    saved: 'Salvou',
    user: 'Usuário',
    email: 'E-mail',
    password: 'Senha',
    enter: 'Entrar',
    next: 'Seguindo',
    upcoming: 'Próximos',
    reset: 'Redefinir',
    previous: 'Anterior',

    forgot_your_password: 'Esqueceu sua senha?',
    new_password: 'Nova senha',
    repeat_password: 'Repetir a senha',
    not_have_an_account: 'Não tem tem uma conta?',
    have_you_an_account: 'Já tem uma conta?',
    sign_in_here: 'Faça login aqui',
    sign_in: 'Faça login',
    what_use_will_you: 'Para que usará o produto?',
    our_recommendations: 'Nossas recomendações',

    sign_up_here: 'Cadastre-se aqui',
    sign_up: 'Cadastrar',

    choose: 'Escolher',
    choose_a_profile: 'Escolha um perfil',
    name: 'Nome',
    last_name: 'Sobrenome',
    phone: 'Telefone',
    address: 'Endereço',
    country: 'País',
    state: 'Estado',
    company_name: 'Razão social',
    where_buy: 'Onde você compra?',
    items_saved: 'Itens salvos',
    more_info: 'Mais informação',

    price_just_for_reference: 'Preço de referência, para mais informações consulte seu executivo de contas.',
    where_buy: 'Onde comprar',

    all_fields_are_required: 'Todos os campos são obrigatórios',
    user_cannot_be_registered: 'Não foi possível registrar o usuário',
    document_not_available: 'O documento não está disponível',
    saved_successfully: 'Salvo com sucesso',
    deleted_successfully: 'Removido com sucesso',
    user_or_password_incorrect: 'Nome de usuário ou senha incorretos',
    user_incorrect: 'Usuário incorreto ou não registrado',
    passwords_do_not_match: 'As senhas não correspondem',
    password_does_not_match: 'A senha não coincidem',
    choose_a_country: 'Escolha um pais',
    choose_a_state: 'Escolha um estado',
    data_save_successfully: 'Dados salvos com sucesso',
    no_products_found: 'Nenhum produto encontrado',
    no_webinars_found: 'Nenhum webinar encontrado',
    no_videos_found: 'Nenhum vídeo encontrado',
    no_distributors_found: 'Nenhum distribuidor encontrado',
    no_promotions_found: 'Nenhuma promoção encontrada',
    recoverpass_request_sent: 'Um e-mail foi enviado para recuperar a senha',
    successfully_operation: 'Operação realizada com sucesso',

    you_have_to_put_a_message: 'Você precisa digitar uma mensagem',
    you_have_to_put_a_city: 'Você precisa digitar sua cidade',
    you_have_to_put_a_country: 'Você precisa selecionar seu país',
    you_have_to_put_an_email: 'Você precisa digitar seu e-mail',
    you_have_to_put_a_valid_email: 'Você precisa digitar um e-mail válido',
    you_have_to_put_a_phone: 'Você precisa digitar seu telefone',
    you_have_to_put_a_name: 'Você precisa digitar seu nome',
    you_have_to_put_a_last_name: 'Você precisa digitar seu sobrenome',

    no_login_modal: 'Convidamo-lo a se registrar e aproveitar todos os benefícios que oferecemos.',
    profile_paragraph: 'Mantenha suas informações atualizadas para que possamos oferecer a melhor experiência de usuário.',
    footer_paragraph: 'A prioridade da BenQ é impactar as áreas mais importantes da vida de seus usuários - melhorando sua experiência e qualidade de vida. Tudo o que é importante para você é importante para nós... Porque é importante!',
    login_paragraph: 'Acesse com seu e-mail e senha.',
    recover_password_paragraph: 'Digite seu nome de usuário ou e-mail.',
    assistant_paragraph: 'Ajudamos você a encontrar o produto de que precisa. Use o assistente para selecionar as características que melhor atendem às suas necessidades.',
    assistant_recommendation_paragraph: 'Aqui você encontrará as recomendações de produtos que temos para você.',
    distributors_paragraph: 'Encontre um distribuidor próximo a você. Eles o ajudarão a obter os melhores produtos com o melhor preço.',
    promotions_paragraph: 'Aproveite nossas promoções e obtenha o melhor pelo melhor preço. Estamos sempre atualizando nossas ofertas e benefícios.',
    contact_paragraph: 'Se você tiver dúvidas, sugestões ou problemas, entre em contato conosco. Preencha o formulário abaixo e responderemos o mais rápido possível.',

    assistant_question_distance: 'A que distância você vai instalar?',
    assistant_question_frequency: 'Qual frequência você precisa?',
    assistant_question_hardwarecalibrable: 'Com calibração de hardware?',
    assistant_question_installation: 'Como você vai instalá-lo?',
    assistant_question_lightcontrol: 'Com controle de luz?',
    assistant_question_lighttype: 'Que tipo de iluminação você prefere?',
    assistant_question_panel: 'Que tipo de painel?',
    assistant_question_place: 'Onde você vai instalá-lo?',
    assistant_question_position: 'Em que posição você vai instalá-lo?',
    assistant_question_purpose: 'Qual é o seu propósito?',
    assistant_question_resolution: 'Qual resolução você precisa?',
    assistant_question_responsetime: 'Qual tempo de resposta você espera?',
    assistant_question_screensize: 'Qual o tamanho da tela que você precisa?',
    assistant_question_size: 'Qual é o tamanho que você precisa?',
    assistant_question_speakers: 'Quais alto-falantes você está procurando?',
    assistant_question_technology: 'Qual tecnologia você prefere?',
    assistant_question_touch: 'Com função de toque?',
    assistant_question_use: 'Que uso você dará ao seu produto?',
    assistant_question_worktime: 'Que tipo de trabalho você vai realizar?',

    assistant_noresults_message: 'Não há resultados para sua seleção. Deseja tentar outra pesquisa?',
}
export default dictionary;