import {
    FETCHING_CARACTERISTICS_PENDING,
    FETCHING_CARACTERISTICS_SUCCESS,
    FETCHING_CARACTERISTICS_FAILURE,
  } from '../constants/actionTypes';
  import api from '../api/caracteristics';
  
  export const getCaracteristics = async (data) => {
    try {
      const [response, json] = await api('getCaracteristics', data);
      json.statusCode = response.status;
      return getDataSuccess(json);
    } catch (err) {
      console.log(err);
      return getDataFailure(err);
    }
  };
  
  export const setCaracteristics = (data) => {
    return {
      type: 'SET_CARACTERISTICS',
      payload: data
    }
  }
  
  //API Requests
  export const getData = () => {
    return {
      type: FETCHING_CARACTERISTICS_PENDING,
    };
  };
  export const getDataSuccess = (json) => {
    return {
      type: FETCHING_CARACTERISTICS_SUCCESS,
      caracteristicsData: json
    };
  };
  export const getDataFailure = () => {
    return {
      type: FETCHING_CARACTERISTICS_FAILURE,
    };
  };