import * as React from "react";
import {
  Dimensions,
  Platform,
  StyleSheet,
  Text,
  View
} from "react-native";
import stripTags from "../../utils/stripTags";
import { WebView as WebViewWeb }  from "react-native-web-webview";
import { WebView as WebViewMobile}  from "react-native-webview";
import youtube_parser from "../../utils/extractYoutube";

import * as globalStyles from "../../styles/global";
import * as Colors from "../../styles/colors";

class ReviewProductCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      review: [],
    };
  }

  componentDidMount() {
    this.setState({ review: this.props.review });
  }

  render() {
    const { review } = this.state;

    return (
      <>
        { review.review &&
          <View style={styles.card}>
            <View style={styles.webViewContainer}>
              { Platform.OS == "web" ?
                <WebViewWeb
                androidLayerType="software"
                  androidHardwareAccelerationDisabled
                  style={{width: "100%" }}
                  javaScriptEnabled={true}
                  domStorageEnabled={true}
                  source={{ uri: `https://www.youtube.com/embed/${youtube_parser(review.review.items.url)}` }}
                /> : 
                <WebViewMobile
                androidLayerType="software"
                  androidHardwareAccelerationDisabled
                  style={{width: '100%' }}
                  javaScriptEnabled={true}
                  domStorageEnabled={true}
                  source={{ uri: `https://www.youtube.com/embed/${youtube_parser(review.review.items.url)}`}}
                />
              }
            </View>
            <View>
              <Text style={[styles.category, globalStyles.default.boldFont]}>
                {review.review.items.category}
              </Text>
              <Text style={[styles.title, globalStyles.default.boldFont]} >
                {review.review.items.title}
              </Text>
              <Text
                numberOfLines={3}
                style={[styles.description, globalStyles.default.titleFont]}
              >
                {stripTags(review.review.items.desc)}
              </Text>
            </View>
          </View>
        }
      </>
    );
  }
}

const styles = StyleSheet.create({
  card: {
    backgroundColor: Colors.LIGTH_GRAY,
    width:
      Dimensions.get("window").width < 767
        ? '100%'
        : Platform.OS == "web"
        ? "360px"
        : Dimensions.get("window").width - 20,
    height:
      Platform.OS == "web"
        ? "480px"
        : Dimensions.get("window").width,
    margin: Platform.OS == "web" && Dimensions.get('window').width > 667 
      ? "10px" 
      : Dimensions.get('window').width > 667
      ? 10 : 0,
    marginEnd: 20,
    marginStart: 0,
    alignItems: "center",
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 1,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
  },
  webViewContainer: {
    width: "100%",
    height: "50%",
    backgroundColor: Colors.PURPLE,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    overflow: 'hidden'
  },
  category: {
    color: Colors.PURPLE,
    fontSize: Platform.OS == "web" ? "18px" : 18,
    paddingHorizontal: Platform.OS == "web" ? "20px" : 20,
    marginTop: 30,
  },
  title: {
    color: Colors.BLACK,
    fontSize: Platform.OS == "web" ? "25px" : 25,
    paddingHorizontal: Platform.OS == "web" ? "20px" : 20,
    marginTop: 0,
  },
  description: {
    color: Colors.GRAY,
    fontSize: Platform.OS == "web" ? "18px" : 18,
    paddingHorizontal: Platform.OS == "web" ? "20px" : 20,
    marginTop: 10
  }
});

export default ReviewProductCard;
