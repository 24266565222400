import * as React from 'react';
import { 
  View, 
  StyleSheet, 
  ImageBackground, 
  Text, 
  Pressable, 
  TextInput, 
  Platform, 
  ActivityIndicator, 
  Dimensions
} from 'react-native';
import {useSelector} from 'react-redux';
import * as Colors from '../styles/colors';
import globalStyles from '../styles/global';

import { connect }  from 'react-redux';
import * as actions from '../actions/profile';
import * as RootNavigation from '../rootNavigation';

import AsyncStorage from '@react-native-async-storage/async-storage';
import Toast from "react-native-toast-notifications";
import { ScrollView } from 'react-native-gesture-handler';
import Footer from '../components/Footer';

function ChangePasswordSection({ route, navigation }) {
  const locale = useSelector( (state) => state.locale)
  const toastRef = React.useRef();
  const [loading, setLoading] = React.useState(false);
  const [userData, setUserData] = React.useState({
    username: '',
    password1: '',
    password2: '',
    token: '',
  });

  const handleChange = async () => {
    //console.log(userData)
    const { username, password1, password2, token } = userData;
    
    if (!username || !password1 || !password2 || !token) {
        toastRef.current.show(locale.in['all_fields_are_required'], { type: 'danger', duration: 3000, placement: 'bottom' });
        return;
    }
    if( /\S+@\S+\.\S+/.test(username) === false) {
        toastRef.current.show(locale.in['you_have_to_put_a_valid_email'], { type: 'danger', duration: 3000, placement: 'bottom' });
        return;
    }
    if( password1 !== password2) {
        toastRef.current.show(locale.in['passwords_do_not_match'], { type: 'danger', duration: 3000, placement: 'bottom' });
        return;
    }

    setLoading(true);

    await actions
    .changePassword({ username: username.toLowerCase().trim(), password: password1.trim(), token: token })
    .then(res => {
        //console.log(res)
        if (res.type === 'FETCHING_PROFILE_SUCCESS' && res.success) {
            setLoading(false);
            setUserData({username: '', password1: '', password2: '', token: ''})
            toastRef.current.show(locale.in['successfully_operaion'], { type: 'success', duration: 3000, placement: 'bottom' });
        } else {
            toastRef.current.show(locale.in['user_or_password_incorrect'], { type: 'danger', duration: 3000, placement: 'bottom' });
            setLoading(false);
            setUserData({username: '', password1: '', password2: '', token: ''})
        }
    })
    .catch(err => {
        console.log(err);
    }
    );
  }

  React.useEffect(() => {
    //Get value from URL params in react-native
    //console.log(route.params)
    const { token,email } = route.params;

    if(token && email) {
        //console.log('ChangePasswordSection useEffect', token)
        setUserData({...userData, token: token, username: email})
    }
  }, [])
  

  return (
    <ScrollView>
    <ImageBackground source={require('../../assets/FONDO_TEXTURA_BENQ.jpg')}>
      <View style={styles.centeredView}>
        <Toast ref={toastRef} />
        <View style={[styles.centeredView, {
            backgroundColor: 'rgba(252, 252, 252, 1)',
            marginVertical: 50,
            padding: 50,
            borderRadius: 10,
            maxWidth: 600,
            width: '100%'
          }]}>
          <Text style={{
            fontSize: 30,
            fontFamily: 'Lato_400Regular',
            marginBottom: 20,
          }}>{locale.in['password'].toUpperCase()}</Text>
         
          <TextInput  
            style={[styles.formInput, {marginTop: 30}]}
            placeholder={locale.in['new_password']}
            onChange={(text) => setUserData({...userData, password1: text.nativeEvent.text})}
            value={userData.password1}
            keyboardType="password"
            autoCapitalize="none"
            autoCorrect={false}
            placeholderTextColor={Colors.GRAY}
            secureTextEntry={true}
          />
          <TextInput  
            style={[styles.formInput, {marginTop: 10}]}
            placeholder={locale.in['repeat_password']}
            onChange={(text) => setUserData({...userData, password2: text.nativeEvent.text})}
            value={userData.password2}
            keyboardType="password"
            autoCapitalize="none"
            autoCorrect={false}
            placeholderTextColor={Colors.GRAY}
            secureTextEntry={true}
          />
          <Pressable
            style={[styles.button, styles.buttonEnter, globalStyles.row]}
            onPress={handleChange}
            disabled={loading}
          >
            <Text style={[styles.textStyle,{color:Colors.WHITE}]}>Entrar</Text>
            {
              loading && (
                <ActivityIndicator size="small" color={Colors.WHITE} style={{marginStart: 10}} />
              )
            }
          </Pressable>
        </View>
      </View>

    <Footer {...{navigation}} />
    </ImageBackground>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    borderRadius: 20,
    padding: 10
  },
  buttonEnter: {
    backgroundColor: Colors.PURPLE,
    width: Dimensions.get('window').width > 667 ? '50%' : '100%',
    textAlign: 'center',
    marginTop: 50,
    justifyContent: 'center',
    elevation: 2
  },
  textStyle: {
    fontWeight: "bold",
    textAlign: "center",
    textTransform: 'uppercase',
    color: Colors.GRAY
  },
  formInput: {
    borderColor: Colors.LIGTH_GRAY,
    borderRadius: 25,
    borderWidth: 2,
    marginVertical: 5,
    textTransform: "uppercase",
    padding: 10,
    paddingHorizontal: 17,
    color: Colors.GRAY,
    width: '100%',
    backgroundColor: 'transparent'
  },
  selectControlArrowImageStyle:{
    marginTop : Platform.OS == 'web' ? 0 : 15,
    tintColor: '#fff'
  },
  selectControlStyle:{
    borderRadius: 25,
    borderBottomLeftRadius: 25,
    borderBottomRightRadius: 25,
    backgroundColor: 'transparent',
    borderColor: Colors.WHITE,
    padding: 10
  },
  optionsListStyle:{
    position: Platform.OS == 'web' ? 'fixed' : 'absolute',
    borderColor: '#fff',
    marginTop: Platform.OS == 'web' ? 0 : -40
  }
});

const mapStateToProps = (state) => {
  return {}
}
const mapDispatchToProps = (dispatch) => {
  return {
      changePassword: (data) => {
          return dispatch(actions.changePassword(data))
      }
  }
}
const ChangePassword = connect(mapStateToProps, mapDispatchToProps)(ChangePasswordSection);

export default ChangePassword;