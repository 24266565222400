import {
  FETCHING_PRODUCT_PENDING,
  FETCHING_PRODUCT_SUCCESS,
  FETCHING_PRODUCT_FAILURE
} from '../constants/actionTypes';

const initialState = {
  productIsFetching: false,
  productError: false,
  productData: {}
}

const producto = (state = initialState, action) => {

  switch (action.type) {

    case 'SET_PRODUCT':
      return {
        productData: action.payload,
          productIsFetching: false,
          productError: false
      };


      //Inicio de casos para API
    case FETCHING_PRODUCT_PENDING:
      return {
        productData: {},
          productIsFetching: true,
          productError: false
      }
      case FETCHING_PRODUCT_SUCCESS:
        return {
          productData: action.data,
            productIsFetching: false,
            productError: false
        }
        case FETCHING_PRODUCT_FAILURE:
          return {
            productData: {},
              productError: true,
              productIsFetching: false
          }
          default:
            return state
  }
}

export default producto;