export const FETCHING_HOME_PENDING 	= 'FETCHING_HOME_PENDING';
export const FETCHING_HOME_SUCCESS 	= 'FETCHING_HOME_SUCCESS';
export const FETCHING_HOME_FAILURE 	= 'FETCHING_HOME_FAILURE';

export const FETCHING_USER_PENDING 	= 'FETCHING_USER_PENDING';
export const FETCHING_USER_SUCCESS 	= 'FETCHING_USER_SUCCESS';
export const FETCHING_USER_FAILURE 	= 'FETCHING_USER_FAILURE';

export const FETCHING_PRODUCT_PENDING 	= 'FETCHING_PRODUCT_PENDING';
export const FETCHING_PRODUCT_SUCCESS 	= 'FETCHING_PRODUCT_SUCCESS';
export const FETCHING_PRODUCT_FAILURE 	= 'FETCHING_PRODUCT_FAILURE';

export const FETCHING_PROMOTION_PENDING 	= 'FETCHING_PROMOTION_PENDING';
export const FETCHING_PROMOTION_SUCCESS 	= 'FETCHING_PROMOTION_SUCCESS';
export const FETCHING_PROMOTION_FAILURE 	= 'FETCHING_PROMOTION_FAILURE';

export const FETCHING_DISTRIBUTOR_PENDING 	= 'FETCHING_DISTRIBUTOR_PENDING';
export const FETCHING_DISTRIBUTOR_SUCCESS 	= 'FETCHING_DISTRIBUTOR_SUCCESS';
export const FETCHING_DISTRIBUTOR_FAILURE 	= 'FETCHING_DISTRIBUTOR_FAILURE';

export const FETCHING_TRAINING_PENDING 	    = 'FETCHING_TRAINING_PENDING';
export const FETCHING_TRAINING_SUCCESS 	    = 'FETCHING_TRAINING_SUCCESS';
export const FETCHING_TRAINING_FAILURE 	    = 'FETCHING_TRAINING_FAILURE';

export const FETCHING_ARTICLES_PENDING 	    = 'FETCHING_ARTICLES_PENDING';
export const FETCHING_ARTICLES_SUCCESS 	    = 'FETCHING_ARTICLES_SUCCESS';
export const FETCHING_ARTICLES_FAILURE 	    = 'FETCHING_ARTICLES_FAILURE';

export const FETCHING_VIDEOS_PENDING 	    = 'FETCHING_VIDEOS_PENDING';
export const FETCHING_VIDEOS_SUCCESS 	    = 'FETCHING_VIDEOS_SUCCESS';
export const FETCHING_VIDEOS_FAILURE 	    = 'FETCHING_VIDEOS_FAILURE';

export const FETCHING_CATEGORIES_PENDING 	= 'FETCHING_CATEGORIES_PENDING';
export const FETCHING_CATEGORIES_SUCCESS 	= 'FETCHING_CATEGORIES_SUCCESS';
export const FETCHING_CATEGORIES_FAILURE 	= 'FETCHING_CATEGORIES_FAILURE';

export const FETCHING_PODCAST_PENDING = 'FETCHING_PODCAST_PENDING';
export const FETCHING_PODCAST_SUCCESS = 'FETCHING_PODCAST_SUCCESS';
export const FETCHING_PODCAST_FAILURE = 'FETCHING_PODCAST_FAILURE';

export const FETCHING_PROFILE_PENDING 	    = 'FETCHING_PROFILE_PENDING';
export const FETCHING_PROFILE_SUCCESS 	    = 'FETCHING_PROFILE_SUCCESS';
export const FETCHING_PROFILE_FAILURE 	    = 'FETCHING_PROFILE_FAILURE';

export const FETCHING_CARACTERISTICS_PENDING 	    = 'FETCHING_CARACTERISTICS_PENDING';
export const FETCHING_CARACTERISTICS_SUCCESS 	    = 'FETCHING_CARACTERISTICS_SUCCESS';
export const FETCHING_CARACTERISTICS_FAILURE 	    = 'FETCHING_CARACTERISTICS_FAILURE';

export const FETCHING_SEARCHING_PENDING 	    = 'FETCHING_SEARCHING_PENDING';
export const FETCHING_SEARCHING_SUCCESS 	    = 'FETCHING_SEARCHING_SUCCESS';
export const FETCHING_SEARCHING_FAILURE 	    = 'FETCHING_SEARCHING_FAILURE';

export const FETCHING_LOCALE_PENDING 	    = 'FETCHING_LOCALE_PENDING';
export const FETCHING_LOCALE_SUCCESS 	    = 'FETCHING_LOCALE_SUCCESS';
export const FETCHING_LOCALE_FAILURE 	    = 'FETCHING_LOCALE_FAILURE';