const MenuItems = [
    {
        inKey:      "home",
        component:  "Home",
        screenName: "Home",
        section: ['header'],
        screenImage:  require("../../assets/components/Header/LOGO_HEADER_BENQ-BLANCO.png"),
        screenImage2: require("../../assets/components/Footer/LOGO_FOOTER_BENQ_COLOR.png")
    },
    {
        inKey:    "profile",
        section: ['header'],
        component: "Perfil",
        screenName: "Perfil",
        requiresAuth: true,
    },
    {
        section: ['footer', 'header'],
        component: "Productos",
        screenName: "Productos",
        inKey:    "products",
    },
    {
        section: ['footer', 'header'],
        component: "Asistente",
        screenName: "Asistente",
        inKey:    "assistant",
    },
    {
        section: ['footer', 'header'],
        component: "Capacitación",
        screenName: "Capacitación",
        requiresAuth: true,
        inKey:    "training",
    },
    {
        section: ['footer', 'header'],
        component: "Distribuidores",
        screenName: "Distribuidores",
        requiresAuth: true,
        inKey:    "distributors",
    },
    {
        section: ['footer', 'header'],
        component: "Promociones",
        screenName: "Promociones",
        inKey:    "promotions",
    },
    {
        section: ['footer', 'header'],
        component: "Citas",
        screenName: "Citas",
        requiresAuth: true,
        inKey:    "appointments",
    },
    {
        section: ['footer'],
        component: "Contacto",
        screenName: "Contacto",
        inKey:    "contact",
    },
    {
        section: ['footer'],
        component: "Contacto",
        screenName: "Reportar Error",
        inKey:      "report_an_error",
    }
];

export default MenuItems;
