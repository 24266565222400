import * as Colors from "../styles/colors";

import {
  Dimensions,
  Image,
  ImageBackground,
  Platform,
  Pressable,
  ScrollView,
  Text,
  View,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator
} from "react-native";
import * as Global from '../styles/global';
import React from "react";

import { WebView as WebViewWeb }  from "react-native-web-webview";
import { WebView as WebViewMobile}  from "react-native-webview";
import { connect }  from 'react-redux';
import youtube_parser from '../utils/extractYoutube';
import stripTags from '../utils/stripTags';
import SaveAndShare from '../components/saveandshare/SaveAndShare';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");
class VideoCardsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items:[],
      componentReady: false,
      dimensions: {
        window,
        screen
      }
    }
  }
  onChange = ({ window, screen }) => {
    this.setState({ dimensions: { window, screen } });
  }
  componentDidMount(){
    //console.log('this.props.home.homeData.items',this.props.home.homeData.items)
    if(this.props.home && this.props.home.homeData && this.props.home.homeData.items){
        if(this.props.home.homeData.items[0].videos){
          this.setState({
              items: this.props.home.homeData.items[0].videos.pages.slice(0,2),
              intro: this.props.home.homeData.items[0].videos.intro,
              componentReady: true
          })
        }
    }
    this.dimensionsSubscription = Dimensions.addEventListener("change", this.onChange);
  }
  componentWillUnmount() {
    this.dimensionsSubscription?.remove();
  }
  render() {
    if(this.state.componentReady){
      return (
        <View style={styles.container}>
            <View style={[styles.titleContainer,{
              width: Platform.OS === 'web' ? '100%' : this.state.dimensions.window.width,
            }]}>
              <Text style={Global.default.sectionHeader}>
                  {this.props.locale.in['videos'].toUpperCase()}
              </Text>
              <Text style={Global.default.sectionSubHeader} >
                  {this.state.intro}
              </Text>
          </View>
  
          <ScrollView
            contentContainerStyle={styles.scrollViewContentContainer}
            style={[styles.scrollViewContainer,{
              maxWidth: this.state.dimensions.window.width,
            }]}
            horizontal={true}
          >
            {this.state.items.map((video, index) => {
              return (
                <View key={"card-" + index} style={[styles.cardContainer,{
                  width:
                    this.state.dimensions.window.width < 767
                      ? this.state.dimensions.window.width * 0.8
                      : Platform.OS == "web"
                      ? "420px"
                      : this.state.dimensions.window.width - 20,
                  height:
                    Platform.OS == "web"
                      ? "550px"
                      : 550,
                }]}>
                  <View style={[styles.cardImageContainer,{
                    width: Platform.OS === 'web' ? '100%' : this.state.dimensions.window.width * 0.8,
                    height: Platform.OS === 'web' ? '55%' : this.state.dimensions.window.width/1.5,
                  }]}>
                  {
                      Platform.OS == "web" && (
                        <WebViewWeb
                          style={{width: "100%" }}
                          javaScriptEnabled={true}
                          domStorageEnabled={true}
                          source={{ uri: `https://www.youtube.com/embed/${youtube_parser(video.url)}` }}
                        />
                      ) 
                      || (
                        <WebViewMobile
                          style={{
                            width: this.state.dimensions.window.width                            
                          }}
                          javaScriptEnabled={true}
                          domStorageEnabled={true}
                          source={{ uri: `https://www.youtube.com/embed/${youtube_parser(video.url)}`}}
                        />
                      )
                    }
                    <SaveAndShare item={video} options={{share:true,save:true,download:false,relative:true}} />
                  </View>
                  <View>
                    <Text style={styles.cardTitle}>
                      {video.category}
                    </Text>
                    <Text
                    numberOfLines={2}
                      style={styles.cardSubTitle}>
                      {video.title}
                    </Text>
                    <Text style={styles.cardDescription} numberOfLines={4}>
                      {stripTags(video.desc)}
                    </Text>
                  </View>
                </View>
              );
            })}
          </ScrollView>
  
          <View>
              <TouchableOpacity 
                style={styles.seeMoreContainer}
                onPress={() => {
                  this.props.navigation.navigate('Videos');
                }}
              >
                <Text style={{ color: Colors.BLACK, fontSize: 18 }}>
                  {this.props.locale.in['see_more'].toUpperCase()}
                </Text>
              </TouchableOpacity>
          </View>
  
        </View>
      );
    }
    else{
      return(
        <ActivityIndicator size="small" color={Colors.PURPLE} style={{marginStart: 10}} />
      )
    }
  }
}

const styles = StyleSheet.create({
  seeMoreContainer:{
    minWidth: 200,
    height: 50,
    backgroundColor: Colors.TRANSPARENT,
    paddingHorizontal: 20,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 30,
    borderRadius: 25,
    borderColor: Colors.PURPLE,
    borderWidth: 2,
  },
  cardDescription:{
    color: Colors.GRAY,
    fontSize: Platform.OS == "web" ? "18px" : 18,
    paddingHorizontal: Platform.OS == "web" ? "20px" : 20,
    marginTop: 10,
  },
  cardSubTitle:{
    color: Colors.BLACK,
    fontSize: Platform.OS == "web" ? "25px" : 25,
    paddingHorizontal: Platform.OS == "web" ? "20px" : 20,
    marginTop: 0,
  },
  cardTitle:{
    color: Colors.PURPLE,
    fontSize: Platform.OS == "web" ? "18px" : 18,
    paddingHorizontal: Platform.OS == "web" ? "20px" : 20,
    marginTop: 30,
  },
  cardImageContainer:{
    backgroundColor: Colors.BLACK,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    overflow: 'hidden',
  },
  cardContainer:{
    backgroundColor: Colors.LIGTH_GRAY,
    marginHorizontal: Platform.OS == "web" ? "10px" : 10,
    alignItems: "center",
    borderRadius: 15,
    elevation: 5
  },
  scrollViewContainer:{
    height: Platform.OS == "web" ? "600px" : 550,
  },
  scrollViewContentContainer:{
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
  },
  titleContainer: {
    flexDirection: 'column',
    minHeight: 180,
    alignItems: 'center',
    maxWidth: 880,
  },
  container: {
    marginTop: 50,
    alignItems: "center",
  }
});


const mapStateToProps = (state) => {
  return {
      home: state.home,
      locale: state.locale,
  }
}

const VideoCards = connect(mapStateToProps,null)(VideoCardsContainer);
export default VideoCards;
