import {useWindowDimensions} from "react-native";
import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import Menu from "./components/menu/Menu";
import Contacto from "./routes/Contacto";
import Header from "./components/Header";
import Home from "./routes/Home";
import Login from "./routes/Login";
import ProductDetails from "./routes/DetalleProducto";
import Products from "./routes/Productos";
import Promotions from "./routes/Promociones";
import Registro from "./routes/Registro";
import Distributors from './routes/Distribuidores';
import Asistente from './routes/Asistente';
import Citas from './routes/Citas';
import Profile from './routes/Perfil';
import Articulos from './routes/Articulos';
import Podcasts from './routes/Podcasts';
import Videos from './routes/Videos';
import Articulo from './routes/Articulo'; //Articulo
import Training from "./routes/Capacitacion";
import Buscador from "./routes/Buscador";
import RecuperarPassword from "./routes/RecuperarPassword";
import CambiarPassword from "./routes/CambiarPassword";


import { createDrawerNavigator } from "@react-navigation/drawer";
import { createStackNavigator } from "@react-navigation/stack";
import { useNavigation } from "@react-navigation/native";

const MainStack = createStackNavigator();
const Drawer = createDrawerNavigator();

function CompleteScreen() {
  const navigation = useNavigation();
  const dimensions = useWindowDimensions();
  const isLargeScreen = dimensions.width > 1024;
  const [isLoginProfile, setIsLoginProfile] = useState(0);

  const fnTest = (e) => {
    // console.log('fnTest => e =>', e);
    setIsLoginProfile(isLoginProfile + 1);
  };

  return (
    <Drawer.Navigator
      drawerContent={props => <Menu {...props} />}
      screenOptions={({ navigation,route }) => ({
        headerShown: true,
        drawerType: dimensions.width <= 1024 ? 'front' : 'slide',
        drawerStyle: isLargeScreen ? null : { width: '100%', backgroundColor: 'rgb(242, 242, 242)' },
        justifyContent: "center",
        header:()=><Header  navigation={navigation} route={route}/>,
        headerTransparent: route.name === 'Home' ? true : false,
      })}
      initialRouteName="Home"
    >

      <Drawer.Screen name="Home" component={Home} options={{animationEnabled: false}}/>
      <Drawer.Screen name="Login" component={Login} options={{animationEnabled: false}}/>
      <Drawer.Screen name="Registro" component={Registro} options={{animationEnabled: false}}/>
      <Drawer.Screen name="Promociones" component={Promotions} options={{animationEnabled: false}}/>
      <Drawer.Screen name="Citas" component={Citas} options={{animationEnabled: false}}/>
      <Drawer.Screen name="Productos" component={Products} options={{animationEnabled: false}}/>
      <Drawer.Screen name="Distribuidores" component={Distributors} options={{animationEnabled: false}}/>
      <Drawer.Screen name="Asistente" component={Asistente} options={{animationEnabled: false}}/>
      <Drawer.Screen name="Perfil" component={Profile} options={{animationEnabled: false}} listeners={(e) => fnTest(e)} initialParams={{
        chkProfile: isLoginProfile,
      }}

      />
      <Drawer.Screen name="Articulos" component={Articulos} />
      <Drawer.Screen name="Podcasts" component={Podcasts} />
      <Drawer.Screen name="Videos" component={Videos} />
      <Drawer.Screen name="Capacitación" component={Training} />

      <Drawer.Screen name="RecuperarPassword" component={RecuperarPassword} options={{animationEnabled: false,drawerLabel:()=>null,drawerIcon:null,title:''}}/>
      <Drawer.Screen name="CambiarPassword" component={CambiarPassword} options={{animationEnabled: false,drawerLabel:()=>null,drawerIcon:null,title:''}}/>
      <Drawer.Screen name="Buscador" component={Buscador} options={{animationEnabled: false,drawerLabel:()=>null,drawerIcon:null,title:''}}/>
      <Drawer.Screen name="Contacto" component={Contacto} options={{animationEnabled: false,drawerLabel:()=>null,drawerIcon:null,title:''}}/>
      <Drawer.Screen name="Detalle Producto" component={ProductDetails} options={{animationEnabled: false,drawerLabel:()=>null,drawerIcon:null,title:''}}/>
      <Drawer.Screen name="Articulo" component={Articulo} options={{animationEnabled: false,drawerLabel:()=>null,drawerIcon:null,title:''}}/>

    </Drawer.Navigator>
  );
}

function PartialScreen() {
  const navigation = useNavigation();
  const dimensions = useWindowDimensions();
  const isLargeScreen = dimensions.width > 1024;

  return (
    <Drawer.Navigator
      drawerContent={props => <Menu {...props} />}
      screenOptions={({ navigation,route }) => ({
        profile:useSelector(state=>state.profile),
        headerShown: true,
        drawerType: dimensions.width <= 1024 ? 'front' : 'slide',
        drawerStyle: isLargeScreen ? null : { width: '100%', backgroundColor: 'rgb(242, 242, 242)' },
        justifyContent: "center",
        header:Header,
        headerTransparent: route.name === 'Home' ? true : false,
      })}
      initialRouteName="Home"
    >

      <Drawer.Screen name="Home" component={Home} />
      <Drawer.Screen name="Login" component={Login} />
      <Drawer.Screen name="Registro" component={Registro} />
      <Drawer.Screen name="Promociones" component={Promotions} />
      <Drawer.Screen name="Productos" component={Products} />
      <Drawer.Screen name="Asistente" component={Asistente} />
      <Drawer.Screen name="Articulos" component={Articulos} />
      <Drawer.Screen name="Podcasts" component={Podcasts} />
      <Drawer.Screen name="Videos" component={Videos} />

      <Drawer.Screen name="Buscador" component={Buscador} options={{drawerLabel:()=>null,drawerIcon:null,title:''}}/>
      <Drawer.Screen name="Contacto" component={Contacto} options={{drawerLabel:()=>null,drawerIcon:null,title:''}}/>
      <Drawer.Screen name="Detalle Producto" component={ProductDetails} options={{drawerLabel:()=>null,drawerIcon:null,title:''}}/>
      <Drawer.Screen name="Articulo" component={Articulo} options={{drawerLabel:()=>null,drawerIcon:null,title:''}}/>

    </Drawer.Navigator>
  )
}

export default function MainStackScreen(props) {
  //const profile = useSelector(state => state.profile)
  return (
    <MainStack.Navigator screenOptions={{ headerShown: false }}>
      <MainStack.Screen
          options={{
            animationEnabled: false,
            stackAnimation: "none"
          }}
          name="Q"
          component={CompleteScreen}
        />
      {/* 
      {profile.profileData.user_id ? (
        <MainStack.Screen
          name="Q"
          component={CompleteScreen}
        />
      ) : (
        <MainStack.Screen
          name="Q"
          component={CompleteScreen}
        />
      )}
      */}
    </MainStack.Navigator>
  );
}
