import {
  FETCHING_PODCAST_PENDING,
  FETCHING_PODCAST_SUCCESS,
  FETCHING_PODCAST_FAILURE,
} from '../constants/actionTypes.js';

import api from '../api/podcast';

export const getPodcasts = (data) => {
  return api('getPodcasts', data)
  .then(([response, json]) => {
    //console.log('response =>', response);
    //console.log('json =>', json);
    json.statusCode = response.status;
    return getDataSuccess(json);
  })
  .catch((err) => {
    console.log(err);
    return getDataFailure(err);
  });
};

//API Requests
export const getData = () => {
  return {
    type: FETCHING_PODCAST_PENDING,
  };
};
export const getDataSuccess = (json) => {
  return {
    type: FETCHING_PODCAST_SUCCESS,
    contentData: json,
  };
};
export const getDataFailure = () => {
  return {
    type: FETCHING_PODCAST_FAILURE,
  };
};
