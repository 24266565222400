import React from 'react';
import { StyleSheet, Dimensions, Platform, Text, TouchableOpacity, Linking, Alert, Image } from 'react-native';
import * as Colors from '../styles/colors';
import * as Global from '../styles/global';
import { BASE_URL } from '../constants/endPoints';

import formatDateWithMonth from '../utils/formatDateWithMonth';
import stripTags from '../utils/stripTags';
class WebinarCard extends React.Component {
  constructor(props) {
    super(props);
  }

  handleLink(url) {
    Linking.canOpenURL(url).then(supported => {
      if (supported) {
        Linking.openURL(url);
      } else {
        Alert("Don't know how to open URI: " + url);
      }
    });
  }

  render() {
    const { webinar } = this.props;

    return (
      <TouchableOpacity style={[styles.card, {
        height: webinar.image && webinar.image != '' ? Dimensions.get("window").width : 220
      }]}
        onPress={() => this.handleLink(webinar.url)}
      >
        {
          webinar.image && webinar.image != "" ? (
            <Image
              style={[styles.imageFLuid, { marginHorizontal: 'auto', marginBottom: 10 }]}
              source={{ uri: BASE_URL + webinar.image }}
              resizeMode="cover"
            />
          ) : <></>
        }

        <Text style={styles.title}>{webinar.title}</Text>
        <Text style={styles.date}>{formatDateWithMonth(webinar.date)}</Text>
        <Text numberOfLines={4} style={styles.description}>{stripTags(webinar.desc)}</Text>
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  card: {
    backgroundColor: Colors.WHITE,
    width:
      Dimensions.get("window").width < 767
        ? '100%'
        : Platform.OS == "web"
          ? "360px"
          : Dimensions.get("window").width - 20,
    height:
      Platform.OS == "web"
        ? "300px"
        : Dimensions.get("window").width,
    margin: Platform.OS == "web" && Dimensions.get('window').width > 667 && Dimensions.get('window').width < 1025
      ? "5px"
      : Platform.OS == "web" && Dimensions.get('window').width > 1025
        ? "10px"
        : Dimensions.get('window').width > 667
          ? 10 : 0,
    marginBottom: 10,
    borderRadius: 10,
    padding: Platform.OS == "web" ? "20px" : 20,
  },
  title: {
    fontSize: Platform.OS == "web" && Dimensions.get('window').width > 667 ? "24px" : 20,
    fontFamily: Global.default.boldFont.fontFamily,
    fontWeight: '500',
    textTransform: 'uppercase'
  },
  date: {
    color: Colors.PURPLE,
    textTransform: 'uppercase',
    fontSize: Platform.OS == "web" && Dimensions.get('window').width > 667 ? "20px" : 14,
    fontFamily: Global.default.boldFont.fontFamily,
    fontWeight: '500'
  },
  description: {
    color: Colors.GRAY,
    marginTop: Platform.OS == "web" ? "15px" : 15,
  },
  imageFLuid: {
    width: '100%',
    height: Platform.OS === 'web' ? '450px' : 200,
    overflow: 'visible'
  },
});

export default WebinarCard;
