import React, { useState, useEffect } from 'react';
import {
  ScrollView,
  View,
  Text,
  StyleSheet,
  Dimensions,
  Pressable,
  ImageBackground,
  Platform,
  ActivityIndicator,
  Alert
} from 'react-native';
import * as Colors from '../styles/colors';
import * as Global from '../styles/global';
import { useSelector } from 'react-redux';
import ProfileForm from '../components/ProfileForm';
import ArticleCardProfile from '../components/ArticleCardProfile';
import VideoCardProfile from '../components/VideoCardProfile';
import PodcastCardProfile from '../components/PodcastCardProfile';
import Footer from '../components/Footer';

import { connect } from 'react-redux';
import * as actions from '../actions/profile';
import * as actionsDistributors from '../actions/distributors';

import AsyncStorage from '@react-native-async-storage/async-storage';
import { useToast } from 'react-native-toast-notifications';

function ProfileSection(props) {
  const { chkProfile = 0 } = props.route.params;
  const locale = useSelector((state) => state.locale);
  const toast = useToast();
  const [showSection, setShowSection] = useState('videos');
  const [profileReady, setProfileReady] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [items, setItems] = useState({ videos: [], articles: [], podcasts: [] });
  const [profile, setProfile] = useState({
    user_id: null,
    first_name: '',
    email: '',
    phone: '',
    company: '',
    avatar: '',
    state: {},
    newPassword: '',
    repeatPassword: '',
    items: {
      videos: [],
      podcasts: [],
      content: [],
    },
  });
  const [userId, setUserId] = useState('');
  const [states, setStates] = useState([]);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [userType, setUserType] = useState([]);
  const [isLogin, setIsLogin] = useState(chkProfile);

  const getStates = async (country) => {
    const states_tmp = await actionsDistributors.getStates(profile.profileData.country.id);
    //console.log('states_tmp', states_tmp.distributorData.items);
    setStates(states_tmp);
  };

  const getProfileItems = async () => {
    const items_tmp = await actions.getProfileItems(userId);
    //console.log('items_tmp', items_tmp);
    setItems(items_tmp.profileData.items);
  };

  const updateProfile = async () => {
    const { email, company, first_name, phone, avatar, newPassword, repeatPassword } = profile;
    const data = {
      email,
      company,
      first_name,
      phone,
      avatar,
      country: country,
      state: state,
      userType: userType,
      id: userId,
    };

    if (newPassword !== repeatPassword) {
      toast.show(locale.in['password_does_not_match'], { type: 'danger', duration: 3000 });
      return;
    }

    if (country) {
      const intCountry = parseInt(country.id);
      if (typeof (intCountry) !== 'number') {
        toast.show(locale.in['choose_a_country'], { type: 'danger', duration: 3000 });
        return;
      }
    }
    if (state) {
      const intState = parseInt(state.id);
      if (typeof (intState) !== 'number') {
        toast.show(locale.in['choose_a_state'], { type: 'danger', duration: 3000 });
        return;
      }
    }


    const intUserType = parseInt(userType.id);
    if (typeof (intUserType) !== 'number') {
      toast.show(locale.in['choose_a_profile'], { type: 'danger', duration: 3000 });
      return;
    }

    await actions
      .updateProfile(JSON.stringify(data))
      .then(res => {
        if (res.type === 'FETCHING_PROFILE_SUCCESS') {
          setEditProfile(false);
          toast.show(locale.in['saved_successfully'], { type: 'success', duration: 3000 });
        }
      }).catch(e => {
        toast.show(e, { type: 'danger', duration: 3000 });
      });
  };

  const setValueForInput = (key, value) => {
    setProfile(prevProfile => {
      return {
        ...prevProfile,
        [key]: value,
      };
    });
  };

  useEffect(() => {
    const getUserId = async () => {
      try {
        const profile = await AsyncStorage.getItem('profile');
        const jsonProfile = JSON.parse(profile);
        const user_id = jsonProfile ? jsonProfile.user_id : null;

        // console.log('jsonProfile', jsonProfile);
        // console.log('user_id', user_id);

        if (user_id) {
          setUserId(user_id);
          return user_id;
        } else {
          setUserId(null);
          props.navigation.navigate('Login');
        }
        return null;
      } catch (e) {
        console.error(e);
      }
    };

    const user_id = getUserId();
    if (!userId) {
      //return;
    }
    else {
      actions.getProfile(userId).then(async (response) => {
        //console.log('response =>', response);
        if (response.type === 'FETCHING_PROFILE_SUCCESS') {
          //console.log('getProfile', response.profileData);
          setProfile(response.profileData);
          setCountry(profile?.country);
          setState(profile?.state);
          setUserType(response.profileData.profile);
          setProfileReady(true);

          getProfileItems();

        }
      });
    }



    //console.log('ProfileSection');
  }, [userId, isLogin, chkProfile]);

  // console.log('Perfil => props =>', props);
  // console.log('Perfil => isLogin =>', isLogin);
  // console.log('Perfil => chkProfile =>', chkProfile);

  return (
    profileReady ? (
      <ScrollView>
        <ImageBackground source={require('../../assets/FONDO_TEXTURA_BENQ.jpg')}>
          <View style={Global.default.sectionContainer}>
            {
              Platform.OS === 'web' && Dimensions.get('window').width > 667 && (
                <View style={Global.default.breadcrumbs}>
                  <Text style={Global.default.breadCrumbitem}>{locale.in['home']} / </Text>
                  <Text style={Global.default.breadCrumbitem}>{locale.in['profile']}</Text>
                </View>
              )
            }
            <View style={{
              flexDirection: 'row',
              alignItems: 'flex-end',
              alignItems: 'baseline'
            }}>
              <Text style={[Global.default.sectionTitle, {
                width: 145
              }]}>{locale.in['profile']}</Text>
              <View style={{

              }}>
                <Pressable onPress={async () => {
                  if (Platform.OS === 'web') {
                    localStorage.removeItem('profile');
                    props.navigation.navigate('Login');
                  }
                  else {
                    await AsyncStorage.removeItem('profile');
                    props.navigation.navigate('Login');
                  }
                }}>
                  <Text style={[styles.buttonClose, {}]}>Cerrar Sesion </Text>
                </Pressable>
              </View>
            </View>

            <Text style={[Global.default.sectionSubTitle, styles.paragraph]}>
              {locale.in['profile_paragraph']}
            </Text>

            {Platform.OS == 'ios' && (
              <View style={{ flexDirection: 'row', alignItems: 'flex-end', alignItems: 'baseline' }}>
                <View style={{}}>
                  <Pressable onPress={async () => {

                    Alert.alert('¿Estas seguro?',
                      'Esta acción no es reversible, eliminaremos por completo tu información de nuestros sistemas',
                      [{
                        text: 'Cancel',
                        onPress: () => { },
                        style: 'cancel',
                      }, {
                        text: 'OK', onPress: () => {
                          actions.requestDeletion({ id: userId }).then(r => {
                            console.log(r);
                          });
                          Alert.alert('Aviso',
                            'Esta acción tardará unas horas, no es necesario realizar otra acción',
                            [{
                              text: 'Ok',
                              onPress: async () => {
                                await AsyncStorage.removeItem('profile');
                                props.navigation.navigate('Login');
                              },
                              style: 'cancel',
                            }]
                          );
                        }
                      }]
                    )
                  }}>
                    <Text style={[styles.buttonDelete, {}]}>Eliminar mi perfil</Text>
                  </Pressable>
                </View>
              </View>
            )}

            <ProfileForm
              profile={profile}
              handleChangeText={setValueForInput}
              states={states}
              handleGetStates={getStates}
              userType={setUserType}
              state={setState}
              country={setCountry}
              handleUpdateProfile={updateProfile}
              editFlag={editProfile}
              handleEditProfile={setEditProfile}
            />

            <Text style={styles.title}>{locale.in['items_saved']}</Text>
            <View style={Global.default.row}>
              <Pressable onPress={() => setShowSection('videos')}>
                <Text style={[styles.chooseSection, showSection === 'videos' && styles.activeSection, { marginLeft: 0 }]}>
                  Videos
                </Text>
              </Pressable>
              <Pressable onPress={() => setShowSection('content')}>
                <Text style={[styles.chooseSection, showSection === 'content' && styles.activeSection]}>Articulos</Text>
              </Pressable>
            </View>
            {
              showSection === 'videos' ? (
                <VideoCardProfile videos={items.videos} handleGetItems={getProfileItems} />
              )
                : (
                  <ArticleCardProfile articles={items.content} navigation={props.navigation} handleGetItems={getProfileItems} />
                )
            }
          </View>
          <Footer {...props} />
        </ImageBackground>
      </ScrollView>
    ) : (
      <View style={{ flex: 1, justifyContent: 'center' }}>
        <ActivityIndicator size="large" color={Colors.PURPLE} />
      </View>
    )
  );
}

const { width: WIDTH } = Dimensions.get('window');
const styles = StyleSheet.create({
  container: {
    marginHorizontal: 'auto',
    width: WIDTH >= 767 ? '70%' : WIDTH - 20,
    paddingBottom: 40,
  },
  title: {
    fontSize: WIDTH >= 767 ? 32 : 18,
    fontWeight: 'bold',
    marginVertical: 10,
    textTransform: 'uppercase',
  },
  paragraph: {
    marginBottom: 5,
  },
  chooseSection: {
    marginHorizontal: 10,
    textTransform: 'uppercase',
    fontSize: 14,
  },
  activeSection: {
    color: Colors.PURPLE,
    fontWeight: 'bold',
  },
  buttonClose: {
    marginLeft: 0,
    color: Colors.PURPLE,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: 14,
  },
  buttonDelete: {
    color: '#999',
    paddingLeft: 5
  }
});

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setProfile: (data) => {
      return dispatch(actions.setProfile(data));
    },
    requestDeletion: (data) => {
      return dispatch(actions.requestDeletion(data));
    },
  };
};
const Profile = connect(mapStateToProps, mapDispatchToProps)(ProfileSection);

export default Profile;
