import {Dimensions, Platform, StyleSheet} from 'react-native';
import { min } from 'react-native-reanimated';
import * as Colors from '../../styles/colors';
const {width: WIDTH} = Dimensions.get('window');

export const styles = StyleSheet.create({
  contForm: {
    backgroundColor: Colors.WHITE,
    borderRadius: 10,
    marginTop: 24,
    padding: Platform.OS === "web" ? "50px" : WIDTH - 50,
    flexDirection: Dimensions.get('window').width >= 768 ? 'row' : 'column',
    flexGrow: "grow",
    flexWrap: "wrap",
    alignItems: "start",
    justifyContent: Platform.OS === "web" ? "start" : "center",
  },
  contColumn: {
    paddingHorizontal: 10,
    width: Dimensions.get('window').width >= 768 ? "50%" : "100%",
  },
  textInput: {
    borderColor: Colors.PURPLE,
    borderRadius: 25,
    borderWidth: 2,
    marginVertical: 10,
    //textTransform: "uppercase",
    padding: 10,
    paddingHorizontal: 17,
  },
  selectControlArrowImageStyle:{
    marginTop : Platform.OS === 'web' ? 0 : 15,
  },
  picker: {
    height: Platform.OS === 'web' ? 40 : 'auto',
    width: '100%',
    marginTop: 0,
    marginBottom: 20,
    position: 'relative',
  },
  selectControlStyle:{
    borderColor: Colors.LIGTH_GRAY,
    borderRadius: 25,
    borderBottomLeftRadius: 25,
    borderBottomRightRadius: 25,
    borderWidth: 2,
    marginVertical: 10,
    textTransform: "uppercase",
    padding: 10,
    paddingHorizontal: 17,
  },
  optionsListStyle:{
    position: Platform.OS === 'web' ? 'fixed' : 'absolute',
    borderColor: '#fff',
    marginTop: Platform.OS === 'web' ? 0 : -40,
  },
  submitButton: {
    backgroundColor: Colors.GRAY,
    borderRadius: 25,
    marginVertical: 20,
    overflow: "hidden",
    width: 150,
  },
  container: {
    flex: 1,
    paddingTop: 40,
    alignItems: "center"
  }
});
