import React,{} from 'react';
import {
    ScrollView,
    View,
    Text,
    Image,
    Pressable,
    Platform,
    Dimensions,
    TouchableOpacity,
    StyleSheet,
} from 'react-native';
import * as Global from '../styles/global';
import * as Colors from '../styles/colors';
import { connect }  from 'react-redux';
import { BASE_URL } from "../constants/endPoints";

import SaveAndShare from '../components/saveandshare/SaveAndShare';

import stripTags from '../utils/stripTags';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");
class ArticleCardsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items:[],
            componentReady: false,
            dimensions: {
                window,
                screen
            }
        }
    }
    onChange = ({ window, screen }) => {
        this.setState({ dimensions: { window, screen } });
    }
    componentDidMount(){
        //console.log('this.props.home.homeData.items.imagenes',this.props.home.homeData.items)
        if(this.props.home && this.props.home.homeData && this.props.home.homeData.items){
            this.setState({
                items: this.props.home.homeData.items[0].articles.pages.slice(0,2),
                intro: this.props.home.homeData.items[0].articles.intro,
                componentReady: true
            })
        }
        this.dimensionsSubscription = Dimensions.addEventListener("change", this.onChange);
    }
    componentWillUnmount() {
        this.dimensionsSubscription?.remove();
    }
    render() {
        return (
            <View style={styles.container}>
                <View style={styles.titleContainer} >
                    <Text style={Global.default.sectionHeader}>
                        {this.props.locale.in['articles'].toUpperCase()}
                    </Text>
                    <Text style={Global.default.sectionSubHeader} >
                        {this.state.intro}
                    </Text>
                </View>

                <ScrollView 
                    contentContainerStyle={styles.scrollViewContentContainer}
                    style={[styles.scrollViewContainer,{
                        maxWidth: this.state.dimensions.window.width,
                    }]}
                    horizontal={true}
                >
                    { 
                        this.state.items.map((article, index) => { 
                            return (
                                <Pressable key = {'card-'+index} style={[styles.cardContainer,{
                                    width:  
                                        this.state.dimensions.window.width  < 767 ?
                                        this.state.dimensions.window.width * 0.8 
                                        :
                                            Platform.OS == 'web' ? '420px' : this.state.dimensions.window.width - 20,
                                    height: Platform.OS == 'web' ? '650px' : this.state.dimensions.window.width * 1.5,
                                }]}
                                    onPress={()=>{
                                        this.props.navigation.navigate("Articulo", {
                                            id: article.id,
                                        });
                                    }}
                                >
                                    <View style={styles.cardImageContainer}>
                                        <Image
                                            source={ { uri: BASE_URL + article.image }}
                                            style={styles.cardImage}
                                            resizeMode='cover'
                                        />
                                        <SaveAndShare item={article} options={{share:true,save:true,download:false}} />
                                    </View>
                                    <View>
                                        <Text style={styles.cardTitle}>{article.category}</Text>
                                        <Text numberOfLines={2} style={styles.cardSubTitle}>{article.title}</Text>
                                        <Text numberOfLines={5} style={styles.cardDescription}>{stripTags(article.desc)}</Text>
                                    </View>
                                </Pressable>
                            )
                        }) 
                    }
                </ScrollView>

                <View>
                    <TouchableOpacity style={styles.buttonContainer}
                        onPress={() => {
                            this.props.navigation.navigate('Articulos');
                        }}
                    >
                        <Text style={{ color: Colors.BLACK, fontSize: 18 }}>
                            {this.props.locale.in['see_more'].toUpperCase()}
                        </Text>
                    </TouchableOpacity>
                </View>
            
            </View>
        )
    }
}

const styles = StyleSheet.create({
    buttonContainer:{
        minWidth: 200,
        height: 50,
        backgroundColor: Colors.TRANSPARENT,
        paddingHorizontal: 20,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 50,
        borderRadius: 25,
        borderColor: Colors.PURPLE,
        borderWidth: 2,
    },
        cardDescription:{
        color: Colors.GRAY,
        fontSize: Platform.OS == "web" ? "18px" : 18,
        paddingHorizontal: Platform.OS == "web" ? "20px" : 20,
        marginTop: 10,
        
    },
        cardSubTitle:{
        color: Colors.BLACK,
        fontSize: Platform.OS == "web" ? "25px" : 25,
        paddingHorizontal: Platform.OS == "web" ? "20px" : 20,
        marginTop: 0,
    },
    cardTitle:{
        color: Colors.PURPLE,
        fontSize: Platform.OS == "web" ? "18px" : 18,
        paddingHorizontal: Platform.OS == "web" ? "20px" : 20,
        marginTop: 30,
    },
    cardImage:{
        width: '100%',
        height: '100%',
        borderTopLeftRadius: 25,
        borderTopRightRadius: 25,
    },
    cardImageContainer:{
        width: '100%',
        height: '50%'
    },
    cardContainer:{
        borderRadius:15,
        backgroundColor: Colors.LIGTH_GRAY,
        marginHorizontal: Platform.OS == 'web' ? '10px' : 10,
        alignItems: 'center',
    },
    scrollViewContainer:{
    },
    scrollViewContentContainer: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    titleContainer: {
        flexDirection: 'column',
        minHeight: 180,
        alignItems: 'center',
        width: '100%',
        maxWidth: 880,
    },
    container: {
        marginTop: 50,
        alignItems: 'center',
    }
});



const mapStateToProps = (state) => {
    return {
        home: state.home,
        locale: state.locale,
    }
}

const ArticleCards = connect(mapStateToProps,null)(ArticleCardsContainer);
export default ArticleCards;