import React, { } from 'react';
import {
  ScrollView,
  View,
  Text,
  Image,
  Platform,
  Dimensions,
  FlatList,
  Pressable,
  StyleSheet,
  ActivityIndicator,
  Modal,
  Alert
} from 'react-native';
import { LinearGradient } from "expo-linear-gradient";

import { WebView as WebViewWeb } from "react-native-web-webview";
import { WebView as WebViewMobile } from "react-native-webview";

import { connect } from 'react-redux';
import * as Colors from '../styles/colors';
import * as actions from '../actions/podcast';
import * as F from '../constants/actionTypes.js';
import { BASE_URL } from "../constants/endPoints";
import stripTags from '../utils/stripTags';
import Footer from "../components/Footer";

const gradientStart = {
  x: 0,
  y: 1
};
const gradientEnd = {
  x: 1,
  y: 0
};

class PodcastsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      podcasts: [],
      length: 0,
      intro: '',
      page: 1,
      podcastsReady: false,
      modalVisible: false,
      selectedFrame: '',
    };
  }

  async componentDidMount() {
    await actions.getPodcasts({
      offset: 0,
      locale: this.props.locale,
    }).then(res => {
      //console.log(res);
      if (res.type == F.FETCHING_PODCAST_SUCCESS) {
        //console.log(res.contentData)
        this.setState({
          intro: res.contentData.items ? res.contentData.items[0].intro : '',
          podcasts: res.contentData.items,
          length: res.contentData.meta.total_count,
          podcastsReady: true,
        });
      }
    }).catch(err => {
      console.log(err);
    });
  }
  Header = () => {
    return (
      <View style={styles.header}>
        <Text style={{ fontSize: 60, marginBottom: 20 }}>
          PODCASTS
        </Text>
        <Text style={{ color: 'gray', fontSize: 25, textAlign: 'center' }}>
          {this.state.intro}
        </Text>
      </View>
    );
  };
  Divider = () => {
    return (
      <View style={{ marginTop: 20 }}>
      </View>
    );
  };
  ItemRender = (podcast) => {
    return (
      <LinearGradient
        colors={["rgba(51,8,102,1)", "rgba(169,124,210,1)"]}
        start={gradientStart}
        end={gradientEnd}
        style={styles.card}
      >
        <Image source={require("../../assets/components/PodcastCards/ICONO_PODCAST_WEB_BENQ.png")}
          style={styles.cardImage}
        ></Image>
        <Text style={styles.cardTitle}
          numberOfLines={1}
        >
          {podcast.title}
        </Text>
        <Text numberOfLines={4} style={styles.cardDescription}>
          {stripTags(podcast.desc)}
        </Text>
        <Pressable
          onPress={() => {
            this.setState({
              modalVisible: true,
              selectedFrame: podcast.frame
            })
          }}
          style={styles.cardButton}
        >
          <Text style={styles.cardButtonText}>
            {this.props.locale.in['listen']}
          </Text>
        </Pressable>
      </LinearGradient>
    );
  };
  onEnd = () => {
    const pagesize = 20;
    const page = this.state.page + 1;
    const offset = (page - 1) * pagesize;
    const limit = pagesize * page;

    //console.log('offset =>', offset);
    //console.log('limit =>', limit);

    if (this.state.length > offset) {
      actions.getPodcasts({
        offset: offset,
        locale: this.props.locale,
      }).then(res => {
        if (res.type == F.FETCHING_PODCAST_SUCCESS) {
          //console.log(res.contentData)
          this.setState({
            podcasts: this.state.podcasts.concat(res.contentData.items),
            length: res.contentData.meta.total_count,
            page: page,
          });
        }
      }).catch(err => {
        console.log(err);
      });
    }

  };

  render() {
    return (
      <View style={styles.container}>
        <Modal
          animationType="slide"
          transparent={true}
          visible={this.state.modalVisible}
        >
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              {
                Platform.OS == "web" && (
                  <WebViewWeb
                    androidLayerType="software"
                    androidHardwareAccelerationDisabled
                    style={{ width: "100%" }}
                    javaScriptEnabled={true}
                    domStorageEnabled={true}
                    source={{ html: `${this.state.selectedFrame}` }}
                  />
                )
                || (
                  <WebViewMobile
                    androidLayerType="software"
                    androidHardwareAccelerationDisabled
                    style={{
                      width: Dimensions.get('window').width,
                      height: Dimensions.get('window').width,
                    }}
                    javaScriptEnabled={true}
                    domStorageEnabled={true}
                    source={{ html: `${this.state.selectedFrame}` }}
                    injectedJavaScript={`const meta = document.createElement('meta'); meta.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0'); meta.setAttribute('name', 'viewport'); document.getElementsByTagName('head')[0].appendChild(meta); `}
                    scalesPageToFit={false}
                  />
                )
              }
              <Pressable onPress={() => { this.setState({ modalVisible: !this.state.modalVisible }) }}>
                <Text>Cerrar</Text>
              </Pressable>
            </View>
          </View>
        </Modal>
        {this.state.podcastsReady ? (
          <FlatList
            style={{
              height: Dimensions.get('window').height,
              width: Dimensions.get('window').width,
            }}
            contentContainerStyle={{ alignItems: 'center' }}
            ListHeaderComponent={this.Header}
            ListFooterComponent={<Footer {...this.props} />}
            ListHeaderComponentStyle={{ alignItems: 'center', width: Dimensions.get('window').width, marginBottom: 50 }}
            ListFooterComponentStyle={{ marginTop: 30, width: '100%' }}
            data={this.state.podcasts}
            renderItem={({ item }) => this.ItemRender(item)}
            ItemSeparatorComponent={this.Divider}
            keyExtractor={item => item.id}
            numColumns={Dimensions.get('window').width < 767 ? 1 : 2}
            onEndReached={this.onEnd}
            initialNumToRender={10}
            onEndThreshold={0.5}
          />
        ) : (
          <View style={{ flex: 1, justifyContent: 'center' }}>
            <ActivityIndicator size="large" color={Colors.PURPLE} />
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  header: {
    flexDirection: 'column',
    minHeight: 180,
    alignItems: 'center',
    width: '100%',
    maxWidth: 880,
  },
  container: {
    marginTop: Dimensions.get('window').width >= 768 || Platform.OS == 'android' ? 50 : Platform.OS == 'ios' ? 70 : 0,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    minWidth: Dimensions.get('window').width >= 768 ? 750 : 350,
    minHeight: 350,
  },
  seeMoreButton: {
    minWidth: 200,
    height: 50,
    backgroundColor: Colors.WHITE,
    paddingHorizontal: 20,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 50,
    borderRadius: 25,
    borderColor: Colors.PURPLE,
    borderWidth: 2,
  },
  seeMoreButtonContainer: {
    width: '100%',
    maxWidth: 880,
    alignItems: 'center',
  },
  seeMoreContainer: {
    minHeight: 180,
    alignItems: 'center',
  },
  cardButtonText: {
    color: "white",
    fontSize: Platform.OS == "web" ? "20px" : 20,
    fontWeight: "bold",
  },
  cardButton: {
    position: "absolute",
    bottom: 50,
  },
  cardDescription: {
    color: "white",
    marginTop: 10,
  },
  cardTitle: {
    color: "white",
    fontSize: Platform.OS == "web" ? "" : 40,
    marginTop: 20,
  },
  cardImage: {
    width: Platform.OS == "web" ? "120px" : 120,
    height: Platform.OS == "web" ? "90px" : 90,
  },
  card: {
    width:
      Platform.OS == "web"
        ? "280px"
        : Dimensions.get("window").width - 20,
    height:
      Platform.OS == "web"
        ? "380px"
        : Dimensions.get("window").width,
    padding: 30,
    marginHorizontal: Platform.OS == "web" ? "5px" : 5,
    alignItems: "center",
    borderRadius: 10,
  },
})

const mapStateToProps = (state) => {
  return {
    locale: state.locale,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {}
}

const Podcasts = connect(mapStateToProps, mapDispatchToProps)(PodcastsContainer);
export default Podcasts;
