import {
  API_URL,
  AUTH_TOKEN,
  ENV
} from '../constants/endPoints';

export default async (method, request) => {

  let appHeaders = new Headers();
  appHeaders.append('Content-Type', 'application/json');
  appHeaders.append('Authorization', AUTH_TOKEN);

  if (ENV === 'dev') {
    console.log(method, request);
  }

  if (method === 'getTraining') {
    try {
      const response = await fetch(`${API_URL}pages/?type=learning.LearningPage&order=-id&fields=*&locale=${request.locale.country.language}`, {
        method: 'GET',
        headers: appHeaders,
        cache: 'default',
      });
      return await Promise.all([response, response.json()]);
    } catch (error) {
      console.log(error);
    }
  } else if (method === 'getFilterWebinar') {
    try {
      const response = await fetch(`${API_URL}pages/?type=webinar.WebinarPage&isReview=${request.isReview}&fields=*&locale=${request.locale.country.language}`, {
        method: 'GET',
        headers: appHeaders,
        cache: 'default',
      });
      return await Promise.all([response, response.json()]);
    } catch (error) {
      console.log(error);
    }
  } else if (method === 'getOrderWebinar') {
    try {
      const response = await fetch(`${API_URL}pages/?type=webinar.WebinarPage&order=${request.order}&fields=*&locale=${request.locale.country.language}`, {
        method: 'GET',
        headers: appHeaders,
        cache: 'default',
      });
      return await Promise.all([response, response.json()]);
    } catch (error) {
      console.log(error);
    }
  } else if (method === 'getFilterVideo' || method === 'getOrderVideo') {
    try {
      const response = await fetch(`${API_URL}pages/?type=video.VideoPage&${request.filter}&order=${request.order}&fields=*&locale=${request.locale.country.language}`, {
        method: 'GET',
        headers: appHeaders,
        cache: 'default',
      });
      return await Promise.all([response, response.json()]);
    } catch (error) {
      console.log(error);
    }
  }
};
