  const initialState = {
    in: null
  }
  
  const locale = (state = initialState, action) => {
  
    switch (action.type) {
      case 'SET_LOCALE':
        return action.payload
        default:
            return state
    }

}
  
  export default locale;