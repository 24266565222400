import React from 'react';
// import type {Node} from 'react';
import * as Colors from './src/styles/colors';

import {NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';

import {Provider} from 'react-redux';
import {createStore, applyMiddleware} from 'redux';

import MainStackScreen from './src/MainStackScreen'
import { navigationRef } from './src/rootNavigation';
import * as Linking from 'expo-linking';

import thunk        from 'redux-thunk';
import storeConfig  from './src/store';
import {ActivityIndicator, Platform} from 'react-native';

import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';

import * as SplashScreen from 'expo-splash-screen';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { ToastProvider } from 'react-native-toast-notifications'
import { HUBSPOT_NT_URL, HUBSPOT_BQ_URL } from './src/constants/endPoints';

import Chat from './src/components/chat';

const MainStack = createStackNavigator();
const RootStack = createStackNavigator();
let store       = createStore(storeConfig, applyMiddleware(thunk));
const prefix    = Linking.createURL('/');

SplashScreen.preventAutoHideAsync()
  .then(result => console.log(`SplashScreen.preventAutoHideAsync() succeeded: ${result}`))
  .catch(console.warn);

if(Platform.OS !== 'web') {
  Notifications.setNotificationHandler({
    handleNotification: async () => ({
      shouldShowAlert: true,
      shouldPlaySound: true,
      shouldSetBadge: true,
    }),
  });
}
export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      isLogged: false,
      notification: {},
      expoPushToken: '',
    }
  }

  registerForPushNotificationsAsync = async () => {
    if(Platform.OS !== 'web') {
      if (Device.isDevice) {
        const { status: existingStatus } = await Notifications.getPermissionsAsync();
        let finalStatus = existingStatus;
        console.log('existingStatus',existingStatus);
        if (existingStatus !== 'granted') {
          const { status } = await Notifications.requestPermissionsAsync();
          finalStatus = status;
        }
        console.log('finalStatus',finalStatus);
        if (finalStatus !== 'granted') {
          //alert('Failed to get push token for push notification!');
          return;
        }
        const token = (await Notifications.getDevicePushTokenAsync()).data;
        console.log(token);
        AsyncStorage.setItem('PushToken', token);
        this.setState({ expoPushToken: token });
      } else {
        alert('Must use physical device for Push Notifications');
      }
    }
  }

  _handleNotification = notification => {
    //this.setState({ notification: notification });
    //console.log('_handleNotification', notification);
  };
  _handleNotificationResponse = response => {
    //console.log('_handleNotificationResponse', response.notification);

    if(response.notification.request && response.notification.request.trigger && response.notification.request.trigger.remoteMessage){
      const { remoteMessage } = response.notification.request.trigger;
      if (remoteMessage.data && remoteMessage.data.link){
        Linking.openURL(remoteMessage.data.link);
      }
    }
  };

  componentWillUnmount() {
    if(Platform.OS !== 'web') {
      this._handleNotification = null;
      this._handleNotificationResponse = null;
    }
  }

  async componentDidMount() {
    if(Platform.OS !== 'web') {
      this.registerForPushNotificationsAsync();
      Notifications.addNotificationReceivedListener(this._handleNotification);
      Notifications.addNotificationResponseReceivedListener(this._handleNotificationResponse);
    }

    try{
      const profile = await AsyncStorage.getItem('profile');
      const jsonProfile = JSON.parse(profile);
      const user_id  = jsonProfile ? jsonProfile.user_id  : null;
      let   dictionary = {};
      let   country = {
        "id": 1,
        "meta": {
          "name": "México",
        },
        "exchange": 20.122875,
        "currency_symbol": "MXN",
        "currency_printable_symbol": "$",
        "price_factor": 1,
        "language": "es"
      }

      const stored_locale = await AsyncStorage.getItem('locale');
      if( stored_locale ){
        country = JSON.parse(stored_locale).country;
      }
      if (user_id) {
        store.dispatch({type: 'SET_PROFILE', payload: {user_id, country}});
      }
      
      if(country.language === 'pt'){
        dictionary = require('./src/locale/pt.js').default;
      }
      else{
        dictionary = require('./src/locale/es.js').default;
      }

      const payload = {
        country,
        in:dictionary,
      }
      AsyncStorage.setItem('locale',JSON.stringify(payload));
      store.dispatch({type: 'SET_LOCALE', payload: payload });
      this.setState({
          isReady: true,
          isLogged: user_id ? true : false,
      });

      if (Platform.OS === 'web') {
        const script = document.createElement('script');

        script.src   = HUBSPOT_BQ_URL;
        script.type  = 'text/javascript';
        script.defer = true;
        script.async = true;
        script.id    = 'hs-script-loader';

        document.body.appendChild(script);
      }
    }
    catch(e){
      console.log(e);
    }
    setTimeout(async () => {
      await SplashScreen.hideAsync();
    }, 2000);
  }
  render() {
    const linking = {
      prefixes: [prefix],
    };
    return (
      this.state.isReady ? (
        <ToastProvider placement="center">
          <Provider store={store}>
            <NavigationContainer ref={navigationRef} linking={linking} fallback={<ActivityIndicator size="large" color={Colors.PURPLE} />} >
              <RootStack.Navigator screenOptions={{headerShown: false}}>
                <RootStack.Screen
                  name="Ben"
                  component={MainStackScreen}
                />
              </RootStack.Navigator>
            </NavigationContainer>
            {
              Platform.OS !== 'web' && (
                <Chat />
              )
            }
          </Provider>
        </ToastProvider>
      ) : <ActivityIndicator size="large" color={Colors.PURPLE} />
    );
  }


}
