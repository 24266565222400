import * as React from 'react';
import { StyleSheet, 
  View, 
  Text, 
  Platform, 
  Dimensions, 
  Image, 
  TouchableOpacity,
  Modal,
  Pressable
} from 'react-native';
import Swiper from 'react-native-web-swiper';
import { BASE_URL } from '../constants/endPoints';
import * as Colors from '../styles/colors';

import ImageViewer from 'react-native-image-zoom-viewer';
export class ProductImagesSlider extends React.Component {
  constructor (props) {
      super(props);
      this.state = {
        showModal: false,
        imageSelected: '',
        imgIndex: 0
      }
      this.swiperRef = React.createRef();
  }

  render () {
    const { images } = this.props;

    return (
      <View style={[styles.container]}>
        <Modal visible={this.state.showModal} >
          <ImageViewer 
            index={this.state.imgIndex}
            ref={this.swiperRef}
            imageUrls={images.map(item=>{return {url: BASE_URL + item.image.url} })} 
            renderIndicator={() => null} 
            enableImageZoom={true}
            onSwipeDown={() => this.setState({showModal: false})}
            doubleClickInterval={500}
            menuContext={{cancel: 'cancel'}}
            enableDoubleClickZoom={true}
            minScale={0.5}
            maxScale={5}
          />
          <View  style={{
              position: 'absolute',
              bottom: 0,
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: 100,
            }}>
            <TouchableOpacity style={{
              backgroundColor: Colors.PURPLE,
              width: 300,
              textAlign: 'center',
              fontSize: 20,
              padding: 10,
              borderRadius: 10,
            }}
            onPress={() => {
              this.setState({
                showModal: false,
              })
            }}
            >
              <Text style={{
                color: 'white',
                fontSize: 20,
                textAlign: 'center'
              }}>Cerrar</Text>
            </TouchableOpacity>
          </View>
        </Modal>
        <View style={styles.swiperContainer}>
          <Swiper
            loop
            controlsProps={{
              dotsPos: false,
              prevPos: 'left',
              nextPos: 'right',
              nextTitle: `❯`,
              nextTitleStyle: { color: '#000', fontSize: 32, fontWeight: 'bold' },
              prevTitle: `❮`,
              prevTitleStyle: { color: '#000', fontSize: 32, fontWeight: 'bold' },
            }}
          >
              {
                images.map((image, index) => {
                  return (
                    <Pressable style={styles.slideContainer} key={index} onPress={()=>{
                      this.setState({
                        showModal: true,
                        imgIndex:index
                      })
                    }}>
                    <View >
                      <Image 
                          source={{ uri: BASE_URL + image.image.url}} 
                          style={styles.image} 
                          resizeMode="contain" 
                        />
                    </View>
                    </Pressable>
                  );
                })
              }
          </Swiper>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: Dimensions.get('window').width < 767 ? 'row' : 'column',
    marginHorizontal: 0,
    marginVertical: Dimensions.get('window').width < 767  ? 0 : 40 ,
    width: '100%'
  },
  swiperContainer: {
    flex: 1,
    height: Dimensions.get('window').width < 767 ? 280 : '350px',
    width: '100%',
  },
  slideContainer: {
    flex: 1,
    alignContent: 'center',
    alignItems: 'center',
    maxWidth: '100%'
  },
  image: {
    width: Dimensions.get('window').width * .5,
    height: Platform.OS === 'web' ? '320px' : '100%',
  }
});