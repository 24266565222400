import * as React from 'react';
import {
  View,
  StyleSheet,
  ImageBackground,
  Text,
  Pressable,
  ScrollView,
  Platform, Dimensions,
} from 'react-native';
import {useSelector} from 'react-redux';

import * as Colors from '../styles/colors';
import globalStyles from '../styles/global';

import RegisterUser from '../components/RegisterUser';
import RegisterDistributor from '../components/RegisterDistributor';
import Toast from 'react-native-toast-notifications';
import Footer from '../components/Footer';
import Select from '../components/Select';

function Registro({navigation}) {
  const locale = useSelector((state) => state.locale);
  const toastRef = React.useRef();
  const [selectedText, setSelectedText] = React.useState('Usuario');
  const [selectedProfile, setSelectedProfile] = React.useState('user');
  const [userValue, setUserValue] = React.useState({
    label: selectedText,
    value: selectedProfile,
  });

  const onChangeUser = (value) => {
    //console.log('onChangeUser => value =>', value);
    setUserValue(value);
  };

  return (
    <ScrollView>
      <ImageBackground source={require('../../assets/FONDO_TEXTURA_BENQ.jpg')}>
      <View style={styles.centeredView}>
        <Toast ref={toastRef}/>
        <View style={[styles.centeredView, {
          backgroundColor: 'rgba(252, 252, 252, 1)',
          marginVertical: 50,
          padding: 50,
          borderRadius: 10,
          maxWidth: 600,
          width: '100%',
        }]}>
          <Text>{locale.in['sign_up'].toUpperCase()}</Text>
          <Text style={{
            fontSize: 30,
            fontFamily: 'Lato_400Regular',
            marginBottom: 20,
          }}>{selectedText.toUpperCase()}</Text>
          <Select
            name={'cboRegistro'}
            label={'Ordenar por'}
            innerStyles={{width: Platform.OS == 'web' ? 360 : (Dimensions.get('window').width - 30)}}
            onValueChange={(option) => {
              onChangeUser(option);
              if ( option.value ) {
                setSelectedProfile(option.value);
                setSelectedText(option.label);
              }
            }}
            dataItems={[
              {label: locale.in['distributor'].toUpperCase(), value: 'distributor'},
              {label: locale.in['user'].toUpperCase(), value: 'user'},
            ]}
            value={userValue}
          />
          {
            selectedProfile === 'distributor' ? (
              <RegisterDistributor toastRef={toastRef}/>
            ) : (
              <RegisterUser toastRef={toastRef}/>
            )
          }
        </View>
        <View style={{marginTop: 0}}>
          <Pressable
            style={[styles.button]}
            onPress={() => {
              navigation.navigate('Login');
            }}
          >
            <Text style={[styles.textStyle, {fontWeight: '400', color: Colors.GRAY}]}>
              {locale.in['have_you_an_account']}{""}
              <Text style={[globalStyles.textBold, {
                marginLeft: 8,
                color: Colors.GRAY,
              }]}>{locale.in['sign_in_here'].toUpperCase()}</Text>
            </Text>
          </Pressable>
        </View>
      </View>
        <Footer {...{navigation}}/>
      </ImageBackground>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    borderRadius: 20,
    padding: 10
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    //textTransform: 'uppercase',
  },
  logo: {
    width: 200,
    height: 50,
    marginTop: 30,
  },
  selectControlArrowImageStyle: {
    marginTop: Platform.OS == 'web' ? 0 : 15,
  },
  picker: {
    height: Platform.OS === 'web' ? 50 : 'auto',
    width: '100%',
    maxWidth: 300,
    marginVertical: 10,
    position: 'relative',
  },
  selectControlStyle: {
    borderRadius: 25,
    borderBottomLeftRadius: 25,
    borderBottomRightRadius: 25,
    backgroundColor: 'transparent',
    borderColor: Colors.BLACK,
    padding: 10,
  },
  optionsListStyle: {
    position: Platform.OS == 'web' ? 'fixed' : 'absolute',
    borderColor: '#fff',
    marginTop: Platform.OS == 'web' ? -100 : -40,
    maxWidth: 300,
  },
});

export default Registro;
